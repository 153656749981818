import React, { lazy, Suspense } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import RwPage from '../shared/RwPage';
import { Grid, Skeleton } from '@mui/material';
import RwDataGrid from '../shared/RwDataGrid';

export const useSharedPacketStyles = makeStyles((theme) => ({
    vertSpace: {
        marginRight: 90,
        marginLeft: 90,
    },
    filterSearch: {
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end'
        }
    }
}));

const columns = [
    { field: 'col1', flex: 1 },
    { field: 'col2', flex: 0.5 },
    { field: 'col3', flex: 0.75 },
    { field: 'col4', flex: 1 },
    { field: 'col5', flex: 0.75 }
];

export const PacketsSkeleton: React.FC = () => {
    const classes = useSharedPacketStyles();

    return (
        <RwPage title="Form Packets" buttons={[{ text: '', action: '' }]} loading={true} showLocationFilter={true}>
            <div className={classes.vertSpace}>
                <Grid container>
                    <Grid item container className={classes.filterSearch} xs={12} sm={12}>
                        <Grid item style={{ height: 56 }}>
                            <Skeleton variant="rectangular" width={200} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <RwDataGrid rows={[]} columns={columns} pageSize={10} pagination loadingSkeleton={true} rowCount={10} paginationMode="server" autoHeight={true} />
        </RwPage>
    );
};


const Packets = lazy(() => import(/* webpackChunkName: "packets-index" */ './index'));

const LazyPackets: React.FC = () => {
    return (
        <Suspense fallback={<PacketsSkeleton />}>
            <Packets />
        </Suspense>
    );
};

export const PacketsModule =
{
    routeProps: {
        path: '/packets/index',
        component: LazyPackets,
        private: true
    },
    name: 'List Packets',
};