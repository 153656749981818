import React, { useState } from 'react';
import ID from '../../shared/UUID';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useFormBuilderContext } from './form-builder-context';

const useStyles = makeStyles((theme) => ({
    closeIcon: {
        color: theme.palette.primary.main
    },
    addButton: {
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        backgroundColor: theme.custom.background,
        boxShadow: 'none',
        borderRadius: '4px',
        width: '100%',
        fontSize: '15px',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
        }
    },
    choiceContainer: {
        alignItems: 'center',
        marginBottom: '5px'
    },
    choicesLabel: {
        marginBottom: '10px'
    }

}));

const DynamicOptionList = (props) => {
    const { state, updateComponent } = useFormBuilderContext();
    const [dirty, setDirty] = useState([]);
    const classes = useStyles();

    const _setValue = (text) => {
        return text.replace(/[^A-Z0-9]+/ig, '_');
    }

    const editOption = (option_index, e) => {
        const this_element = { ...state.editElement };
        const newDirty = { ...dirty };
        newDirty[option_index] = true;
        setDirty(newDirty);
        this_element.options[option_index].text = e.target.value;
        this_element.options[option_index].value = _setValue(e.target.value);
        updateField(this_element);
    }

    const editValue = (option_index, e) => {
        const this_element = { ...state.editElement };
        const newDirty = { ...dirty };
        newDirty[option_index] = true;
        setDirty(newDirty);
        this_element.options[option_index].value = _setValue(e.target.value);
        updateComponent(this_element.id, 'options', this_element.options);
        updateField(this_element);
    }

    const updateField = (element) => {
        const this_element = { ...state.editElement };
        // to prevent ajax calls with no change
        updateComponent(this_element.id, 'options', this_element.options);
    }

    const addOption = (index: number) => {
        const this_element = { ...state.editElement };
        this_element.options = this_element.options || [];
        this_element.options.splice(index, 0, { value: 'Choice_#' + (+index + 1), text: 'Choice #' + (+index + 1), key: ID.uuid() });
        updateComponent(this_element.id, 'options', this_element.options);
    }

    const removeOption = (index) => {
        const this_element = { ...state.editElement };
        this_element.options.splice(index, 1);
        updateComponent(this_element.id, 'options', this_element.options);
    }

    if(props.hasPmsOptions)
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.choicesLabel} variant="subtitle2">{props.softwareType} Choices</Typography>
                </Grid>
                {state.editElement.options?.length > 0 && state.editElement.options && state.editElement.options.map((option, index) => {
                    const this_key = `edit_${option.key}`;
                    return (
                        <Grid item xs={12} key={this_key}>
                            <Typography className={classes.choicesLabel} variant="body1">{option.text}</Typography>
                            </Grid>

                    )
                })
                }
                {state.editElement.options?.length === 0 && 
                    < Grid item xs={12} >
                <Typography className={classes.choicesLabel} variant="body1">Yes/No</Typography>
            </Grid>
                    }
            </Grid>);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className={classes.choicesLabel} variant="subtitle2">Choices</Typography>
            </Grid>

            {state.editElement.options && state.editElement.options.map((option, index) => {
                    const this_key = `edit_${option.key}`;
                    return (
                        <Grid container className={classes.choiceContainer} key={this_key} spacing={2}>
                            <Grid item xs={props.canHaveOptionValue?5:11}>
                                <TextField id="outlined-basic" error={dirty[index] && (!option.text || option.text === '')} label={`Choice #${index + 1}`} variant="outlined" size="small"
                                    tabIndex={index + 1} type="text" name={`text_${index}`} placeholder="Option text" value={option.text} onChange={editOption.bind(this, index)} style={{ width: '100%' }} />
                            </Grid>
                            {props.canHaveOptionValue &&
                                <Grid item xs={5}>
                                <TextField id="outlined-basic" error={dirty[index] && (!option.value || option.value === '')} label={`Value #${index + 1}`} variant="outlined" size="small"
                                    type="text" name={`value_${index}`} value={option.value} onChange={editValue.bind(this, index)} />
                                </Grid>
                            }
                            <Grid item xs={1}>
                                <IconButton aria-label="delete" onClick={removeOption.bind(this, index)} size="large">
                                    <CloseIcon className={classes.closeIcon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })
            }
            <Grid item xs={12}>
                <Button className={classes.addButton} variant="contained" aria-label="add" onClick={addOption.bind(this, state.editElement.options.length)}>
                    <AddIcon />
                    Add Additional Choice
                </Button>
            </Grid>
        </Grid>
    );

}

export default DynamicOptionList;
