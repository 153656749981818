import React, { lazy, Suspense, useEffect } from 'react';
import { useAppContext } from '../../../context/app-context';
import Container from '../view/container';

export const ViewFormSkeleton: React.FC = () => {
    return (
        <Container />
    );
};


const SingleSourceForms = lazy(() => import(/* webpackChunkName: "single-source-view" */ '../single'));

const LazySingleSourceForms: React.FC = () => {
    const { setDrawerVisible, setProductMenuVisible } = useAppContext();

    useEffect(() => {
        setDrawerVisible(false);
        setProductMenuVisible(false);
    }, [setDrawerVisible, setProductMenuVisible]);

    return (
        <Suspense fallback={<ViewFormSkeleton />}>
            <SingleSourceForms />
        </Suspense>
    );
};

export const SingleSourceFormsModule =
{
    routeProps: {
        path: '/forms/single',
        component: LazySingleSourceForms,
        private: true
    },
    name: 'Single Form',
};