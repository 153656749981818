import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Avatar } from '@mui/material';
import SwipeOutlinedIcon from '@mui/icons-material/SwipeOutlined';

const useStyles = makeStyles((theme) => ({
    defaultView: {
        height: 267,
        width: 'auto',
        margin: '36px 100px',
        backgroundColor: '#edf7ed',
        border: '2px dashed #E9EAEE',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        '& > p': {
            marginTop: 5,
        },


    },
    largeAvatar: {
        width: 56,
        height: 56,
        backgroundColor: '#edf7ed',
        '& > *': {
            color: theme.palette.success.dark
        }
    },
    iconRoot: {
        textAlign: 'center'
    },
    imageIcon: {
        height: '100%'
    },
}));

const EmptyRows = () => {
    const classes = useStyles();
    const { setNodeRef } = useDroppable({
        id: 'empty-rows',
    });

    return (
        <div className={classes.defaultView} ref={setNodeRef}>
            <Avatar className={classes.largeAvatar} style={{ backgroundColor: '#edf7ed' }}>
                <IconButton classes={{ root: classes.iconRoot }}>
                    <SwipeOutlinedIcon className={classes.imageIcon} />
                </IconButton>
            </Avatar>
            <p>
                Drop an item as a row here to begin
            </p>
        </div>
    );
}

export default EmptyRows;