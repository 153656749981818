import React, { lazy, Suspense, useEffect } from 'react';
import { useAppContext } from '../../../context/app-context';
import Container from '../view/container';

export const ViewFormSkeleton: React.FC = () => {
    return (
        <Container />
    );
};


const BuiltInForms = lazy(() => import(/* webpackChunkName: "single-source-view" */ '../builtin'));

const LazyBuiltInForms: React.FC = () => {
    const { setDrawerVisible, setProductMenuVisible } = useAppContext();

    useEffect(() => {
        setDrawerVisible(false);
        setProductMenuVisible(false);
    }, [setDrawerVisible, setProductMenuVisible]);

    return (
        <Suspense fallback={<ViewFormSkeleton />}>
            <BuiltInForms />
        </Suspense>
    );
};

export const BuiltInFormsModule =
{
    routeProps: {
        path: '/forms/builtin',
        component: LazyBuiltInForms,
        private: true
    },
    name: 'Built In Form',
};