import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import * as React from 'react';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


declare module '@mui/material/styles' {
    interface Theme {
        custom: {
            background: string,
            backgroundLightGrey: string,
            listItem: string,
            listItemBackground: string
            listItemBorderColor: string
            iconBackground: string
            iconBackgroundHover: string,
            cancel: string,
            cancelHover: string,
            errorBorder: string,
            errorColor: string,
            transparentBorder: string,
            secondaryButtonColor: string,
            secondaryButtonColorBackground: string,
            dataGridBorder: string,
            dataGridHeaderColor: string,
            formRowBackgroundColor: string,
        },
        patients: {
            background: string,
        }
    }
}


const FormsCustomThemeProvider: React.FC = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={(outerTheme) => ({
                ...outerTheme,
                custom: {
                    background: '#E0EFFA',
                    backgroundLightGrey: '#F0F1F4',
                    listItem: '#1769AA',
                    listItemBackground: '#F5FAFF',
                    listItemBorderColor: '#EBF3FB',
                    iconBackground: '#089AF7',
                    iconBackgroundHover: '#9cd7fc',
                    cancel: '#323232',
                    cancelHover: '#000',
                    errorBorder: '1px solid #e25650',
                    errorColor: '#e25650',
                    transparentBorder: '1px solid transparent',
                    secondaryButtonColor: '#089af7',
                    secondaryButtonColorBackground: 'rgba(0,125,255,0.08)',
                    dataGridBorder: '2px solid #E9EAEE',
                    dataGridHeaderColor: '#1C3D64',
                    formRowBackgroundColor: '#F9FAFB'
                },
                patients: {
                    background: '#F1F2F5'
                },

                overrides: {
                    MuiSwitch: {
                        colorSecondary: {
                            "&$checked": {
                                color: "#4dcea0"
                            }
                        },
                        track: {                        
                            "$checked$checked + &": {                                                        
                                backgroundColor: "#bbecdb"
                            }
                        }
                    },
                },

            })}>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default FormsCustomThemeProvider;