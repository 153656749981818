import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import useSWR from 'swr';
import { CreateFormSubmissionCommand, FormWithUriDto, IPmsSystemVm, PatientsClient } from '../../../api/web-api-client';
import { AzureService } from '../../../services/azure';
import clientService from '../../../services/clientService';
import IComponent, { FormJsonData, ILayoutRow, ISubmissionJsonField } from '../../../services/forms.interface';
import Keys from '../../../services/keys';
import { IRule, NetworkField } from './interfaces';
import moment from 'moment';
import { IFormViewError } from '../view/interfaces';
import * as pdfjsLib from "pdfjs-dist-es5/build/pdf";
import pdfjsWorker from "pdfjs-dist-es5/build/pdf.worker.entry";
import { Engine } from 'json-rules-engine';
import { ADDRESS, ALLERGIES, ALLERGIESPMS, MEDICATIONS, PROBLEMS, PROBLEMSPMS, SIGNATURE, SWITCHINPUT } from './constants';
import { createPmsListComponent } from './helpers';

const regex = (mask) => {
    switch (mask) {
        case 'maskZipUs': {
            return /^\d{5}$|^\d{5}-\d{4}$/;
        }
        case 'maskSSN': {
            return /^\(?\d{3}\)?[- ](\d{2})[- ](\d{4})$/;
        }
        case 'maskPhoneUs': {
            return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        }
        case 'maskState': {
            return /[a-zA-Z][a-zA-Z]/;
        }
        case 'maskEmail': {
            return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        }

    }
}

export const SUBMISSION_PMS_FIELDS_DATA = 'submission-pms-fields-data';

export enum ActionKind {
    SET_INIT = 'setInit',
    SET_ID = 'setId',
    SET_ASSIGNMENTID = 'setAssignmentId',
    SET_LEGACY = 'setLegacy',
    SET_LOADING = 'setLoading',
    SET_REDIRECT = 'setRedirect',
    SET_DATA = 'setData',
    SET_COMPONENTS = 'setComponents',
    SET_LAYOUT = 'setLayout',
    SET_TEMP_LAYOUT = 'setTempLayout',
    SET_RULES = 'setRules',
    SET_ERROR = 'setError',
    SET_PDF = 'setPdf',
    SET_URI = 'setUri',
    SET_IMAGES = 'setImages',
    SET_PAGE_PLACEHOLDERS = 'setPagePalaceholders',
    SET_DRAGGING = 'setErrors',
    SET_VALIDATION_MESSAGES = 'setValidationMessages',
    SET_OPEN_CONFIRM_CANCEL_DIALOGUE = 'setOpenConfirmCancelDialogue',
    SET_SUBMITTING = 'setSubmitting',
    SET_HAS_PRACTICE_SIGNATURE = 'setHasPracticeSignature',
    SET_PMSSYSTEM = 'setPmsSystem',
    SET_PMSSYSTEMLOADED = 'pmsSystemLoaded',
    SET_LOADPMSSYSTEM = 'loadPmsSystem',
}

interface IData {
    json: FormJsonData,
    form: FormWithUriDto,
    rules?: IRule[],
    
}

interface IState {
    id: string;
    assignmentId: string;
    legacy: boolean;
    loading: boolean;
    submitting: boolean;
    data: IData;
    mutate: any;
    error: any;
    redirect: boolean;
    preview: boolean;
    practiceView: boolean;
    pdf: any;
    uri: string;
    images: [],
    pagePlacesHolders: any;
    validationMessages: Record<string, string[]>;
    openConfirmCancelDialogue: boolean;
    readOnly: boolean;
    showSkeleton: boolean;
    hideActionBar: boolean;
    formViewActionBarString: string;
    tempLayout?: ILayoutRow[];
    pmsSystemVm: IPmsSystemVm;
    pmsSystemLoaded: boolean;
    loadPmsSystem: boolean;
}

const emptyValidationMessages = { 'required': [], 'regex': [] };

export const initialState: IState = {
    id: '',
    assignmentId: '',
    legacy: false,
    loading: true,
    submitting: false,
    data: null,
    mutate: null,
    error: null,
    redirect: false,
    preview: false,
    practiceView: false,
    pdf: null,
    uri: '',
    images: [],
    pagePlacesHolders: null,
    validationMessages: emptyValidationMessages as Record<string, string[]>,
    openConfirmCancelDialogue: false,
    readOnly: false,
    showSkeleton: true,
    hideActionBar: false,
    formViewActionBarString: '',
    pmsSystemVm: {},
    pmsSystemLoaded: false,
    loadPmsSystem: false,
}

const reducer = (state: IState, action) => {
    switch (action.type) {
        case ActionKind.SET_INIT:
            return { ...state, ...action.payload }
        case ActionKind.SET_ID:
            return { ...state, id: action.payload }
        case ActionKind.SET_ASSIGNMENTID:
            return { ...state, assignmentId: action.payload }
        case ActionKind.SET_LEGACY:
            return { ...state, legacy: action.payload }
        case ActionKind.SET_LOADING:
            return { ...state, loading: action.payload }
        case ActionKind.SET_SUBMITTING:
            return { ...state, submitting: action.payload }
        case ActionKind.SET_REDIRECT:
            return { ...state, redirect: action.payload }
        case ActionKind.SET_DATA:
            return { ...state, data: action.payload }
        case ActionKind.SET_COMPONENTS:
            return { ...state, data: { ...state.data, json: { ...state.data.json, components: action.payload } } }
        case ActionKind.SET_LAYOUT:
            return { ...state, data: { ...state.data, json: { ...state.data.json, layout: action.payload } } }
        case ActionKind.SET_TEMP_LAYOUT:
            return { ...state, tempLayout: action.payload }
        case ActionKind.SET_RULES:
            return { ...state, rules: action.payload }
        case ActionKind.SET_PDF:
            return { ...state, pdf: action.payload }
        case ActionKind.SET_URI:
            return { ...state, uri: action.payload }
        case ActionKind.SET_IMAGES:
            return { ...state, images: action.payload }
        case ActionKind.SET_PAGE_PLACEHOLDERS:
            return { ...state, pagePlacesHolders: action.payload }
        case ActionKind.SET_ERROR:
            return { ...state, error: action.payload }
        case ActionKind.SET_VALIDATION_MESSAGES:
            return { ...state, validationMessages: action.payload }
        case ActionKind.SET_OPEN_CONFIRM_CANCEL_DIALOGUE:
            return { ...state, openConfirmCancelDialogue: action.payload }
        case ActionKind.SET_PMSSYSTEM:
            return { ...state, pmsSystemVm: action.payload }
        case ActionKind.SET_LOADPMSSYSTEM:
            return { ...state, loadPmsSystem: action.payload }
        case ActionKind.SET_PMSSYSTEMLOADED:
            return { ...state, pmsSystemLoaded: action.payload }
        default:
            return state;
    }
}


const FormViewContext = React.createContext<{
    state: IState;
    dispatch: React.Dispatch<any>;
    mutate: () => void;
    handleSubmit: () => void;
    handleChange: (event, id, value, parentId?) => void;
    handleCancel: () => void;

}>({
    state: initialState,
    dispatch: () => null,
    mutate: () => null,
    handleSubmit: () => null,
    handleChange: (event, id, value, parentId?) => null,
    handleCancel: () => null
});


export const useFormViewContext = () => React.useContext(FormViewContext);

export const FormViewContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { data, error, mutate } = useSWR(state.id ? [Keys.AzureForm, state.id, state.assignmentId] : null, (url, formId, legacy) => fetcher(state.id, state.legacy));
    const canvasRefs = useRef([]);
    const patientsClient = clientService.getFormsClient(PatientsClient, null);
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;


    const fetcher = async (formId: string, legacy: boolean): Promise<IData> => {

        if (!formId)
            throw new Error("Form Id is Required.");

        const form = await patientsClient.getFormWithUri(formId, legacy);
        let newJson = {};
        
        if (form.azureBlobSasUri) {
            const service = new AzureService();
            const jsonString = await service.getJsonFromAzure(form.azureBlobSasUri);
            if (jsonString && jsonString !== '') {
                newJson = JSON.parse(jsonString);
            }
        }
        return { json: newJson, form: form };

    }

    const EnbableDisableChildComponents = (newComponents, row) => {
        row.children.map((column) => {
            column.children.map((layoutComponent) => {
                const component = newComponents[layoutComponent.id];
                component.disabled = !row.visible;
                if (component.childComponents) {
                    Object.keys(component.childComponents).map((childComponent) => {
                        const child = component.childComponents[childComponent];
                        child.disabled = !row.visible;
                    });
                }
            });
        });
    }

    const ShowHideRangeOfRows = (newLayout, newComponents, messages, overrideVisibility, visibility = null) => {

        if (messages?.length !== 2)
            return;

        const startInsurance = newLayout.findIndex((obj) => { return obj.id === messages[0]; });
        const endInsurance = newLayout.findIndex((obj) => { return obj.id === messages[1]; });
        newLayout.map((row, index) => {
            if (index >= startInsurance && index <= endInsurance) {

                if (row.visible === undefined)
                    row.visible = true;

                row.visible = overrideVisibility ? visibility : !row.visible;

                EnbableDisableChildComponents(newComponents, row);
            }
        });

    }

    const handleChange = (event, id: string, value: string, parentId?: string) => {
       
        if (state.preview && state.practiceView !== true)
            return;

        const newComponents = { ...state.data.json.components };
        const component = newComponents[id] || newComponents[parentId].childComponents[id];

        if (!component)
            return;

        const rule = state.data.json.rules?.find((obj) => { return obj.name === component.id; });

        if (rule) {

            const facts = { value: Array.isArray(value) ? component.value[0] : value };
            const engine = new Engine();
            engine.addRule(rule);
            engine
                .run(facts)
                .then(({ events, }) => {
                    events.map(event => {

                        const newLayout = [...state.data.json.layout];

                        switch (event.type) {
                            case 'ShowHide':
                                ShowHideRangeOfRows(newLayout, newComponents, event.params.message, false);
                                break;
                            case 'ShowHideInsurance':
                                ShowHideRangeOfRows(newLayout, newComponents, event.params.message, true, facts.value === '1');
                                break;
                            case 'SetValue':
                                event.params.message.map((message) => {
                                    if (newComponents[message]) {
                                        if (newComponents[message].type === SWITCHINPUT) {
                                            newComponents[message].value = facts.value === 'true' ? 'false' : '';
                                            delete newComponents[message].error
                                        }
                                    }
                                });
                                break;
                            case 'TypeOfPlan':
                                if (event.params.message?.length === 3) {
                                    const insuranceCompanyNameRow = newLayout[newLayout.findIndex((obj) => { return obj.id === event.params.message[0] })];
                                    const subscriberIdRow = newLayout[newLayout.findIndex((obj) => { return obj.id === event.params.message[1] })];
                                    const groupNumberRow = newLayout[newLayout.findIndex((obj) => { return obj.id === event.params.message[2] })];
                                    const subscriberIdLabel = newComponents[subscriberIdRow?.children?.[0]?.children?.[0]?.id];
                                    const subscriberIdComponent = newComponents[subscriberIdRow?.children[0]?.children[1]?.id];
                                    if (subscriberIdLabel && subscriberIdComponent) {
                                        const isNotMedicaid = (facts.value === '0' || facts.value === '2');
                                        insuranceCompanyNameRow.visible = isNotMedicaid;
                                        EnbableDisableChildComponents(newComponents, insuranceCompanyNameRow);
                                        groupNumberRow.visible = isNotMedicaid;
                                        EnbableDisableChildComponents(newComponents, groupNumberRow);
                                        subscriberIdLabel.content = isNotMedicaid ? 'Subscriber ID' : 'Medicaid ID';
                                        subscriberIdComponent.label = isNotMedicaid ? 'Subscriber ID' : 'Medicaid ID';
                                    }
                                }
                                break;
                        }

                        dispatch({ type: ActionKind.SET_LAYOUT, payload: newLayout });
                    });
                });
        }

        component.value = value || '';
        component.dirty = true;
        validate(component);
       
        if (parentId) validate(newComponents[parentId]);
        
        
        dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });

    }

    const handleSubmit = () => {

        if (state.preview && state.practiceView !== true)
            return;

        dispatch({ type: ActionKind.SET_SUBMITTING, payload: true });

        const newComponents = { ...state.data.json.components };

        Object.keys(newComponents).forEach(key => {
            validate(newComponents[key], true);
            validateSwitchInputDescription(newComponents[key]);
        });

        const hasErrors = Object.keys(newComponents).some((key) => {
            const component = newComponents[key];
            return (!component.disabled && component.error) || (component.childComponents && Object.keys(component.childComponents).some((childKey) => {
                const childComponent = component.childComponents[childKey];
                return !childComponent.disabled && childComponent.error;
            }));
        });

        if (!hasErrors) {
            commitSubmittion(state.data.json.components);
        } else {
            dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });
            formatValidationMessages(newComponents);
            dispatch({ type: ActionKind.SET_SUBMITTING, payload: false });
        }

    }

    const commitSubmittion = async (components) => {
        try {

            if (!state.assignmentId)
                throw new Error("Assignment Id is Required.");

            const responseArray = [] as ISubmissionJsonField[];

            const networkFields: Record<string, NetworkField> = {};

            Object.keys(components).forEach(key => {
                const component = components[key];
                if (component) {
                    if (component.value && component.type !== ADDRESS)
                        responseArray.push({ value: component.value, fieldId: component.fieldId, id: component.id, ...(component.externalId && { externalId: component.externalId }) });

                    if (component.childComponents) {
                        Object.keys(component.childComponents).forEach(childKey => {
                            const childComponent = component.childComponents[childKey];
                            if (childComponent) {
                                if (childComponent.value) {
                                    responseArray.push({ value: childComponent.value, fieldId: childComponent.fieldId, id: childComponent.id, ...(childComponent.externalId && { externalId: childComponent.externalId }) });
                                }
                                if (component.type === ALLERGIESPMS || component.type === PROBLEMSPMS) {
                                    networkFields[childComponent.id] = { label: childComponent.label, type: component.type, value: childComponent.value };
                                }
                            }
                        });
                    }
                }
            });

            const pmsFieldsbject = {
                id: SUBMISSION_PMS_FIELDS_DATA,
                value: networkFields
            } as ISubmissionJsonField;

            responseArray.push(pmsFieldsbject)

            let requiresPracticeSignature = false;
            if (state.data?.json?.components) {
                requiresPracticeSignature = Object.values(state.data?.json?.components as Map<string, IComponent>).some(value => value.type === SIGNATURE && value.practice === true);
            }

            const cmd = new CreateFormSubmissionCommand();
            cmd.formId = state.id;
            cmd.formAssignmentId = state.assignmentId;
            cmd.formSubmissionJson = JSON.stringify(responseArray);
            cmd.requiresPracticeSignature = requiresPracticeSignature;
            await patientsClient.create(cmd);
            dispatch({ type: ActionKind.SET_REDIRECT, payload: true });

        } finally {
            dispatch({ type: ActionKind.SET_SUBMITTING, payload: false });
        }
    }

    const handleCancel = () => {
        dispatch({ type: ActionKind.SET_OPEN_CONFIRM_CANCEL_DIALOGUE, payload: true });
    }

    const validateSwitchInputDescription = (component: IComponent) => {

        if (component.disabled)
            return;

        // explain text is required if 'yes' button is selected in switchInput
        if (component.type === SWITCHINPUT && component.value === 'true' && component.explaination && component.required && component.childComponents && Object.values(component.childComponents)[0].value.length === 0) {
            const childKey = Object.keys(component.childComponents)[0];
            const childComponent = component.childComponents[childKey];
            childComponent.error = { required: true, message: 'Please complete all required fields.' } as IFormViewError;
            return childComponent.error;
        }
        return;
    }

    const validate = (component: IComponent, submit?: boolean) => {

        if (component.disabled)
            return;

        // required
        let error = component.required && (submit || component.dirty) && component.type !== ADDRESS && component.type !== ALLERGIES && component.type !== PROBLEMSPMS && component.type !== ALLERGIESPMS && component.type !== PROBLEMS && component.type !== MEDICATIONS && (!component.value || component.value.length === 0);

        if (error) {
            component.error = { required: true, message: 'Please complete all required fields.' } as IFormViewError;
            return;
        }
        
        // invalid date
        if (component.type === 'DatePicker' && component.value) {
            const date = moment(component.value, 'mm/dd/yyyy', true);
            const minDate = '01/01/1900';
            const maxDate = '01/01/2050';
            if (!date.isValid() || moment(date, 'mm/dd/yyyy').isBefore(moment(minDate, 'mm/dd/yyyy')) || moment(date, 'mm/dd/yyyy').isAfter(moment(maxDate, 'mm/dd/yyyy'))) {
                component.error = { required: false, message: 'Please enter a valid date with a 4 digit year.' } as IFormViewError;
                error = true;
            }
        }

        // clear error and check regex
        if (!error) {
            delete component.error;
            validateRegex(component);
        }
        
        // validate child components
        if (component.childComponents) {
            Object.keys(component.childComponents).forEach(key => {
                validate(component.childComponents[key], submit);
            });
        }
    }

    const validateRegex = (component: IComponent) => {

        if (component.mask && component.value?.length > 0) {
            const maskRegExp = regex(component.mask);

            const match = component.value.match(maskRegExp);
            if (!match) {
                component.error = { required: false, message: 'Please enter a valid ' + component.label + '.' } as IFormViewError;
            } else {
                delete component.error;
            }
            if (component.childComponents) {
                Object.keys(component.childComponents).forEach(key => {
                    validateRegex(component);
                });
            }
        }
    }

    const formatValidationMessages = (newComponents) => {

        const errors = [];
        Object.keys(newComponents).forEach(key => {
            const component = newComponents[key];
            if (component) {
                if (component.error)
                    errors.push(component.error);

                if (component.childComponents) {
                    Object.keys(component.childComponents).forEach(key => {
                        const childComponent = component.childComponents[key];
                        if (childComponent) {
                            if (childComponent.error)
                                errors.push(childComponent.error);
                        }
                    });
                }
            }

        });

        const messages = { ...state.validationMessages };
        const errorValues = Object.values(errors);
        if (errorValues.some((err: IFormViewError) => { return err.required == true; })) {
            messages['required'] = ['Please complete all required fields.'];
        } else {
            messages['required'] = [];
        }
        const regexErrors = errorValues.filter((err: IFormViewError) => { return err.required == false });
        messages['regex'] = regexErrors.map((err: IFormViewError) => err.message);
        dispatch({ type: ActionKind.SET_VALIDATION_MESSAGES, payload: messages });

    }

    const convertPageToImage = async (pageNumber: number) => {

        if (!state.pdf)
            return;

        const page = await state.pdf.getPage(pageNumber + 1);
        const canvas = canvasRefs.current[pageNumber] as HTMLCanvasElement;

        if (!canvas)
            return;

        const scale = 1.5;
        const viewport = page.getViewport({ scale: scale, });
        // Support HiDPI-screens.
        const outputScale = window.devicePixelRatio || 1;

        const context = canvas.getContext('2d');

        if (!context)
            return;

        context.imageSmoothingEnabled = false;

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = viewport.height;
        canvas.style.height = viewport.width;

        const transform = outputScale !== 1
            ? [outputScale, 0, 0, outputScale, 0, 0]
            : null;


        const renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport
        };

        await page.render(renderContext).promise;

        return canvas.toDataURL('image/png');
    }

    useEffect(() => {

        if (!state.uri)
            return;

        const convertPdfToImages = async () => {

            if (!state.uri)
                return;

            const pdf = await pdfjsLib.getDocument({ url: state.uri }).promise;

            if (!pdf)
                return;

            dispatch({ type: ActionKind.SET_PDF, payload: pdf });

            const newPages = [];

            for (let index = 0; index < pdf.numPages; index++) {
                newPages.push(index);
            }

            dispatch({ type: ActionKind.SET_PAGE_PLACEHOLDERS, payload: newPages });

        }

        convertPdfToImages();
        
    }, [state.uri]);

    useEffect(() => {

        if (!state.pagePlacesHolders)
            return;

        const getPages = async () => {

            const newImages = [];

            for (let index = 0; index < state.pagePlacesHolders.length; index++) {
                const image = await convertPageToImage(index);

                newImages.push(image);

            }

            dispatch({ type: ActionKind.SET_IMAGES, payload: newImages });
            dispatch({ type: ActionKind.SET_LOADING, payload: false });

        }
      
        getPages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pagePlacesHolders]);

    const base64StringtoBlob = useCallback((b64Data: string): Blob => {

        if (!b64Data || b64Data.length === 0)
            return;

        const contentType = 'application/pdf';
        const sliceSize = 512;

        const split = b64Data.split(',');
        const byteCharacters = atob(split[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }, []);

    useEffect(() => {

        if (!state.data?.json?.pdf)
            return;

        const blob = base64StringtoBlob(state.data.json.pdf);

        dispatch({ type: ActionKind.SET_URI, payload: URL.createObjectURL(blob) });


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.data?.json?.pdf]);

    useEffect(() => {
        setTimeout(() => {
            dispatch({ type: ActionKind.SET_VALIDATION_MESSAGES, payload: emptyValidationMessages });

        }, 600000);
    }, [state.validationMessages]);

    useEffect(() => {
        
        if (error || !data)
            return;

        dispatch({ type: ActionKind.SET_DATA, payload: data });
        dispatch({ type: ActionKind.SET_ERROR, payload: error });
        dispatch({ type: ActionKind.SET_LOADING, payload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        if (!state.data?.json?.layout)
            return;

        const newLayout = state.data.json.layout.filter(row => (row.practice === true && state.practiceView) || (row.practice !== true && row.visible !== false));
        dispatch({ type: ActionKind.SET_TEMP_LAYOUT, payload: newLayout });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.data?.json?.layout]);

    useEffect(() => {
        
        if (!state.data?.json?.components || !state.pmsSystemVm.allergyFormFields || !state.pmsSystemVm.diseaseFormFields)
            return;

        dispatch({ type: ActionKind.SET_LOADPMSSYSTEM, payload: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pmsSystemVm, state.data?.json?.components]);

    useEffect(() => {

        if (!state.loadPmsSystem)
            return;

        const newComponents = { ...state.data.json.components };
  
        Object.keys(state.data?.json?.components).forEach(key => {
            let newComponent = { ...newComponents[key] };

            if (newComponent.key === ALLERGIESPMS) {
                newComponent = createPmsListComponent(newComponent, state.pmsSystemVm.allergyFormFields);
                Object.values(newComponent.childComponents).forEach((comp: any, index) => {
                    comp.required = newComponent.required;
                });
                newComponents[key] = newComponent;
            }
            if (newComponent.key === PROBLEMSPMS) {
                newComponent = createPmsListComponent(newComponent, state.pmsSystemVm.diseaseFormFields);
                Object.values(newComponent.childComponents).forEach((comp: any, index) => {
                    comp.required = newComponent.required;
                });
                newComponents[key] = newComponent;
            }
        });
    
        dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });
        dispatch({ type: ActionKind.SET_PMSSYSTEMLOADED, payload: true });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadPmsSystem]);

    return (
        <FormViewContext.Provider value={{
            state,
            dispatch,
            mutate,
            handleSubmit,
            handleChange,
            handleCancel
        }} >
            {children}
            {state.pagePlacesHolders && state.pagePlacesHolders.map((page, index) => (
                <canvas
                    key={`child-${index}`}
                    ref={(ref) => canvasRefs.current.push(ref)}
                    style={{ display: 'none' }}
                >
                </canvas>
            ))}
        </FormViewContext.Provider>
    );
};


