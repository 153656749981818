import { DataGrid, DataGridProps, GridCellParams, GridColumnHeaderParams } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';


const useStyles = makeStyles((theme) => ({
    grid: {
        display: 'flex',
        '& div.inner': {
            flexGrow: 1,
        }
    },
    loadingCell: {
        width: '70%'
    },
    dataGrid: {
        '& .MuiDataGrid-main': {
            border: theme.custom.dataGridBorder,
            borderRadius: '5px',
        },
        '& .MuiDataGrid-columnsContainer': {
            color: '#1C3D64',
            backgroundColor: theme.custom.backgroundLightGrey,
        },
        '& .MuiDataGrid-cell': {
            borderBottom: theme.custom.dataGridBorder,
        },
        '& .MuiTooltip': {
            color:'red'
        },
        '& .MuiIconButton-root': {
            padding: '4px',
            margin: '8px',
        },
        '& .MuiDataGrid-columnHeadersInner': {
            backgroundColor: theme.custom.backgroundLightGrey,
        },
        '& .MuiDataGrid-columnHeader': {
            backgroundColor: theme.custom.backgroundLightGrey,
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': { 
            outline: ' none'
        },
        '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
            outline: 'none'
        }
    }
}));



export interface IRwDataGridProps extends DataGridProps {
    loadingSkeleton?: boolean;
    hideMargin?: boolean;
}

const RwDataGrid: React.FC<IRwDataGridProps> = (props) => {
    const classes = useStyles();
    const { autoHeight, columns, pageSize, loadingSkeleton, rowCount } = props;

    if (loadingSkeleton || rowCount === null) {
        const loadingRows = Array.from(Array(pageSize)).map((o, i) => ({ id: i }));
        const loadingColumn = (params: GridCellParams) => (
            <div className={classes.loadingCell}>
                <Skeleton variant="text" height={20} />
            </div>
        );

        const renderHeader = (params: GridColumnHeaderParams) => (
            <Skeleton variant="text" width={80} height={30} />
        );

        const loadingColumns = columns.map(c => ({ ...c, renderHeader: renderHeader, renderCell: loadingColumn }));
        return (
            <div className={classes.grid} style={{ 'margin': (props.hideMargin ? '0' : '0px 90px') }}>
                <div className="inner">
                    <DataGrid key='loading' autoHeight={autoHeight} columns={loadingColumns} rows={loadingRows} rowHeight={props.rowHeight ?? 52}
                        className={classes.dataGrid} rowCount={pageSize} hideFooter={true} disableColumnMenu hideFooterSelectedRowCount/>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.grid} style={{ 'margin': (props.hideMargin ? '0' : '0px 90px') }}>
            <div className="inner">
                <DataGrid key='loaded' {...props} className={classes.dataGrid} autoHeight={true} disableColumnMenu hideFooterSelectedRowCount />
            </div>
        </div>
    );
}

export default RwDataGrid