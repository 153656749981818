import { Grid, IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import React from "react";
import IComponent from "../../../../services/forms.interface";
import makeStyles from '@mui/styles/makeStyles';
import classNames from "classnames";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

interface IImageData {
    image: string;
    title: string;
    size: number;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: '10px',
        padding: '10px',
    },
    formControl: {
        width: '100%'
    },
    textField: {
        width: '100%',
    },
    dialog: {
        minHeight: '270px',
        marginTop: '-20px',
    },
    dialogTitle: {
        paddingTop: '0px',
    },
    form: {
        width: "100%",
        height: '175px',
        position: "relative",
    },
    dragFileElement: {
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: "1rem",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    },
    labelFileUpload: {
        height: "175px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: "2px",
        borderRadius: "4px",
        borderStyle: "dashed",
        borderColor: "#cbd5e1",
        cursor: "pointer",
        textAlign: "center",
        '&.error': {
            borderColor: theme.custom.errorColor,
        },
        '&.drag-active': {
            borderColor: theme.custom.secondaryButtonColor,
        },
    },
    labelFileUploaded: {
        height: "175px",
        width: '100%',
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: "2px",
        borderRadius: "4px",
        borderStyle: "dashed",
        borderColor: "#cbd5e1",
        cursor: "cursor",
        textAlign: "center",
    },
    uploadButton: {
        borderRadius: '100px',
        backgroundColor: 'rgba(112, 203, 248, 0.16)',
        height: '64px',
        width: '64px',
    },
    uploadedButton: {
        borderRadius: '100px',
        backgroundColor: 'rgba(112, 203, 248, 0.16)',
        height: '64px',
        width: '64px',
        cursor: 'default'
    },
    inputFileUpload: {
        display: "none",
    },
    uploadLink: {
        textDecoration: 'underline',
        color: theme.custom.secondaryButtonColor,
    },
    agreementSection: {
        background: theme.custom.formRowBackgroundColor,
        borderRadius: '6px',
        padding: '20px',
        opacity: '.4',
        '&.enabled': {
            opacity: '1',
        },
    },
    image: {
        width: '50px',
        height: '50px',

    },
    p: {
        '&.error': {
            color: theme.custom.errorColor,
        },
    }
}));


const Attachment: React.FC<IComponent> = props => {
    const classes = useStyles();
    const [images, setImages] = React.useState<IImageData[]>([]);
    const [dragActive, setDragActive] = React.useState<boolean>(false);
    const [fileSizeError, setFileSizeError] = React.useState('');
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length) {
            processImages(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (inputRef.current.files && inputRef.current.files.length) {
            await processImages(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onUploadButtonClick = () => {
        inputRef.current.click();
    };

    const getBase64 = (file: File) => {
        const reader = new FileReader();
        const future = new Promise((resolve, reject) => {
            reader.addEventListener('load', () => {
                resolve(reader.result);
            }, false);
            reader.addEventListener('error', (event) => {
                reject(event);
            }, false);

            reader.readAsDataURL(file);
        });
        return future;
    }

    const processImages = async (files: File[]) => {

        const fileCount = files.length + images.length || 0;

        if (fileCount > 2) {
            setFileSizeError('A maximum of two files are allowed.');
            return;
        }

        let fileSize = files.length === 2 ? files[0].size + files[1].size : files[0].size;

        if (images.length) {
            fileSize = fileSize + images.map(a => a.size).reduce(function (a, b) {
                return a + b;
            });
        }

        if (fileSize > 10000000) {
            setFileSizeError('File size(s) are too large. Please upload file(s) less than 10MB total.');
            return;
        }

        setFileSizeError('');

        const newImages = [...images];
        for (const file of files) {
            const title = file.name.split('.')[0];
            const base64 = await getBase64(file) as string;
            newImages.push({ title: title, image: base64, size: file.size });
        }
        setImages(newImages);
        props.handleChange(event, props.id, newImages);
    }

    const removeImage = (index: number) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
        props.handleChange(event, props.id, newImages);
    }

    return (
        <div className={classes.wrapper}>
            {images.length < 2 &&
                <div className={classes.form}>
                    <form id="form-file-upload" className={classes.form} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} type="file" accept="image/png, image/x-png, image/gif, image/jpeg" id="input-file-upload" className={classes.inputFileUpload} multiple={true} onChange={handleChange} />
                        <label id="label-file-upload" htmlFor="input-file-upload" className={classNames(dragActive ? "drag-active" : "", classes.labelFileUpload, { error: props.hasOwnProperty('error') || fileSizeError.length > 0 })} onChange={() => { }}>

                            <Grid container>
                                <Grid item xs={12}>
                                    <IconButton className={classes.uploadButton} onClick={onUploadButtonClick}>
                                        <AttachFileOutlinedIcon color="primary" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <p className={classNames(classes.p, { error: fileSizeError.length > 0 }) }>{fileSizeError.length > 0? fileSizeError: 'Add up to 2 images here. Drag and drop or click to upload'}</p>
                                </Grid>
                            </Grid>

                        </label>
                        {dragActive && <div id="drag-file-element" className={classes.dragFileElement} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                    </form>
                </div>
            }

            {!!images.length &&
                <div className={classes.labelFileUploaded}>
                    <ImageList sx={{ width: '100%', height: 175 }} rowHeight={175} gap={25} cols={images.length}>
                        {images.map((item, index) => (
                            <ImageListItem key={item.title + index}>
                                <img
                                    src={item.image}
                                    srcSet={item.image}
                                    alt={item.title}
                                    loading="lazy"
                                    style={{ height: '175px', objectFit: 'contain' }}
                                />
                                <ImageListItemBar
                                    title={item.title}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.title}`}
                                            onClick={() => removeImage(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>
            }
        </div>
    );
}


export default Attachment;