import React, { useEffect, useState } from 'react';
import { FormVm } from '../../api/web-api-client';
import { TextField } from '@mui/material';
import { AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RwAutocomplete from './RwAutocomplete';
import Tooltip from '@mui/material/Tooltip';
import RwAccordion from './rw-accordion';
import { LocationData } from '../../hooks/use-rw-platform-client.interface';
import Skeleton from '@mui/material/Skeleton';
import { useAppContext } from '../../context/app-context';
import RwAlert from './RwAlert';

export enum FormModes {
    ReadOnly = 0,
    Edit = 1,
    Add = 2,
}

interface LocationWithFf extends LocationData {
    forms2Status: string
}

interface ILocationSectionProps {
    formMode: FormModes,
    formData: FormVm,
    allLocations: LocationData[],
    showLocationsWithoutForms2FeatureFlag?: boolean,
    hookupVm?: (data: FormVm) => void,
    showSkeleton: boolean,
    tooltip: string,
    defaultExpanded: boolean,
    showAllLocationsOnNoSelection: boolean,
    warnings?: string[],
    fullSizeChips?: boolean,
}

const RwLocationsPanel: React.FC<ILocationSectionProps> = (props) => {
    const [locations, setLocations] = useState([]);
    const [locationsWithFf, setLocationsWithFf] = useState<LocationWithFf[]>([]);
    const { allLocationsWithoutForms2FeatureFlag } = useAppContext();
    

    useEffect(() => {
        let allLocationsWithFfData = props.allLocations?.map(l => ({ ...l, forms2Status: 'Forms2 Locations' }) as LocationWithFf);
        if (props.showLocationsWithoutForms2FeatureFlag) {
            const allLocationsWithoutFf = allLocationsWithoutForms2FeatureFlag?.map(l => ({ ...l, forms2Status: 'Locations still on Legacy' }) as LocationWithFf);
            allLocationsWithFfData = allLocationsWithFfData.concat(allLocationsWithoutFf);
        }
        setLocationsWithFf(allLocationsWithFfData);
    }, [props.allLocations, props.showLocationsWithoutForms2FeatureFlag, allLocationsWithoutForms2FeatureFlag]);

    useEffect(() => {
        const formLocations = props.formData?.locationIds?.map(f => locationsWithFf.find(x => x.id == f)).filter(u => typeof u !== "undefined");
        const atLeastOneSelectedLocationDoesntHaveForms2 = props.formData?.locationIds
            ?.some(setId => allLocationsWithoutForms2FeatureFlag.map(l => l.id).includes(setId));
            // in case someone switches back from public yes to no, we need to remove any legacy locations that might have been added.
        if (props.formData?.locationIds?.length !== formLocations?.length && !props.showLocationsWithoutForms2FeatureFlag
            && atLeastOneSelectedLocationDoesntHaveForms2) {
            if (props.hookupVm) {
                const copy: FormVm = { ...props.formData, locationIds: formLocations.map(f => f.id) } as FormVm;
                props.hookupVm(copy);
            }
        }
        setLocations(formLocations);
    }, [props.formData, props.hookupVm, locationsWithFf, allLocationsWithoutForms2FeatureFlag]);

    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
        focused: {
            flexWrap: 'wrap'
        }
    }))

    const acClasses = useStyles();

    return (
        <RwAccordion title="Locations" name="locations" error={false} showSkeleton={props.showSkeleton} defaultExpanded={props.defaultExpanded}>
            <Tooltip title={props.formMode != FormModes.ReadOnly ? '' : props.tooltip} placement="left">
                <AccordionDetails>
                    {props.showSkeleton &&
                        <Skeleton variant="text" width={300} />
                    }
                    {!props.showSkeleton &&
                        <div style={{ width: '100%' }}>
                            {props.warnings?.length > 0 && props.warnings.map((warning, i) => (
                                <RwAlert key={i} severity="warning" style={{ marginBottom: 10 }}>
                                    {warning}
                                </RwAlert>
                            ))}
                            <RwAutocomplete
                                {...(props.formMode == FormModes.ReadOnly ? { disabled: true, readOnly: true } : {})}
                                id="location-selector"
                                limitTags={-1}
                                options={locationsWithFf}
                                {...(props.showLocationsWithoutForms2FeatureFlag ? { groupBy: (option) => option.forms2Status } : {})}
                                noOptionsText="No locations available"
                                classes={acClasses}
                                fullWidth
                                chipwidth={props.fullSizeChips ? 'none' : 300}
                            value={locations?.length ? locations :
                                ((props.formMode === FormModes.ReadOnly && props.showAllLocationsOnNoSelection) ? props.allLocations : [])}
                                getOptionLabel={(option) => option.name}
                            onChange={(_, valueArray: any[]) => {
                                if (props.hookupVm)
                                {
                                    const copy: FormVm = { ...props.formData, locationIds: valueArray.map(f => f.id) } as FormVm;
                                    props.hookupVm(copy);
                                }
                                }}
                                renderInput={params => (
                                    <TextField {...params} type="text" variant='outlined'
                                        label={locations?.length ? "Locations" : (props.showAllLocationsOnNoSelection ? "All Locations" : "No Locations")} />
                                )}
                            />
                        </div>
                    }

                </AccordionDetails>
            </Tooltip>
        </RwAccordion>
    );
}

export default RwLocationsPanel