/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer as RWDrawer } from "@revenuewell/ui-drawers";
import { DrawerSection } from './drawer-section';
import { DrawerButton } from './drawer-button';
import { useLayout } from './layout';
import { useNavigation } from '@revenuewell/logic-navigation';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import { Divider, ListItemIcon, TextField } from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { useAppContext } from "../../context/app-context";
import RwAutocomplete from '../shared/RwAutocomplete';
import { makeStyles } from "@mui/styles";
import { useSubmissionStatsContext } from '../../context/submission-stats-context';
import classNames from 'classnames';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

/* eslint-disable-next-line */
export interface IDrawerProps { }

const useStyles = makeStyles(theme => ({
    subMenu: {
        marginLeft: '25px'
    }
}))


export function Drawer(props: React.PropsWithChildren<IDrawerProps>) {
    const { menuFocus, setMenuFocus } = useLayout();
    const navigation = useNavigation();
    const location = useLocation();
    const { unreadSubmissionCount } = useSubmissionStatsContext();
    const classes = useStyles();

    const renderActiveState = (path: string) => {
        return location.pathname.indexOf(path) > 0 ? "active" : "";
    }

    const isSettingsActive = () => {
        return location.pathname.indexOf("forms") > 0 || location.pathname.indexOf("packets") > 0;
    }

    const onDrawerButtonClick = (path: string) => {
        setMenuFocus('content');
        navigation.push('/' + path);
    }

    const LocationFilter = () => {
        const { allLocations, selectedLocations, setSelectedLocations, locationFilterVisible } = useAppContext();

        const useStyles = makeStyles(theme => ({
            root: {
                width: 'auto',
                backgroundColor: theme.palette.background.paper
            },
            inputRoot: {
                flexWrap: 'wrap',
            },
            focused: {
                flexWrap: 'wrap'
            }
        }))

        const acClasses = useStyles();

        if (!locationFilterVisible || allLocations?.length <= 1)
            return <></>

        return (
            <>
                <RwAutocomplete
                    id="location-selector"
                    limitTags={1}
                    options={allLocations}
                    noOptionsText="Loading..."
                    classes={acClasses}
                    chipwidth={155}
                    value={selectedLocations?.length ? selectedLocations : []}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, valueArray) => {
                        setSelectedLocations(valueArray);
                    }}
                    renderInput={params => (
                        <TextField {...params} type="text" variant='outlined' label={selectedLocations?.length ? "Locations" : "All Locations"} />
                    )}
                />
                <Divider style={{ margin: '5px 0' }} />
            </>
        )
    }

    return (
        <RWDrawer focused={menuFocus == "drawer"}>
            <DrawerSection>
                <LocationFilter />
                <DrawerButton title="Patients"
                    id="library"
                    onClick={() => onDrawerButtonClick("submissions/index")} >
                    <ListItemIcon><Person2OutlinedIcon /></ListItemIcon>
                </DrawerButton>
                <DrawerButton title="Submissions"
                    id="submissions"
                    className={classNames(renderActiveState("submissions"), classes.subMenu)}
                    onClick={() => onDrawerButtonClick("submissions/index")}
                    badgeCount={unreadSubmissionCount} >
                    <ListItemIcon><AllInboxOutlinedIcon /></ListItemIcon>
                </DrawerButton>
                <DrawerButton title="Assignments"
                    id="assignments"
                    className={classNames(renderActiveState("assignments"), classes.subMenu)}
                    onClick={() => onDrawerButtonClick("assignments/index")} >
                    <ListItemIcon><AssignmentIndOutlinedIcon /></ListItemIcon>
                </DrawerButton>
                <DrawerButton title="Settings"
                    id="settings"
                    onClick={() => onDrawerButtonClick("forms/index")}>
                    <ListItemIcon><SettingsOutlinedIcon /></ListItemIcon>
                </DrawerButton>
                {isSettingsActive() &&
                    <>
                        <DrawerButton title="Forms"
                            id="library"
                            className={classNames(renderActiveState("forms"), classes.subMenu)}
                            onClick={() => onDrawerButtonClick("forms/index")} >
                            <ListItemIcon><LibraryBooksOutlinedIcon /></ListItemIcon>
                        </DrawerButton>
                        <DrawerButton title="Packets"
                            id="packets"
                            className={classNames(renderActiveState("packets"), classes.subMenu)}
                            onClick={() => onDrawerButtonClick("packets/index")} >
                            <ListItemIcon><AssignmentOutlinedIcon /></ListItemIcon>
                        </DrawerButton>
                    </>
                }
            </DrawerSection>
        </RWDrawer>
    );
}

