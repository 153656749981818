import React from 'react';
import { SWRConfig } from 'swr';
import { AppContextProvider } from '../../context/app-context';
import { SubmissionStatsContextProvider } from '../../context/submission-stats-context';
import FormsCustomThemeProvider from '../../themes/forms-custom-theme';
import { IntercomProvider } from 'react-use-intercom';
import LayoutProvider from './layout';
import { useConfig } from '@revenuewell/logic-config';

export default function AppProviders(props: React.PropsWithChildren<any>) {
    const { config } = useConfig();

    return (
        <SWRConfig value={{ revalidateOnFocus: false }}>
            <IntercomProvider appId={config.forms.intercomAppId}>
                <AppContextProvider>
                    <SubmissionStatsContextProvider>
                        <FormsCustomThemeProvider>
                            <LayoutProvider>
                                {props.children}
                            </LayoutProvider>
                        </FormsCustomThemeProvider>
                    </SubmissionStatsContextProvider>
                </AppContextProvider>
            </IntercomProvider>
        </SWRConfig>
    );
}