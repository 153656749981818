import React, { lazy, Suspense, useEffect } from 'react';
import { useAppContext } from '../../../context/app-context';
import Container from '../view/container';

export const ViewFormSkeleton: React.FC = () => {
    return (
        <Container />
    );
};


const Forms1Forms = lazy(() => import(/* webpackChunkName: "single-source-view" */ '../forms1'));

const LazyForms1Forms: React.FC = () => {
    const { setDrawerVisible, setProductMenuVisible } = useAppContext();

    useEffect(() => {
        setDrawerVisible(false);
        setProductMenuVisible(false);
    }, [setDrawerVisible, setProductMenuVisible]);

    return (
        <Suspense fallback={<ViewFormSkeleton />}>
            <Forms1Forms />
        </Suspense>
    );
};

export const Forms1FormsModule =
{
    routeProps: {
        path: '/forms/forms1/:id?',
        component: LazyForms1Forms,
        private: true
    },
    name: 'Legacy Form',
};