import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Typography } from '@mui/material';
import IComponent from '../../../../services/forms.interface';
import styled from '@emotion/styled';

interface ICustomProps {
    name: string;
    type: string;
    value: string;
    defaultValue: string;
    disabled: boolean;
    read_only: string;
    required: boolean;
    maskKey: string;
    error: any;
    onChange: any;
    onBlur: any;
    label: string;
}

interface IProblemProps {
    parentId: string;
    localProps: ICustomProps;
    count: number;
    maxRows: number;
    index: number;
    addProblem: () => void;
    components: IComponent[];
    isMobile?: boolean;
    disabled: boolean;
    handleChange: (event: any, id: string, value: string, parentId: string) => void;
}

const useStyles = makeStyles((theme) => ({
    ProblemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%'
    },
    Problem: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '100%',
        margin: '5px'
    },
    addIcon: {
        width: '30px',
    },
    addDiv: {
        flexBasis: '30px',
        minWidth: '30px',
        jusifyContent: 'flex-end'
    },
    textFieldName: {
        flexBasis: '100%',
        marginRight: '5px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '100%',
        flexWrap: (props: any) => props.isMobile ? 'wrap' : 'nowrap',
    },
    column1: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'row',
        marginTop: '5px',
        flexBasis: (props: any) => props.isMobile ? '100%' : '30%',
    },
    column2: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'row',
        margin: '5px 10px 5px 5px',
        flexBasis: (props: any) => props.isMobile ? '100%' : '68%',
        flexWrap: (props: any) => props.isMobile ? 'wrap' : 'nowrap',
        justifyContent: 'flex-end'
    }
}));



const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F9F9F9',
    },
});

const Problem: React.FC<IProblemProps> = props => {
  
    const classes = useStyles({ isMobile: props.isMobile });

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event)
            props.handleChange(event, event.target.id.substring(0, 36), event.target.value, props.parentId);
    };

    const localProps: ICustomProps = {} as ICustomProps;

    localProps.type = 'text';
    localProps.disabled = props.disabled;

    if (props.disabled)
        localProps.disabled = true;

    return (
        <div className={classes.row}>
            <div className={classes.column1}>
                <Typography variant="subtitle1" fontSize={17}>
                    {'Illness #' + (+props.index + 1)}
                </Typography></div>
            <div className={classes.column2}>
                <StyledTextField fullWidth variant="outlined"
                    id={props.components[0].id}
                    name={props.components[0].name}
                    onChange={handleChange}
                    {...localProps} size="small"
                     InputProps={{ className: classes.textFieldName }} margin="none"               />
                <div className={classes.addDiv}>
                    {!props.disabled && props.count < props.maxRows && props.index === props.count - 1 &&
                        <IconButton aria-label="Add" onClick={props.addProblem}>
                            <AddIcon className={classes.addIcon} color="primary" arial-label="add an Problem" />
                        </IconButton>
                    }
                </div>

            </div>
        </div>)
}

const Problems: React.FC<IComponent> = props => {
    const classes = useStyles({ isMobile: props.isMobile });
    const [count, setCount] = useState(1);

    const maxRows = Object.keys(props.childComponents).length;

    const isRequired = (props.hasOwnProperty('required') && props.required === true && !props.read_only);

    const localProps: ICustomProps = {} as ICustomProps;
    localProps.type = 'text';

    localProps.required = isRequired;

    if (props.error) { localProps.error = true; }

    if (props.disabled)
        localProps.disabled = true;

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }


    const addProblem = () => {
        if (count <= maxRows)
            setCount(count + 1);
    }

    const getComponents = (index) => {
        index++;
        const row = [];
        Object.values(props.childComponents).forEach((comp: any, i) => {
            if (comp?.name === 'health_history_problem_' + index + '_name')
                row.push(comp);
        });
        return row;
    }

    const list = []
    for (let i = 0; i < count; i++) {
        list.push(<Problem key={i} parentId={props.id} localProps={localProps} count={count} index={i} addProblem={addProblem} components={getComponents(i)} handleChange={props.handleChange} isMobile={props.isMobile} maxRows={maxRows} disabled={localProps.disabled} />)
    }


    return (
        <div className={baseClasses}>
            <div className={classes.ProblemsContainer}>
                {list}
            </div>
        </div>
    );
}

export default Problems;



