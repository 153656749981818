import { Box, Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import React from 'react';
import RwValidationErrors from './RwValidationErrors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from "@mui/material/styles";


export interface IRwActionBarButtonProps {
    id?: string;
    buttonStyle?: 'buttonPreview' | 'buttonSuccess' | 'buttonCancel' | 'buttonSettings' | 'buttonWarning' | 'buttonDisable';
    text: string;
    alternateText?: string;
    color?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
    variant?: 'text' | 'outlined' | 'contained';
    to?: string;
    onClick?: () => void;
    type?: 'button' | 'reset' | 'submit';
    disabled?: boolean;
    addArrowToText?: boolean;
    visible?: boolean;
}

export interface IRwActionBarProps {
    patientName?: string;
    leftButtons?: IRwActionBarButtonProps[];
    rightButtons?: IRwActionBarButtonProps[];
    validationMessages?: Record<string, string[]>;
    validationFadeout?: boolean;
    handleErrorMessageClose?: ((keyIndex: number, valueIndex: number) => void) | (() => void);
    fullWidth?: boolean;
    width?: string,
    marginLeft?: string,
    marginRight?: string
}
export const actionBarTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        backgroundColor: 'lightgray',
                        color: 'darkgray'
                    }
                }
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    actionBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        background: 'rgba(32, 31, 44, 0.8)',
        borderRadius: '6px',
        padding: 10,
        height: '40px',
        
        zIndex: 1250,
        marginTop: '20px',
    },
    validationMessages: {
        position: 'absolute',
        width: '100%',
        zIndex: 2,
        borderRadius: '6px',
        margin: '11px 0px',
        bottom: 80,
        "&& li": {
            color: '#fff',
        },
        pointerEvents: 'none'
    },
    buttonPreview: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        padding: '8px 22px',
        position: 'static',
    },
    buttonWarning: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 22px',
        position: 'static',
    },
    buttonSuccess: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 22px',
        position: 'static',
    },
    buttonCancel: {
        backgroundColor: theme.custom.cancel,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '8px 22px',
        position: 'static',
    },
    buttonDisable: {
        backgroundColor: theme.palette.text.secondary,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '8px 22px',
        position: 'static',
    },
    buttonSettings: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        alignSelf: 'flex-end',
        alignItems: 'center',
        padding: '8px 22px',
        position: 'static',
    },
    leftButtons: {

    },
    rightButtonsFirstElement: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
    },
    rightButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    wrapper: {
        width: (props: any) => props.width ? props.width : '',
        position: 'relative',
        marginLeft: (props: any) => props.marginLeft ? props.marginLeft : 'auto',
        marginRight: (props: any) => props.marginRight ? props.marginRight : 'auto',
    },
    bar: {
     
        justifyContent: 'center',
        width: '100%',
        alignSelf: 'flex-end',
        marginBottom: '25px'
    }
}));



const ActionBarButton: React.FC<IRwActionBarButtonProps> = (props) => {
    const classes = useStyles(props);
    const { buttonStyle, to, color, text, addArrowToText, visible, ...other } = props;

    if (visible === false)
        return <></>;

    if (to) {
        return (
            <Button component={Link} to={to} color={color === 'primary' ? 'inherit' : color} className={classes[buttonStyle]} {...other}>
                {props.text}
                </Button>
        )
    }

    return (
        <Button color={color} className={classes[buttonStyle]} type={props.type ?? 'button'} {...other}>
            {text} {addArrowToText && <ArrowForwardIcon fontSize="small" sx={{paddingLeft: '7px'}}/>}
        </Button>
    );
}

ActionBarButton.defaultProps = {
    color: 'primary',
    variant: 'contained'
}



const RwActionBar: React.FC<IRwActionBarProps> = (props) => {

    const classes = useStyles(props);

    return (
        <div className={classes.wrapper}
        ><div className={classes.bar}>
            <ThemeProvider theme={actionBarTheme}>
                {props.validationMessages && Object.values(props.validationMessages).length > 0 &&
                <Box className={classes.validationMessages}>

                <RwValidationErrors errors={props.validationMessages} handleclose={props.handleErrorMessageClose} fadeout={props.validationFadeout}/>
                </Box>
            }
            <Box
                component="div"
                className={classes.actionBar}
                    {...(props.fullWidth ? { style: { width: '100%', marginBottom: 0} } : {})}
            >
               
                <Grid container id='container1' direction='row' spacing={1}>
                    {/* left buttons*/}

                        {props.leftButtons &&
                            props.leftButtons.map(button => {
                                return (<Grid item key={button.text} className={classes.leftButtons}>
                                    <ActionBarButton id={button.id} key={button.text} buttonStyle={button.buttonStyle ?? 'buttonPreview'} {...button} />
                                </Grid>);
                            })
                        }

                    {props?.patientName &&
                        <Grid item sx={{ width: '40vw', textAlign: 'center', alignSelf: 'center', color: '#fff', textOverflow: 'ellipsis', overflow: 'hidden', flexGrow: 1 }}>
                            {props.patientName}
                        </Grid>}

                    {/* right buttons*/}
                        {props.rightButtons &&
                            props.rightButtons.map((button, index) => {
                                return (<Grid key={button.text} item className={(!props?.patientName && index === 0) ? classes.rightButtonsFirstElement : classes.rightButtons}>
                                    <ActionBarButton key={button.text} buttonStyle={button.buttonStyle ?? 'buttonCancel'} {...button} />
                                </Grid>);
                            })
                        }
                </Grid>
                </Box>
            </ThemeProvider>
        </div></div>
    );


}
export default RwActionBar

