import { useState } from 'react';
import { AxiosInstance } from 'axios';
import { httpService } from '../services/httpService';
import { OidcContextProps } from '@revenuewell/logic-oidc/hooks/oidc/types/oidc-context-props';
import { useOidc } from '@revenuewell/logic-oidc';

const useAuthenticatedRequest = (): AxiosInstance => {
    const [ready, setReady] = useState(false);
    const { getAccessToken, logoutRedirect }: OidcContextProps = useOidc();
    const instance = httpService.GetInstance();

    if (ready) {
        return instance;
    }
    instance.interceptors.request.use(async (config) => {
        const token = await getAccessToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });
    instance.interceptors.response.use(response => response,
        error => {
            const { status } = error.response;
            if (status === 401 || status === 403) {
                logoutRedirect();
            }
            return Promise.reject(error);
        });
    setReady(true);

    return instance;
};

export default useAuthenticatedRequest;