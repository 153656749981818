import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import DraggableField from './draggable-field';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';


const useStyles = makeStyles((theme) => ({
    toolBarItem: {
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '2px solid',
        borderColor: theme.custom.listItemBorderColor,
        backgroundColor: theme.custom.listItemBackground,
    },
    toolBarItemDragIcon: {
        justifyContent: 'flex-end',
        color: theme.custom.listItem,
        marginLeft: '10px',
        "&:hover": {
            cursor: 'move'
        }
    },
}));

const DraggableRow = ({ block, container }) => {
    const classes = useStyles();
    const { attributes, listeners, setNodeRef } = useDraggable({
        id: container,
        data: {
            type: "card",
        },
    });


    if (!block)
        return <></>;

    return (
        <div ref={setNodeRef} className={classes.toolBarItem}>
            <DraggableField block={block} container="A" key={block?.id}></DraggableField>
            {block?.data?.fields?.map((field, index) => <DraggableField block={field} container="B" key={field?.id}></DraggableField>)}

            <Tooltip title="Drog to add this item as a new row">
                <TableRowsRoundedIcon className={classes.toolBarItemDragIcon} {...listeners} {...attributes} />
            </Tooltip>

        </div>
    );
}

export default DraggableRow;