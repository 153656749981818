import { useConfig } from '@revenuewell/logic-config';
import { useCallback } from 'react';
import { useAppContext } from '../context/app-context';
import { httpService } from '../services/httpService';
import IRwPlatformClient, { LocationData, Contact, PatientInfo } from './use-rw-platform-client.interface';
import { useLocation } from 'react-router-dom';

export interface RwLocationProduct {
    id: number;
    isAvailable: boolean;
}

const useRwPlatformClient = (): IRwPlatformClient => {
    const instance = httpService.GetInstance();
    const { config } = useConfig();
    const { allLocations, getMasterAccountId } = useAppContext();
    const { search } = useLocation();
    const getContactDomain = config.forms.contactDomain;
    const getPhoneDomain = config.forms.phoneDomain;

    const getLocations = useCallback(async (): Promise<LocationData[]> => {
        const res = await instance.get(`${config.forms.accountApiUrl}/locations`)
        return res.data;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getLocationsByAccountId = useCallback(async (accountId: number): Promise<LocationData[]> => {
        const res = await instance.get(`${config.forms.accountApiUrl}/master-accounts/${accountId}/locations`);
        return res.data;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getContact = useCallback(async (patientId: number): Promise<Contact> => {
        const res = await instance.get(`${config.forms.contactsApiUrl}/contacts/${patientId}`)
        return res.data;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getPatientInfo = useCallback(async (patientId: number): Promise<PatientInfo> => {
        const res = await instance.get(`${config.forms.contactsApiUrl}/contacts/${patientId}/patientinfo`)
        return res.data;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getAccountContacts = useCallback(async (pageNumber: number, pageSize: number, search?: string, locationIds?: number[]): Promise<Contact[]> => {
        if (!pageNumber)
            throw Error('The page number starts at 1.');

        const accountId = await getMasterAccountId();
        let url = `${config.forms.contactsApiUrl}/master-accounts/${accountId}/contacts?sortBy=LastName&pageNumber=${pageNumber}&pageSize=${pageSize}`;
        if (search) {
            url += `&pattern=${search}`;
        }
        // you need to add locations or the api does funny things
        const locationIdsToUse = locationIds?.length ? locationIds : allLocations.map(l => l.id);
        url += `&locationIds=${locationIdsToUse.join()}`;

        const data = await instance.get(url);
        return data.data.data;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getContactMessengerUrl = useCallback(async (contactId: number, locationId: number): Promise<string> => {

        const url = `${config.forms.messengerApiUrl}/messenger/deep-link?contactId=${contactId}&locationId=${locationId}`
        const res = await instance.get(url);
        return res.data.directUrl + search;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIfForms2AvailableForLocations = useCallback(async (locationIds: number[]): Promise<RwLocationProduct[]> => {
        const url = `${config.forms.productApiUrl}/product/IsProductAvailableForLocations?locationIds=${locationIds.join(',')}&product=Forms2`;
        const res = await instance.get(url);
        return res.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return {
        getPhoneDomain,
        getContactDomain,
        getContact,
        getContactMessengerUrl,
        getLocations,
        getAccountContacts,
        getPatientInfo,
        getIfForms2AvailableForLocations,
        getLocationsByAccountId
    };
}

export default useRwPlatformClient;