import React from 'react';
import IComponent from '../../../../services/forms.interface';
import "react-quill/dist/quill.core.css";
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        marginTop: '7px',
    }
}));


const Paragraph: React.FC<IComponent> = props => {
    const classes = useStyles();

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }

    return (
        <div className={baseClasses}>
            <div className={classNames("view ql-editor", classes.root)} dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.content, { allowedAttributes: { 'div': ['class'], 'p': ['class'], 'a': ['href', 'target'] } }) }} />
        </div>
    );
}

export default Paragraph;
