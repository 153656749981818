import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { ILayoutContext, MenuFocus } from './types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

export const LayoutContext = createContext<ILayoutContext>(null!);

const useStyles = makeStyles((theme) => ({
    back: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    content: {
        width: '100%',
        '&.desktop': {
            maxWidth: '350px',
        },
    }
}));

const Content = ({ children }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const { menuFocus } = useLayout();
    const classes = useStyles();

    if (!isDesktop && menuFocus !== "content")
        return <></>;

    return <div className={classNames(classes.content, { desktop: isDesktop})}>{children}</div>;
}

interface IBack {
    focusOn: MenuFocus;

}

const Back = (props: React.PropsWithChildren<IBack>) => {
    const theme = useTheme();
    const { setMenuFocus } = useLayout();
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
   
    if (isDesktop)
        return <>{props.children}</>;

    return (
        <div className={classes.back}>
            <IconButton aria-label="back" onClick={() => setMenuFocus(props.focusOn)} >
                <ArrowBackIcon />
            </IconButton>
            {props.children}
        </div>
    )
}

const SubContent = ({ children }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const { menuFocus } = useLayout();

    if (!isDesktop && menuFocus !== "subcontent")
        return <></>;

    return <>{children}</>;
}

const LayoutProvider = (props: React.PropsWithChildren<unknown>) => {
    const [menuFocus, setMenuFocus] = useState<MenuFocus>("drawer");

    const contextValue = {
        menuItems: ['drawer', 'content', 'subcontent'],
        menuFocus,
        setMenuFocus: setMenuFocus,
    } as ILayoutContext;

    return <LayoutContext.Provider value={{ ...contextValue }}>{props.children}</LayoutContext.Provider>;
}

// wrap content to be shown when menuFocus is "content" in <LayoutProvider.Content></LayoutProvider.Content>
LayoutProvider.Content = Content;
// wrap content to be shown when menuFocus is "subcontent" in <LayoutProvider.SubContent></LayoutProvider.SubContent>
LayoutProvider.SubContent = SubContent;
// wrap componet that you would like to place a back arrow next to in <LayoutProvider.Back></LayoutProvider.Back>
LayoutProvider.Back = Back;

export default LayoutProvider;

export function useLayout() {
    const context = useContext(LayoutContext);

    if (!context) {
        throw new Error('useLayout must be used within the LayoutProvider');
    }

    return context;
}

