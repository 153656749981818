import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import IComponent from '../../../../services/forms.interface';
import TextField from "@mui/material/TextField";
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, CircularProgress, IconButton, styled, Typography } from '@mui/material';
import useSWR from 'swr';
import Keys from '../../../../services/keys';
import clientService from '../../../../services/clientService';
import { FormsClient } from '../../../../api/web-api-client';
import { useDebouncedCallback } from 'use-debounce';
import { IMedicationVm } from '../interfaces';
import { useAppContext } from '../../../../context/app-context';

interface ICustomProps {
    name: string;
    type: string;
    value: string;
    defaultValue: string;
    disabled: boolean;
    read_only: string;
    required: boolean;
    maskKey: string;
    error: any;
    onChange: any;
    onBlur: any;
    label: string;
}

interface IMedicationrops {
    parentId: string;
    localProps: ICustomProps;
    count: number;
    maxRows: number;
    index: number;
    addMedication: () => void;
    components: IComponent[];
    isMobile?: boolean;
    disabled: boolean;
    handleChange: (event: any, id: string, value: string, parentId: string) => void;
}

const useStyles = makeStyles((theme) => ({
    medicationsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
    },
    medication: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '100%',
    },
    addIcon: {
        width: '30px',
    },
    addDiv: {
        flexBasis: '30px',
        minWidth: '30px',
        jusifyContent: 'flex-end'
    },
    textFieldName: {
        flexBasis: (props: any) => props.isMobile ? '100%' : '35%',
        marginBottom: (props: any) => props.isMobile ? '5px' : '0px',
        marginRight: '5px'
    },
    textFieldFrequency: {
        flexBasis: (props: any) => props.isMobile ? '100%' : '20%',
        marginBottom: (props: any) => props.isMobile ? '5px' : '0px',
        marginRight: '5px'
    },
    textFieldDosage: {
        flexBasis: (props: any) => props.isMobile ? '100%' : '35%',
        marginBottom: (props: any) => props.isMobile ? '5px' : '0px',
        marginRight: '5px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '100%',
        flexWrap: (props: any) => props.isMobile ? 'wrap' : 'nowrap',
    },
    column1: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'row',
        marginTop: '10px',
        flexBasis: (props: any) => props.isMobile ? '100%' : '30%',
    },
    column2: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'row',
        margin: '5px',
        flexBasis: (props: any) => props.isMobile ? '100%' : '68%',
        flexWrap: (props: any) => props.isMobile ? 'wrap' : 'nowrap',
        justifyContent: 'flex-end'
    }
}));

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F9F9F9',
    },
    '&.MuiAutocomplete-root': {
        marginRight: '5px'
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F9F9F9',
    },
});




const Medication: React.FC<IMedicationrops> = props => {
    const [vm, setVm] = useState({ inputValue: '', searchError: false } as IMedicationVm);
    const classes = useStyles({ isMobile: props.isMobile });
    const { data, isValidating } = useSWR([Keys.ApiMedications, vm.inputValue], (url, search: string) => medicationsFetcher(search).then(res => res));
    const { unwrapperedDomain } = useAppContext();

    const medicationsFetcher = async (searchText: string) => {
        if (!searchText)
            return null;
        try {
            const client = clientService.getFormsClient(FormsClient, { apiPath: unwrapperedDomain });
            const result = await client.listMedications(searchText);
            result.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)
            return result;
        } catch (e) {
        }
    }

    const handleNameChange = (event, value) => {
        if(value)
            props.handleChange(event, event.target.id.substring(0, 36), value, props.parentId);
    };

    const handleChange = (event) => {
        props.handleChange(event, event.target.id, event.target.value, props.parentId);
    };

    const debouncedInput = useDebouncedCallback(
        
        (value) => {
            if (value.length > 0 && value.length <= 2) {
                setVm({ ...vm, searchError: true });
            } else {
                setVm({ ...vm, inputValue: value, searchError: false });
            }
        },
        800
    );

    return (
        <div className={classes.row}>
            <div className={classes.column1}>
                <Typography variant="subtitle1" fontSize={17}>
                    {'Medication #' + (+props.index + 1)}
            </Typography></div>
            <div className={classes.column2}>
                <StyledAutocomplete
                    id={props.components[0].id}
                    options={!data || isValidating ? [] : data}
                    freeSolo
                    sx={{ mr: 1 }}
                    filterOptions={(x) => x}
                    autoComplete
                    fullWidth
                    includeInputInList
                    filterSelectedOptions
                    onChange={(event, value) => handleNameChange(event, value)}
                    onInputChange={(event, newInputValue) => {
                        // ignore blur (clicking off without selecting) and click events (clicking the list), those aren't someone typing
                        if (event?.type !== 'change')
                            return;
                        debouncedInput(newInputValue);
                        handleChange(event);
                    }}
                    onBlur={(elem) => {
                        setVm({ ...vm, searchError: false });
                        return;
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={vm.searchError ? 'Min. 3 characters' : 'Select Medication'} fullWidth variant="outlined"
                            {...props.localProps} name={props.components[0].name} className={classes.textFieldName} size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isValidating ? <CircularProgress size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                            error={vm.searchError}
                        />
                    )}
                />
                <StyledTextField variant="outlined"  {...props.localProps} onBlur={handleChange} id={props.components[1].id} label={props.components[1].label} name={props.components[1].name} className={classes.textFieldFrequency} size="small" />
                <StyledTextField variant="outlined"  {...props.localProps} onBlur={handleChange} id={props.components[2].id} label={props.components[2].label} name={props.components[2].name} className={classes.textFieldDosage} size="small" />
                <div className={classes.addDiv}>
                    {!props.disabled && props.count < props.maxRows && props.index === props.count - 1 &&
                        <IconButton aria-label="Add" onClick={props.addMedication}>
                            <AddIcon className={classes.addIcon} color="primary" arial-label="add a medication" />
                        </IconButton>
                    }
                </div>
            </div>
        </div>)
}

const Medications: React.FC<IComponent> = props => {
    const classes = useStyles({ isMobile: props.isMobile });
    const [count, setCount] = useState(1);

    const maxRows = Object.keys(props.childComponents).length / 3;

    const isRequired = (props.hasOwnProperty('required') && props.required === true && !props.read_only);

    const localProps: ICustomProps = {} as ICustomProps;
    localProps.type = 'text';

    localProps.required = isRequired;

    if (props.error) { localProps.error = true; }

    localProps.disabled = props.disabled;

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }
    
    const addMedication = () => {
        if (count <= maxRows)
            setCount(count + 1);
    }

    const getComponents = (index) => {
        index++;
        const row = [];
        Object.values(props.childComponents).forEach((comp:any, i) => {
            if (comp.name === 'health_history_dosage' + index + '_name')
                row.push(comp);

            if (comp.name === 'health_history_frequency' + index + '_name')
                row.push(comp);

            if (comp.name === 'health_history_medication' + index + '_name')
                row.push(comp);
        });
        return row;
    }


    const list = [];
    const medications = props.medications?.length > 0 ? props.medications : [''];
    if (medications) {
        for (let i = 0; i < count; i++) {
            list.push(<Medication key={i} parentId={props.id} localProps={localProps} count={count} index={i} addMedication={addMedication} components={getComponents(i)} handleChange={props.handleChange} isMobile={props.isMobile} maxRows={maxRows} disabled={localProps.disabled} />)
        }
    }
    return (
        <div className={baseClasses}>
            {list && list.length > 0 &&
                <div className={classes.medicationsContainer}>
                    {list}
                </div>
            }
        </div>
    );

}

export default Medications;



