import { AppointmentStatus } from "../api/web-api-client";

export const getPatientPortalColor = (color: string = 'blue' || 'green' || 'purple' || 'red' || 'orange') => {
    switch (color) {
        case 'blue': {
            return '#2078CF';
        }
        case 'green': {
            return '#007766';
        }
        case 'purple': {
            return '#564CFA';
        }
        case 'red': {
            return '#D60345';
        }
        case 'orange': {
            return '#FD9100';
        }
    }
}
export const getPatientPortalColorRGB = (color, opacity) => {
    switch (color) {
        case 'blue': {
            return 'rgb(32, 120, 207, ' + opacity + ')';
        }
        case 'green': {
            return 'rgb(0, 119, 102, ' + opacity + ')';
        }
        case 'purple': {
            return 'rgb(86, 76, 250, ' + opacity + ')';
        }
        case 'red': {
            return 'rgb(214, 3, 69, ' + opacity + ')';
        }
        case 'orange': {
            return 'rgb(253, 145, 0, ' + opacity + ')';
        }
    }
}

export const getAppointmentStatusColor = (status: AppointmentStatus) => {
    return status === AppointmentStatus.Unconfirmed ? "#F9BE28" : "#008A06"
}


export const patientStyles = {
    background: '#F1F2F5',
    pageBackgroundColor: '#F7F8FC',
    successBackgroundColor: '#4DAD51',
    secondaryButtonBackgroundColor: 'rgba(0, 125, 255, 0.08)',
    secondaryButtonBackgroundColorHover: 'rgba(0, 125, 255, 0.16)',
    secondaryButtonColor: '#089af7',
    footerColor: "#2C3542",
}


