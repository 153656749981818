import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AccordionDetails, AccordionDetailsProps, Box, Tooltip, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';


export interface IRwAccordionRowProps extends AccordionDetailsProps {
    label?: string;
    toolTip?: string;
    variant: 'input' | 'inputWithIcon' | 'text',
    showSkeleton?: boolean;
    fullRowLabel?: boolean;
}
const useStyles = makeStyles((theme) => ({
    row: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'left',
        alignItems: 'center',
        padding: '11px 11px 5px 11px',
    },
    input: {
        width: '100%',
        marginLeft: '16px',
        marginRight: '73px'
    },
    inputWithIcon: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        alignItems: 'center',
    },
    text: {
        paddingRight: '16px'
    }
}));

const RwAccordionRow: React.FC<IRwAccordionRowProps> = (props) => {
    const classes = useStyles();
    const toolTip = props.toolTip ?? '';

    return (
        <Tooltip title={toolTip} placement="left">
            <AccordionDetails>
                <Box className={classes.row}>
                    {props.showSkeleton &&
                        <>
                            <Skeleton variant="text" width={130} style={{ marginRight: 35 }} />
                                <Box className={classes[props.variant]}>
                                    <Skeleton variant="text" width={300} />
                                </Box>
                        </>
                    }
                    {!props.showSkeleton &&
                        <>
                        {props?.label &&
                            <Typography variant="subtitle2" style={{ width: props.fullRowLabel ? '100%' : '180px' }}>{props.label}</Typography>
                        }
                        {!props.fullRowLabel &&
                            <Box className={classes[props.variant]}>
                                {props.children}
                            </Box>
                        }
                        </>
                    }
                </Box>
            </AccordionDetails>
        </Tooltip>
    );
}
export default RwAccordionRow

