
import React, { lazy, Suspense, useEffect } from 'react';
import { useAppContext } from '../../../context/app-context';
import { FormSkeleton } from '../shared/skeleton';

export const ViewFormSkeleton: React.FC = () => {
    return (
        <FormSkeleton />
    );
};


const ViewForm = lazy(() => import(/* webpackChunkName: "forms-view" */ '../view'));

const LazyViewForm: React.FC = () => {
    const { setDrawerVisible, setProductMenuVisible } = useAppContext();

    useEffect(() => {
        setDrawerVisible(false);
        setProductMenuVisible(false);
    }, [setDrawerVisible, setProductMenuVisible]);

    return (
        <Suspense fallback={<ViewFormSkeleton />}>
            <ViewForm />
        </Suspense>
    );
};

export const ViewFormModule =
{
    routeProps: {
        path: '/forms/view/:id/:legacy?',
        component: LazyViewForm,
        private: true
    },
    name: 'View Form',
};