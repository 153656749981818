import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { usePatientAppContext } from '../../context/patient-app-context';
import { patientStyles } from '../../themes/patients-custom-theme';
import { CircularProgress } from '@mui/material';

export interface IRwPatientPageProps {
    title?: string;
    loading?: boolean;
    overrideElement?: React.ReactElement;
}

const RwPage: React.FC<IRwPatientPageProps> = (props) => {

    const { loading, color } = usePatientAppContext();

    const useStyles = makeStyles((theme) => ({

        bar: {
            width: '100%',
            height: '10px',
            backgroundColor: color,
        },
        overlay: {
            background: '#ffffff',
            position: 'fixed',
            height: '100%',
            width: '100%',
            zIndex: 5000,
            top: 0,
            left: 0,
            float: 'left',
            textAlign: 'center',
            paddingTop: '20%',
            opacity: 0.70
        },
        patientContainer: {
            width: '100%',
            height: '100%',
            backgroundColor: patientStyles.background,
            position: 'relative',
            overflowX: 'hidden'
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.patientContainer}>
            <div className={classes.bar}></div>
            {loading &&
                <div className={classes.overlay}>
                    <CircularProgress />
                </div>}
            {props.children}
        </div>
    );
}

export default RwPage