import React from 'react';
import { DesktopDatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import FormControl from '@mui/material/FormControl';
import IComponent from '../../../../services/forms.interface';
import myxss from './myxss';
import makeStyles from '@mui/styles/makeStyles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import enLocale from 'date-fns/locale/en-US';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
}));


const localeMap = {
    en: enLocale,
};

const maskMap = {
    fr: '__/__/____',
    en: '__/__/____',
    ru: '__.__.____',
    de: '__.__.____',
};

const DateAndTimePickers: React.FC<IComponent> = props => {
    const [locale] = React.useState<keyof typeof maskMap>('en');
    const classes = useStyles();

    const handleChange = (event) => {
        props.handleChange(event, props.id, event);
    };

    const showTimeOnly = props.showTimeSelectOnly;
    const showBoth = !showTimeOnly && props.showTimeSelect;
    const showDateOnly = !showTimeOnly && !showBoth;
    const isRequired = (props.hasOwnProperty('required') && props.required === true && !props.read_only);
    const labelText = myxss.process(props.label);

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }
    

    return (
        <div className={baseClasses}>
            <FormControl className={classes.formControl}>
                {showBoth &&
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                    <DateTimePicker label={labelText} mask={maskMap[locale]} value={props.value} onChange={handleChange} disabled={props.disabled} renderInput={(params) => <TextField {...params} sx={{ backgroundColor: '#F9F9F9' }} error={props.hasOwnProperty('error')} required={isRequired} variant="outlined" size="small" disabled={props.disabled} />}  />
                </LocalizationProvider>
                }
                {showDateOnly &&
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                    <DesktopDatePicker
                        minDate={moment('01/01/1900').toDate()}
                        maxDate={moment('01/01/2050').toDate()}
                        label={labelText} mask={maskMap[locale]} value={props.value} onChange={handleChange}
                        renderInput={(params) => <TextField {...params} sx={{ backgroundColor: '#F9F9F9' }} error={props.hasOwnProperty('error')} required={isRequired} variant="outlined" size="small" disabled={props.disabled}  />}
                    />
                </LocalizationProvider>
                }
                {showTimeOnly &&
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                    <TimePicker label={labelText} value={props.value} onChange={handleChange} disabled={props.disabled} renderInput={(params) => <TextField {...params} sx={{ backgroundColor: '#F9F9F9' }} required={isRequired} error={props.hasOwnProperty('error')} variant="outlined" size="small" disabled={props.disabled} />} />
                </LocalizationProvider>
                }
            </FormControl>
        </div>
    );
}

export default DateAndTimePickers;