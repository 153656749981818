import React from 'react';
import IComponent from '../../../../services/forms.interface';
import myxss from './myxss';
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles(() => ({
    subHeader: {
        color: '#1C3D64',
        marginBottom: '5px',
    },
    info: {
        padding: 2,
        fontSize: 16,
        marginTop: 'auto',
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.23)',
        '&:hover': {
            color: '#fff',
            backgroundColor: 'lightblue',
            borderRadius: '8px'
        }
    }
}));

const SubHeader: React.FC<IComponent> = props => {
    const classes = useStyles();

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }


    return (
        <div className={baseClasses}>
            <Typography variant={'h6'} className={classes.subHeader}>
                {myxss.process(props.content)}
                {props.editMode &&
                    <Tooltip title="Sub-Headers are used to organize your rows into visual groups and can be used with or without text. Rows below this sub-header will be grouped togeter until another sub-header or header is encountered.">
                        <InfoOutlinedIcon fontSize="small" color="secondary" className={classes.info} data-no-dnd="true" />
                    </Tooltip>
                }
            </Typography>

        </div>
    );
}

export default SubHeader;