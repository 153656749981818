import { useCallback } from 'react'
import moment from 'moment';
import { FormType } from '../api/web-api-client';

export interface IUSState {
    label: string,
    id: string
}
export interface IRwUtils {
    formatAge: (birthDate: Date) => number,
    usStates: IUSState[],
    formatContactDate: (date: string) => string,
    copyLink: (text: string) => Promise<boolean | void>,
    formTypeOptions: () => { value: string, text: string }[],
    allFormTypeOptions: () => { value: string, text: string }[]
}

export enum FormTypeReadable {
    Registration = "Registration",
    Consent = "Consent",
    Miscellaneous = "Miscellaneous",
    Uploaded = "Uploaded",
    PatientInformation = "Patient Information",
    InsuranceInformation = "Insurance Information",
    HealthHistory = "Health History",
}


const useRwUtils = ():IRwUtils => {
    
    const formatAge = useCallback((birthDate: Date): number => {
        return moment().diff(moment(birthDate, "DD-MM-YYYY"), 'years');
    }, []);

    const copyLink = useCallback(async (text: string): Promise<boolean | void> => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }, []);

    const formatContactDate = useCallback((date: string): string => {
        if (!date)
            return '';
        const splitDate = date.split("-");
        return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
    }, []);

    const formTypeOptions = useCallback((): { value: string, text: string }[] => {
        return Object.keys(FormType).filter(key => !isNaN(Number(FormType[key])) && FormType[key] !== FormType.Uploaded).map(key => ({ value: FormType[key], text: FormTypeReadable[key] }));
    }, []);

    const allFormTypeOptions = useCallback((): { value: string, text: string }[] => {
        return Object.keys(FormType).filter(key => !isNaN(Number(FormType[key]))).map(key => ({ value: FormType[key], text: FormTypeReadable[key] }));
    }, []);

    const usStates: IUSState[] =
        [
            {
                label: "Alabama",
                id: "AL"
            },
            {
                label: "Alaska",
                id: "AK"
            },
            {
                label: "American Samoa",
                id: "AS"
            },
            {
                label: "Arizona",
                id: "AZ"
            },
            {
                label: "Arkansas",
                id: "AR"
            },
            {
                label: "California",
                id: "CA"
            },
            {
                label: "Colorado",
                id: "CO"
            },
            {
                label: "Connecticut",
                id: "CT"
            },
            {
                label: "Delaware",
                id: "DE"
            },
            {
                label: "District Of Columbia",
                id: "DC"
            },
            {
                label: "Federated States Of Micronesia",
                id: "FM"
            },
            {
                label: "Florida",
                id: "FL"
            },
            {
                label: "Georgia",
                id: "GA"
            },
            {
                label: "Guam",
                id: "GU"
            },
            {
                label: "Hawaii",
                id: "HI"
            },
            {
                label: "Idaho",
                id: "ID"
            },
            {
                label: "Illinois",
                id: "IL"
            },
            {
                label: "Indiana",
                id: "IN"
            },
            {
                label: "Iowa",
                id: "IA"
            },
            {
                label: "Kansas",
                id: "KS"
            },
            {
                label: "Kentucky",
                id: "KY"
            },
            {
                label: "Louisiana",
                id: "LA"
            },
            {
                label: "Maine",
                id: "ME"
            },
            {
                label: "Marshall Islands",
                id: "MH"
            },
            {
                label: "Maryland",
                id: "MD"
            },
            {
                label: "Massachusetts",
                id: "MA"
            },
            {
                label: "Michigan",
                id: "MI"
            },
            {
                label: "Minnesota",
                id: "MN"
            },
            {
                label: "Mississippi",
                id: "MS"
            },
            {
                label: "Missouri",
                id: "MO"
            },
            {
                label: "Montana",
                id: "MT"
            },
            {
                label: "Nebraska",
                id: "NE"
            },
            {
                label: "Nevada",
                id: "NV"
            },
            {
                label: "New Hampshire",
                id: "NH"
            },
            {
                label: "New Jersey",
                id: "NJ"
            },
            {
                label: "New Mexico",
                id: "NM"
            },
            {
                label: "New York",
                id: "NY"
            },
            {
                label: "North Carolina",
                id: "NC"
            },
            {
                label: "North Dakota",
                id: "ND"
            },
            {
                label: "Northern Mariana Islands",
                id: "MP"
            },
            {
                label: "Ohio",
                id: "OH"
            },
            {
                label: "Oklahoma",
                id: "OK"
            },
            {
                label: "Oregon",
                id: "OR"
            },
            {
                label: "Palau",
                id: "PW"
            },
            {
                label: "Pennsylvania",
                id: "PA"
            },
            {
                label: "Puerto Rico",
                id: "PR"
            },
            {
                label: "Rhode Island",
                id: "RI"
            },
            {
                label: "South Carolina",
                id: "SC"
            },
            {
                label: "South Dakota",
                id: "SD"
            },
            {
                label: "Tennessee",
                id: "TN"
            },
            {
                label: "Texas",
                id: "TX"
            },
            {
                label: "Utah",
                id: "UT"
            },
            {
                label: "Vermont",
                id: "VT"
            },
            {
                label: "Virgin Islands",
                id: "VI"
            },
            {
                label: "Virginia",
                id: "VA"
            },
            {
                label: "Washington",
                id: "WA"
            },
            {
                label: "West Virginia",
                id: "WV"
            },
            {
                label: "Wisconsin",
                id: "WI"
            },
            {
                label: "Wyoming",
                id: "WY"
            }
        ];

    return {
        formatAge,
        usStates,
        formatContactDate,
        copyLink,
        formTypeOptions,
        allFormTypeOptions
    };

}

export default useRwUtils