import React, { useCallback, useContext, useReducer } from 'react';
import { IDocument, IDraft, IPage, PageMap, ISideBarItems } from './interfaces';
import { ILayoutRow } from '../../../services/forms.interface';
import { SIDEBAR_ITEMS } from './constants';
import { deleteChildren, handleRemoveItemFromLayout, updateItem } from './helpers';
import { FormFieldDto, IPmsSystemVm } from '../../../api/web-api-client';

export enum ActionKind {
    SET_BACKGROUND = 'setBackground',
    SET_USEEFFECTCOUNT = 'setUseEffectCount',
    SET_TAB = 'setTab',
    SET_TIMESTAMP = 'setTimeStamp',
    SET_EDITMODE = 'setEditMode',
    SET_EDITELEMENT = 'setEditElement',
    SET_AUTOSAVE = 'setAutoSave',
    SET_OPEN = 'setOpen',
    SET_DELETEDIALOGOPEN = 'setDeleteDialogOpen',
    SET_REDIRECT = 'setRedirect',
    SET_LAYOUT = 'setLayout',
    SET_DOCUMENT = 'setDocutment',
    SET_DOCUMENT_PAGE_INDEX = 'setDocutmentPageIndex',
    SET_COMPONENTS = 'setComponents',
    SET_ROW_COMPONENTS = 'setRowComponents',
    SET_RULES = 'setRules',
    SET_DATA = 'setData',
    SET_ERRORS = 'setErrors',
    SET_DRAGGING = 'setDragging',
    SET_SIDEBARITEMS = 'sideBarItems',
    SET_DRAFT = 'setDraft',
    SET_PMSSYSTEM = 'setPmsSystem',
    SET_IS_IN_BASE_ACCOUNT = 'setIsInBaseAccount',
    SET_PREVIEW = 'setPreview',
    SET_DISPLAY_LABELS = 'setDisplayLabels',
    SET_DIRTY = 'setDirty',
}

interface IState {
    background: string,
    useEffectCount: number;
    tab: number;
    timeStamp: any;
    draft: IDraft;
    editMode: boolean;
    editElement: any;
    autoSave: boolean;
    open: boolean;
    deleteDialogOpen: boolean;
    redirect: boolean;
    layout: ILayoutRow[];
    components: any;
    rules: [];
    errors: string;
    dragging: boolean;
    rowIndex: number;
    sideBarItems: ISideBarItems,
    pmsSystemVm: IPmsSystemVm,
    isInBaseAccount: boolean,
    showCorrectColumn: boolean,
    // forms/doc specific
    document: IDocument;
    documentPageIndex: number;
    preview: boolean;
    displayLabels: boolean;
    dirty: boolean;
    pdf: any;
    
}

export const initialState: IState = {
    background: '',
    useEffectCount: 0,
    tab: 0,
    draft: { hasDraft: false, id: null as string },
    timeStamp: null,
    editMode: false,
    editElement: {},
    autoSave: true,
    open: false,
    deleteDialogOpen: false,
    redirect: false,
    layout: [],
    components: {},
    rules: [],
    errors: "",
    dragging: false,
    rowIndex: -1,
    sideBarItems: SIDEBAR_ITEMS,
    pmsSystemVm: {},
    isInBaseAccount: false,
    showCorrectColumn: false,
    displayLabels: false,
    dirty: false,
    // forms/doc specific
    document: { pages: {}, pageCount: 0 },
    documentPageIndex: 0,
    preview: false,
    pdf: null
}

const reducer = (state: IState, action) => {
    switch (action.type) {
        case ActionKind.SET_BACKGROUND:
            return { ...state, background: action.payload }
        case ActionKind.SET_USEEFFECTCOUNT:
            return { ...state, useEffectCount: action.payload }
        case ActionKind.SET_TAB:
            return { ...state, tab: action.payload }
        case ActionKind.SET_TIMESTAMP:
            return { ...state, timeStamp: action.payload }
        case ActionKind.SET_EDITMODE:
            return { ...state, editMode: action.payload }
        case ActionKind.SET_EDITELEMENT:
            return { ...state, editElement: action.payload }
        case ActionKind.SET_AUTOSAVE:
            return { ...state, autoSave: action.payload }
        case ActionKind.SET_OPEN:
            return { ...state, open: action.payload }
        case ActionKind.SET_DISPLAY_LABELS:
            return { ...state, displayLabels: action.payload }
        case ActionKind.SET_DIRTY:
            return { ...state, dirty: action.payload }
        case ActionKind.SET_DELETEDIALOGOPEN:
            return { ...state, deleteDialogOpen: action.payload }
        case ActionKind.SET_REDIRECT:
            return { ...state, redirect: action.payload }
        case ActionKind.SET_LAYOUT:
            return { ...state, layout: action.payload }
        case ActionKind.SET_COMPONENTS:
            return { ...state, components: action.payload }
        case ActionKind.SET_RULES:
            return { ...state, rules: action.payload }
        case ActionKind.SET_DATA:
            return { ...state, ...action.payload }
        case ActionKind.SET_ERRORS:
            return { ...state, errors: action.payload }
        case ActionKind.SET_DRAGGING:
            return { ...state, dragging: action.payload }
        case ActionKind.SET_SIDEBARITEMS:
            return { ...state, sideBarItems: action.payload }
        case ActionKind.SET_DRAFT:
            return { ...state, draft: action.payload }
        case ActionKind.SET_PMSSYSTEM:
            return { ...state, pmsSystemVm: action.payload }
        case ActionKind.SET_IS_IN_BASE_ACCOUNT:
            return { ...state, isInBaseAccount: action.payload }
        // forms/doc specific
        case ActionKind.SET_DOCUMENT:
            return { ...state, document: action.payload }
        case ActionKind.SET_DOCUMENT_PAGE_INDEX:
            return { ...state, documentPageIndex: action.payload }
        case ActionKind.SET_PREVIEW:
            return { ...state, preview: action.payload }

        default:
            return state;
    }
}

const FormBuilderContext = React.createContext<{
    state: IState;
    dispatch: React.Dispatch<any>;
    updateComponent?: (id, property, value) => void;
    updateComponentFormField?: (id, formField: FormFieldDto, formFields: FormFieldDto[]) => void;
    addPage?: (pageKey: number, page: IPage) => void;
    addPages?: (page: PageMap, pageCount: number) => void;
    deleteItem?: (item) => void;
    editModeOn?: (item) => void;
    editModeOff?: (item) => void,
    saveExperimentData?: (storageId, newExperimentData) => void;
    deleteUnusedComponents?: () => void;
    createNoToAllRules?: () => void;
}>({
    state: initialState,
    dispatch: () => null,
    updateComponent: (id, property, value) => null,
    updateComponentFormField: (id, formField, formFields) => null,
    addPage: (pageKey: number, page: IPage) => null,
    addPages: (page: PageMap, pageCount: number) => null,
    deleteItem: (item) => null,
    editModeOn: (item) => null,
    editModeOff: (item) => null,
    saveExperimentData: (storageId, newExperimentData) => null,
    deleteUnusedComponents: () => null,
    createNoToAllRules: () => null
});

export const useFormBuilderContext = () => useContext(FormBuilderContext);

export const FormBuilderContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const editModeOn = useCallback((id): void => {
        dispatch({ type: ActionKind.SET_EDITELEMENT, payload: state.components[id] })
        dispatch({ type: ActionKind.SET_EDITMODE, payload: true })
    }, [state.components]);

    const editModeOff = useCallback((e): void => {
        if (state.editElement.content)
            updateComponent(state.editElement.id, 'content', state.editElement.content);

        dispatch({ type: ActionKind.SET_EDITMODE, payload: false });
        dispatch({ type: ActionKind.SET_EDITELEMENT, payload: {} })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addPage = useCallback((pageKey: number, page: IPage): void => {
        page.boxes = {};
        const doc = { ...state.document };
        doc.pageCount++;
        doc.pages[pageKey] = page;
        dispatch({ type: ActionKind.SET_DOCUMENT, payload: doc });
    }, [state.document]);

    const addPages = useCallback((pages: PageMap, pageCount: number): void => {
        const doc = { ...state.document };
        doc.pages = pages;
        doc.pageCount = pageCount;
        dispatch({ type: ActionKind.SET_DOCUMENT, payload: doc });
    }, [state.document]);

    const deleteUnusedComponents = useCallback(() => {
        const layoutString = JSON.stringify(state.layout);
        const items = state.components;
        for (const k in state.components) {
            if (layoutString.indexOf(k) === -1) {
                delete items[k];
            }
        }
        return items;
    }, [state.components, state.layout]);

    const createNoToAllRules = useCallback(() => {
        const rules = [];
        let noToAllButtonId = null;
        let switchInputIds = [];

        const processSwitchInputs = () => {
            if (noToAllButtonId && switchInputIds.length > 0) {
                rules.push(getRulePayload(noToAllButtonId, switchInputIds));
            }
            noToAllButtonId = null;
            switchInputIds = [];
        };
    
        state.layout.forEach((row) => {
            row.children[0]?.children.forEach((child) => {
                
                const id = child?.id;
                const component = state.components[id];

                if (component) {
                    if (component.type === 'Button' && component.label === 'No To All') {
                        processSwitchInputs();
                        noToAllButtonId = id;
                    } else if (['SubHeader', 'Header'].includes(state.components[child.id]?.type)) {
                        processSwitchInputs();
                    } else if (noToAllButtonId && component.type === 'SwitchInput') {
                        switchInputIds.push(id);
                    }
                }
            });
        });
    
        processSwitchInputs();

        dispatch({ type: ActionKind.SET_RULES, payload: rules });
    }, [state.components, state.layout]);
    
    const getRulePayload = (noToAllButtonId, switchInputIds) => ({
        name: noToAllButtonId,
        conditions: { any: [{ all: [{ fact: 'value', operator: 'equal', value: 'true' }] }] },
        event: { type: 'SetValue', params: { message: switchInputIds } },
    });    

    const updateTimestamp = useCallback((): void => {
        const time = new Date();
        const currentTime = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
        dispatch({ type: ActionKind.SET_TIMESTAMP, payload: currentTime });
    }, []);

    // This is the side effect we want to run on users' changes.
    const saveExperimentData = useCallback((storageId, newExperimentData) => {
        if (!state.autoSave)
            return;
        dispatch({ type: ActionKind.SET_DIRTY, payload: true });
        window.localStorage.setItem(storageId, JSON.stringify(newExperimentData));
        updateTimestamp();
    }, [state.autoSave, updateTimestamp]);

    const updateComponent = useCallback((id, property, value) => {

        const component = state.components[id];

        if (!component)
            return;

        component[property] = value;

        const newComponents = { ...state.components };

        updateItem(component, property, value);

        newComponents[component.id] = component;

        dispatch({ type: ActionKind.SET_EDITELEMENT, payload: component });
        dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });
        saveExperimentData(state.draft.id, { layout: state.layout, components: newComponents, rules: state.rules, pdf: state.pdf });
    }, [state.components, state.draft.id, state.layout, state.rules, saveExperimentData]);


    const updateComponentFormField = useCallback((id, formField: FormFieldDto, formFields: FormFieldDto[]) => {

        const component = state.components[id];
       
        if (!component)
            return;
        
        updateComponentFormFieldCommon(component, formField, formFields);
        
        const newComponents = { ...state.components };
        newComponents[component.id] = component;
        dispatch({ type: ActionKind.SET_EDITELEMENT, payload: component });
        dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });

        saveExperimentData(state.draft.id, { layout: state.layout, components: newComponents, rules: state.rules, pdf: state.pdf });
    }, [state.components, state.draft.id, state.layout, state.rules, saveExperimentData]);

    const updateComponentFormFieldCommon = (component, formField: FormFieldDto, formFields: FormFieldDto[]) => {
       
        if (formField) {

            component.name = formField.name;
            component.autocomplete = formField.autoComplete;

            if (formField.externalId) {
                component.externalId = formField.externalId;
                delete component.fieldId;
            } else {
                component.fieldId = formField.id;
                delete component.externalId;
            }

            updateItem(component, 'name', formField.name);

            if (component.childComponents) {
                Object.keys(component.childComponents).forEach(childKey => {
                    const childComponent = component.childComponents[childKey];
                    if (childComponent) {
                        const childField = formFields?.find(c => c.name === childComponent.name);
                        if (childField) {
                            childComponent.autocomplete = childField.autoComplete;
                            if (childField.externalId) {
                                childComponent.externalId = childField.externalId;
                                delete childComponent.fieldId;
                            } else {
                                childComponent.fieldId = childField.id;
                                delete childComponent.externalId;
                            }
                        }
                    }
                });
            }
        } else {

            delete component.name;
            delete component.fieldId;
            delete component.externalId;
            delete component.autocomplete;

            if (component.childComponents) {
                Object.keys(component.childComponents).forEach(childKey => {
                    const childComponent = component.childComponents[childKey];
                    if (childComponent) {
                        delete childComponent.fieldId;
                        delete childComponent.externalId;
                        delete childComponent.autocomplete;
                    }
                });
            }
        }


        return component;
    }

    const deleteItem = useCallback((item): void => {
        if (!item.path)
            return;

        const splitPath = item.path.split("-");

        // form builder
        if (state.layout && state.layout.length > 0) {
            const newLayout = handleRemoveItemFromLayout(state.layout, splitPath);

            dispatch({
                type: ActionKind.SET_DATA, payload: { layout: newLayout, components: deleteChildren(item, state.components) }
            });
        }

        // document builder
        if (state.document && state.document.pageCount > 0) {
            const newDocument = { ...state.document, pages: { ...state.document.pages, boxes: { ...state.document.pages[state.documentPageIndex].boxes } } };
            delete newDocument.pages[state.documentPageIndex].boxes[item.id];
            dispatch({ type: ActionKind.SET_DOCUMENT, payload: newDocument });

            const newComponents = { ...state.components };
            delete newComponents[item.id];
            dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });
        }
    }, [state.components, state.document, state.layout, state.documentPageIndex]);

   

    return (
        <FormBuilderContext.Provider value={{
            state,
            dispatch,
            addPage,
            addPages,
            updateComponent,
            updateComponentFormField,
            deleteItem,
            editModeOn,
            editModeOff,
            saveExperimentData,
            deleteUnusedComponents,
            createNoToAllRules
        }}>
            {children}
        </FormBuilderContext.Provider>
    );
};


