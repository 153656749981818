import React from "react";
import Column from "./column";
import makeStyles from '@mui/styles/makeStyles';
import classNames from "classnames";
import IComponent from '../../../services/forms.interface';
import Component from "./component";
import { IPmsSystemVm } from "../../../api/web-api-client";
import { ALLERGIESPMS, PROBLEMSPMS } from "../shared/constants";

interface RowProps {
    row: any;
    components: Map<string, IComponent>;
    path: string;
    handleChange: (event, id, value) => void;
    readOnly?: boolean;
    nextRow: any;
    prevRow: any;
    index: number;
    last: boolean;
    isMobile: boolean;
    googleMapApiKey: string;
    isUploadedForm?: boolean;
    isEdit?: boolean;
    displayLabel?: boolean;
    pmsSystemVm?: IPmsSystemVm;
}

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
        borderRight: '1px solid rgba(0, 0, 0, 0.23)',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        '&.isUploadedForm': {
            backgroundColor: theme.custom.formRowBackgroundColor,
            '& > div': {
                borderTop: 0,
                paddingTop: '5px',
                paddingBottom: '5px',
            }
        },
        '&.isEdit': {
            flexBasis: '100%',
            '&:hover': {
                borderLeft: "1px solid #0675DD",
                borderRight: "1px solid #0675DD",
                background: '#E1F0FD',
            }
        },
        '& > div': {
            display: 'flex',
            borderTop: '1px solid #E4E5E8',
            paddingTop: '10px',
            paddingBottom: '10px',
            width: '95%',
            height: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
            columnGap: '15%',
        },
        '&.Header': {
            marginTop: '30px',
            paddingLeft: '0px',
            backgroundColor: 'inherit',
            border: '0px',
            boxShadow: 'none',
            '& + .SubHeader': {
            },
            '& > div': {
                borderTop: 0,
                width: '100%',
            },
            '& .column': {
                borderTop: '0px',
            },
            '&:not(.firstRow)': {
            },

        },
        '&.prev-Header': {
            borderRadius: "7px 7px 0px 0px",
            borderTop: '1px solid rgba(0, 0, 0, 0.23)',
            paddingTop: '5px',
            '& > div': {
                borderTop: 0,
            },
        },
        '&.prev-SubHeader': {
            '&:not(.subHeaderHasText)': {
                '& > div': {
                    paddingTop: '0px',
                },
            },
            '& > div': {
                borderTop: 0,
            },
            paddingTop: '10px'
        },
        '&.next-Header': {
            borderRadius: "0px 0px 7px 7px",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            marginBottom: '10px'
        },
        '&.next-AllergiesPms': {
            borderBottomLeftRadius: "7px",
            borderBottomRightRadius: "7px",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            marginBottom: '10px',
        },
        '&.prev-AllergiesPms': {
            borderTopLeftRadius: "7px !important",
            borderTopRightRadius: "7px !important",
            borderTop: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '&.next-ProblemsPms': {
            borderBottomLeftRadius: "7px",
            borderBottomRightRadius: "7px",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            marginBottom: '10px',
        },
        '&.prev-ProblemsPms': {
            borderTopLeftRadius: "7px !important",
            borderTopRightRadius: "7px !important",
            borderTop: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '&:not(.Header).next-SubHeader': {
            borderRadius: "0px 0px 7px 7px",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '&.SubHeader': {
            borderRadius: "7px 7px 0px 0px",
            marginTop: '5px',
            paddingTop: '5px',
            borderTop: '1px solid rgba(0, 0, 0, 0.23)',
            '& > div': {
                borderTop: 0,
            },
            '&.subHeaderHasText': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            }
        },
        '&.Paragraph > .column': {
            flex: '1 0 90%'
        },
        '&.prev-Paragraph': {
            '& > div': {
                borderTop: 0,
            },
        },
        '&:not(.Header).lastRow': {
            borderRadius: "0px 0px 7px 7px",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            paddingBottom: '15px',
            marginBottom: '5px',
        },
        '&:not(.Header).firstRow': {
            borderRadius: "7px 7px 0px 0px",
            borderTop: '1px solid rgba(0, 0, 0, 0.23)',
            
            '& > div': {
                border: '0px'
            }
        },
        '&.prev-Header.lastRow': {
            borderRadius: "7px 7px 7px 7px",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            paddingBottom: '15px'
        },
        '&.prev-Header.next-Header': {
            borderRadius: "7px 7px 7px 7px",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '&.firstRow': {
            marginTop: '0px',
        },
        '&.notVisible': {
            display: 'none',

        },
        '&:has(+.notVisible)': {
            borderRadius: "7px 7px 7px 7px",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
        }
    }
}));


const Row: React.FC<RowProps> = props => {
    const classes = useStyles();

    const GetRowComponent = (): any => {
        let value: any = {};
        props.row?.children.forEach((col) => {
            if (col.children[0])
                value = props.components[col.children[0].id];
        });
        
        return value;
    }

    const rowComponent = GetRowComponent();

    const nextRowType = (): any => {
        let value: any = {};

        if (!props.nextRow)
            return value;

        if (props.nextRow) {
            props.nextRow.children?.forEach((col) => {
                if (col.children[0])
                    value = 'next-' + props.components[col.children[0]?.id]?.type;
            });
        }
        

        return value;
    }

    const prevRowType = (): any => {
        let value: any = {};

        if (!props.prevRow)
            return value;

        if (props.prevRow) {
            props.prevRow.children?.forEach((col) => {
                if (col.children[0])
                    value = 'prev-' + props.components[col.children[0].id]?.type;
            });
        }

        return value;
    }

    const subHeaderHasText = rowComponent?.type === 'SubHeader' && rowComponent?.content !== '';

    if (props.row.visible === false) {
        const nextType = nextRowType();
        if (nextType === 'next-SubHeader') {
            return <div className={classNames(classes.row, "row", "notVisible", nextType)}></div>;
        } else {
            return <></>;
        }
    }

    if (!rowComponent)
        return <></>;

    if (rowComponent.type === ALLERGIESPMS || rowComponent.type === PROBLEMSPMS)
        return <Component
            key={rowComponent.id}
            component={rowComponent}
            components={props.components}
            path={''}
            handleChange={props.handleChange}
            readOnly={props.readOnly}
            parentType={rowComponent.parentType}
            peerCount={0}
            index={0}
            firstComponentId={rowComponent.id}
            isMobile={props.isMobile}
            googleMapApiKey={props.googleMapApiKey}
            displayLabel={props.displayLabel}
            pmsSystemVm={props.pmsSystemVm}
        />;

    return (
        <>
            <div id={props.row.id} className={classNames(classes.row, "row", rowComponent?.type, prevRowType(), nextRowType(), { firstRow: props.index === 0, lastRow: props.last, subHeaderHasText: subHeaderHasText, isUploadedForm: props.isUploadedForm, isEdit: props.isEdit })}>

                {!props.isEdit && (rowComponent.type !== 'SubHeader' || subHeaderHasText) &&
                <div>
                    {props.row.children.map((column, index) => {
                        const currentPath = `${props.path}-${index}`;
                        return (
                            <Column
                                key={column.id}
                                column={column}
                                components={props.components}
                                path={currentPath}
                                handleChange={props.handleChange}
                                readOnly={props.readOnly}
                                parentType={props.row.type}
                                isMobile={props.isMobile}
                                googleMapApiKey={props.googleMapApiKey}
                                displayLabel={props.displayLabel}
                                pmsSystemVm={props.pmsSystemVm}
                            />
                        );
                    })}
                    </div>
                }
            </div>
        </>
    );

};
export default Row;
