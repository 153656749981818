import React from "react";
import Component from "./component";
import makeStyles from '@mui/styles/makeStyles';
import classNames from "classnames";
import IComponent from '../../../services/forms.interface';
import { IPmsSystemVm } from "../../../api/web-api-client";

interface ColumnProps {
    column: any;
    components: Map<string, IComponent>;
    path: string;
    handleChange: (event, id, value) => void;
    readOnly?: boolean;
    parentType: any;
    isMobile: boolean;
    googleMapApiKey: string;
    displayLabel?: boolean;
    pmsSystemVm?: IPmsSystemVm;
}

const useStyles = makeStyles(() => ({
    column: {
        display: "flex",
        flex: '1',
        flexWrap: "wrap",
        flexBasis: '100%',
        justifyContent: 'space-between',
    }
}));


const Column: React.FC<ColumnProps> = props => {
    const classes = useStyles();

return (
  
            <div className={classNames(classes.column, "column")}>
                {props.column.children.map((component, index) => {

                    const currentPath = `${props.path}-${index}`;

                    return (
                        <Component
                            key={component.id}
                            component={component}
                            components={props.components}
                            path={currentPath}
                            handleChange={props.handleChange}
                            readOnly={props.readOnly}
                            parentType={props.parentType}
                            peerCount={props.column.children.length}
                            index={index}
                            firstComponentId={props.column.children[0].id}
                            isMobile={props.isMobile}
                            googleMapApiKey={props.googleMapApiKey}
                            displayLabel={props.displayLabel}
                            pmsSystemVm={props.pmsSystemVm}
                        />
                    );
                })}

            </div>

);
};

export default Column;
