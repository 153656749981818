import { AccordionDetails, Box, Divider, Skeleton } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import RwAccordion from '../../shared/rw-accordion';
import RwAccordionRow from '../../shared/rw-accordion-row';
import RwLocationsPanel from '../../shared/rw-locations-panel';
import RwPage from '../../shared/RwPage';

export const FormsSettingsSkeleton: React.FC = () => {
    return (
        <>
            <RwPage loading={true} buttons={[{ text: 'EDIT FORM Settings', action: '' }, { text: 'EDIT FORM', action: '' }]} title='form settings'>
                <Box m={2} pr={12} pl={12}>
                    <form>
                        <RwAccordion title="Details" name="Details" error={null} defaultExpanded={true} showSkeleton={true}>
                            <RwAccordionRow variant="input" label="Form Name" showSkeleton={true}></RwAccordionRow>
                            <Divider />
                            <RwAccordionRow variant="input" label="Form Type" showSkeleton={true}></RwAccordionRow>
                            <Divider />
                            <RwAccordionRow variant="input" label="Form Specialty" showSkeleton={true}></RwAccordionRow>
                            <Divider />
                            <RwAccordionRow variant="input" label="Display on Public Page" showSkeleton={true}></RwAccordionRow>
                            <Divider />
                            <RwAccordionRow variant="input" label="Last Modified" showSkeleton={true}></RwAccordionRow>
                            <Divider />
                        </RwAccordion> {/*settings panel*/}

                        <RwLocationsPanel formMode={0} allLocations={null} tooltip='' formData={null} showSkeleton={true} defaultExpanded={true} showAllLocationsOnNoSelection={false}/>

                        <RwAccordion title="Auto Sync" name="Auto Sync" showSkeleton={true} defaultExpanded={true} error={null}>
                            <RwAccordionRow variant="input" label='' showSkeleton={true} fullRowLabel={true} />
                            <RwAccordionRow variant="input" label="Auto Sync" showSkeleton={true} toolTip=''></RwAccordionRow>
                        </RwAccordion> {/*Autosync panel*/}

                        <RwAccordion title='' name="preview" error={false} showSkeleton={true}>
                            <AccordionDetails>
                                <Skeleton variant="rectangular" width={'100%'} height={200} style={{ marginTop: 10 }} />
                            </AccordionDetails>
                        </RwAccordion> {/*preview panel*/}
                    </form>
                </Box>
            </RwPage >
        </>
    );
};


const SettingsForm = lazy(()  => import(/* webpackChunkName: "forms-settings" */ '../settings'));

const LazySettingsForm: React.FC = () => {
    return (
        <Suspense fallback={<FormsSettingsSkeleton />}>
            <SettingsForm />
        </Suspense>
    );
};

export const SettingsFormModule =
{
    routeProps: {
        path: '/forms/settings/:id?/:packetId?',
        component: LazySettingsForm,
        private: true
    },
    name: 'Form Settings',
};