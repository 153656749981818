import { Grid, Select, Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { lazy, Suspense } from 'react';
import RwDataGrid from '../../shared/RwDataGrid';
import RwPage from '../../shared/RwPage';

export const useSharedFormsStyles = makeStyles((theme) => ({
    vertSpace: {
        marginRight: 90,
        marginLeft: 90,
    },
    filterDropdown: {
        marginRight: 10,
        marginBottom: 20,

    },
    filterSearch: {
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end'
        }
    }
}));

const columns = [
    { field: 'col1', flex: 1 },
    { field: 'col2', flex: 1 },
    { field: 'col3', flex: 1 },
    { field: 'col4', flex: 0.75 },
    { field: 'col5', flex: 1 },
    { field: 'col6', flex: 1.75 }
];

export const FormsSkeleton: React.FC = () => {
    const classes = useSharedFormsStyles();

    return (
        <>
            <RwPage title="Form Library" showLocationFilter={true} loading={true} buttons={[{ text: '', action: '' }]}>
                <div className={classes.vertSpace}>
                    <Grid container>
                        <Grid item container xs={12} sm={6}>
                            <Grid item className={classes.filterDropdown}>
                                <Skeleton><Select /></Skeleton>
                            </Grid>
                            <Grid item className={classes.filterDropdown}>
                                <Skeleton><Select /></Skeleton>
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.filterSearch} xs={12} sm={6}>
                            <Grid item style={{ height: 56 }}>
                                <Skeleton variant="rectangular" width={200} />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <RwDataGrid rows={[]} columns={columns} pageSize={10} pagination loadingSkeleton={true} rowCount={10} paginationMode="server" autoHeight={true} />
            </RwPage>
        </>
    );
};

const Forms = lazy(() => import(/* webpackChunkName: "forms-index" */ '../index'));

const LazyForms: React.FC = () => {
    return (
        <Suspense fallback={<FormsSkeleton />}>
            <Forms />
        </Suspense>
    );
};

export const FormsModule =
{
    routeProps: {
        path: '/forms/index',
        component: LazyForms,
        private: true
    },
    name: 'List Forms',
};