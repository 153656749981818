import { Box, Grid, Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { lazy, Suspense } from 'react';
import RwPatientPage from '../shared/RwPatientPage';

export const useSharedPatientStyles = makeStyles((theme) => ({
    patientAssignmentsContainer: {
        margin: 0,
        marginBottom: '20px'
    },
    bottomlocationIcon: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        justifyContent: 'center',

    },
    bottomLocationName: {
        fontWeight: '600 !important'
    },
    locationName: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            alignItems: 'center'
        }

    },
    phoneViewLocationName: {
        display: 'flex',
        justifyContent: 'center',
    },
    dataGridContainer: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '7px',
        backgroundColor: '#FFF',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '25px',
            paddingRight: '25px'
        },
    },
    patientAssignmentsGridTop: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.only('sm')]: {
            justifyContent: 'space-between',
            paddingRight: '16px'
        },
        [theme.breakpoints.only('xs')]: {
            paddingRight: '32px'
        },
    },
    patientAssignments: {
        display: 'flex',
        justifyContent: 'center'
    },
}));


export const PatientSkeleton: React.FC<any> = (props) => {
    const classes = useSharedPatientStyles();

    return (
        <RwPatientPage title="Forms" loading={false}>
            <Grid container spacing={2} className={classes.patientAssignmentsContainer}>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ paddingTop: '40px' }} className={classes.patientAssignments}>
                        <Grid container item className={classes.patientAssignmentsGridTop}>
                            <Grid item xs={5}>
                                <Skeleton variant="rectangular" height={50} width={'40%'} />
                                <Skeleton variant="text" width={'80%'} />
                            </Grid>
                            <Grid item xs={3} className={classes.locationName}>
                                <Skeleton variant="text" width={'60%'} />
                            </Grid>
                        </Grid>

                        <Grid item xs={8}>
                            <Grid item xs={12}>
                                <Box px={7} py={3} className={classes.dataGridContainer}>
                                    <Grid container item mb={3}>
                                        <Skeleton variant="rectangular" height={35} width={300} />
                                    </Grid>
                                    {[...Array(4)].map((row, i) => {
                                        return (
                                            <Grid container item justifyContent="space-between" key={i} style={{ paddingBottom: 25 }}>
                                                <Grid item xs={5}>
                                                    <Skeleton variant="text" width={'80%'} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Skeleton variant="rectangular" height={25} width={'100%'} />
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} className={classes.bottomlocationIcon}>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container direction="column" className={classes.bottomlocationIcon}>
                                <Skeleton variant="text" width={'50%'} />
                                <Skeleton variant="text" width={'40%'} />
                                <Skeleton variant="text" width={'20%'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </RwPatientPage>
    );
}

const Patient = lazy(() => import(/* webpackChunkName: "patients-index" */ './index'));

const LazyPatient: React.FC = () => {
    return (
        <Suspense fallback={<PatientSkeleton />}>
            <Patient />
        </Suspense>
    );
};

export const PatientModule =
{
    routeProps: {
        path: '/patients/index',
        component: LazyPatient,
        private: true
    },
    name: 'Patient',
};