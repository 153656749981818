/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { FormFieldDto } from '../../../api/web-api-client';
import IComponent from '../../../services/forms.interface';


export interface FieldNamesProps {
    onChange: (value: any, event: any) => void,
    fields: FormFieldDto[],
    component: IComponent, 
    components: Map<string, IComponent>,
    elementType: any,
    usedFieldNames?: any
}

export default function FieldNames(props: FieldNamesProps) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState<FormFieldDto | null>(null);


    useEffect(() => {

        if (props.fields) {

            // filter out tags that are assigned to other fields in the current form
            // don't filter out the tag assigned to this field if one is assigned.
            //const availFields = { ...props.fields };

            let cloned = JSON.parse(JSON.stringify(props.fields));

            if (props.components) {
                const components: IComponent[] = Object.keys(props.components).map(key => props.components[key]);
                const componentNames = components.map(a => a.name).filter(name => name && (name !== props.component.name && name !== ''));

                // filter out fields if an component has pms fields set.
                components.filter(a => (a.element === 'Address' || a.element === 'Medications' || a.element === 'Problems' || a.element === 'Allergies') && a.name && (a.name !== props.component.name && a.name !== '')).forEach(a => {
                    if (a.childComponents) {
                        const childComps = Object.keys(a.childComponents).map(key => a.childComponents[key]);
                        childComps.forEach(b => {
                            componentNames.push(b.name);
                        });
                    }
                });

                cloned = cloned.filter((field) => !componentNames.some(name => name === field.name && field.name !== ''));
            }
 
            
            let newOptions: FormFieldDto[] = [];
            let newValue = props.component.name || null;
            let newSelectedOption: FormFieldDto = null;
            setSelectedOption(newSelectedOption);

            switch (props.elementType) {

                case 'Medications': {
                    
                    cloned = cloned.filter((f) => f.name.trim().indexOf('health_history_medication1_name') !== -1);

                    newOptions = cloned.map((option) => {
                        option.name = option.name
                        return {
                            ...option,
                        };
                    });

                    if (newValue)
                        newSelectedOption = newOptions.find(e => e.name === newValue);


                    break;
                }
                case 'Allergies': {

                    cloned = cloned.filter((f) => f.name.trim().indexOf('health_history_allergies_1_name') !== -1);

                    newOptions = cloned.map((option) => {
                        option.name = option.name
                        return {
                            ...option,
                        };
                    });
                    
                    if (newValue)
                        newSelectedOption = newOptions.find(e => e.name === newValue)

                    break;
                }
                case 'Problems': {

                    cloned = cloned.filter((f) => f.name.trim().indexOf('health_history_problem_1_name') !== -1);

                    newOptions = cloned.map((option) => {
                        option.name = option.name
                        return {
                            ...option,
                        };
                    });

                    if (newValue)
                        newSelectedOption = newOptions.find(e => e.name === newValue)

                    

                    break;
                }
                case 'Address': {

                    cloned = cloned.filter((f) => f.name.trim().indexOf('street') !== -1);

                    newOptions = cloned.map((option) => {
                        option.name = option.name.replaceAll('_', ' ');
                        option.name = option.name.replaceAll('street', '');
                        return {
                            ...option,
                        };
                    });

                    if (newValue && newValue !== 'Address') {
                        newValue = newValue.replaceAll('_', ' ');
                        newValue = newValue.replaceAll('street', '');
                        newSelectedOption = newOptions.find(e => e.name === newValue)
                    }

                    break;
                }
                default: {

                    let elementType = props.elementType;
       
                    if (elementType === 'RadioButtons' || elementType === 'Dropdown')
                        elementType = 'SingleSelectInput';

                    if (elementType === 'Checkbox')
                        elementType = 'MultiSelect';

                    const medicationRegex = new RegExp('^health_history_medication.*_name.*$', 'i');
                    const dosageRegex = new RegExp('^health_history_dosage.*_name.*$', 'i');
                    const frequencyRegex = new RegExp('^health_history_frequency.*_name.*$', 'i');
                    const allergyRegex = new RegExp('^health_history_allergies_.*_name.*$', 'i');
                    const problemRegex = new RegExp('^health_history_problem_.*_name.*$', 'i');
          
                    cloned = cloned.filter((f) =>
                        (
                        f.name.trim() === 'health_history_medication_any'
                        ||
                            (
                                !medicationRegex.test(f.name.trim())
                                && !dosageRegex.test(f.name.trim())
                                && !frequencyRegex.test(f.name.trim())
                                && !allergyRegex.test(f.name.trim())
                                && !problemRegex.test(f.name.trim())
                            )
                        )
                    );

                    if (elementType === 'TextInput') {
                        cloned = cloned.filter((f) => f.htmlType == elementType || f.htmlType == 'NumberInput');
                    } else {
                        cloned = cloned.filter((f) => f.htmlType == elementType);
                    }
                        

                    newOptions = cloned.map((option) => {
                        option.name = option.name.replaceAll('_', ' ');
                        return {
                            ...option,
                        };
                    });

                    if (newValue) {
                        newValue = newValue.replaceAll('_', ' ');
                        newSelectedOption = newOptions.find(e => e.name === newValue)
                    }
                   
                }
            }
            
            if (newSelectedOption && Object.keys(newSelectedOption).length === 0) {
                newSelectedOption = newOptions.find(e => e.id === 0);
            }

            setSelectedOption(newSelectedOption);
            setOptions(newOptions);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fields, props.component.name, props.usedFieldNames, props.elementType]);

    function handleChange(event, option: FormFieldDto) {

        if (option) {
            switch (props.elementType) {
                case 'Address': {
                    option.name = option ? (option.name + 'street').replaceAll(' ', '_') : null;
                    break;
                }
                default: {
                    option.name = option?.name.replaceAll(' ', '_')
                }
            }
        }
        
        props.onChange(option, event);
    }

    const getOptionLabel = (option) => {
        const label = option.display || option.name;
        return (label !== undefined)?label:'';
    }

    if (!options || options.length === 0)
        return <p>No pms fields available for {props.elementType} question types</p>;

    return (
        <Autocomplete
            id="fieldTag"
            options={options}
            getOptionLabel={(option) => getOptionLabel(option)}
            onChange={handleChange}
            value={selectedOption}
            style={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label='select...' variant="outlined" />}
                />);
}