import { Grid, Paper, Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { lazy, Suspense } from 'react';
import RwPage from '../shared/RwPage';

export const useSharedSubmissionsStyles = makeStyles((theme) => ({
    skeletonSubmissions: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        paddingTop: 10
    },
    submissionListSection: {
        width: '100%',
        height: '100%',
        maxWidth: '350px',
        overflow: 'hidden',
    }
}));

export const SubmissionsSkeleton: React.FC = () => {
    const classes = useSharedSubmissionsStyles();

    return (
        <>
            <Paper className={classes.submissionListSection} elevation={3}>
                {[...Array(30)].map((row, i) => {
                    return (
                        <Grid container key={i} direction="row" justifyContent="space-between" alignItems="center" className={classes.skeletonSubmissions}>
                            <div>
                                <Skeleton variant="text" width={80} />
                                <Skeleton variant="text" width={160} />
                            </div>
                            <Skeleton variant="text" width={70} />
                        </Grid>
                    );
                })}
            </Paper>
            <RwPage title="Submissions" showLocationFilter={true} loading={true}>
            </RwPage>
        </>
    );
};


const Submission = lazy(() => import(/* webpackChunkName: "submissions-index" */ './index'));

const LazySubmissions: React.FC = () => {
    return (
        <Suspense fallback={<SubmissionsSkeleton />}>
            <Submission />
        </Suspense>
    );
};

export const SubmissionsModule =
{
    routeProps: {
        path: '/submissions/index',
        component: LazySubmissions,
        private: true
    },
    name: 'Submission',
};