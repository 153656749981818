import { useEffect, useState } from 'react';
import { AxiosInstance } from 'axios';
import { httpService } from '../services/httpService';
import { useHistory } from "react-router-dom";
import { Mode } from '../api/web-api-client';

const useAuthenticatedPatientRequest = (rwLocationId: number, mode: Mode): AxiosInstance => {
    const instance = httpService.GetInstance();
    const [interceptor, setInterceptor] = useState<any>(null);
    const history = useHistory();

    useEffect(() => {
        if (interceptor) {
            instance.interceptors.response.eject(interceptor);
        }
        const int = instance.interceptors.response.use(response => response,
            error => {
                const { status } = error.response;
                if (status === 401 || status === 403) {
                    if (mode === Mode.SignedInAs) {
                        history.push(`/`);
                    } else {
                        history.push(`/patients/login/${rwLocationId}/true`);
                    }
                }
                return Promise.reject(error);
            });
        setInterceptor(int);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rwLocationId]);

    return instance;
};

export default useAuthenticatedPatientRequest;