import React from 'react';
import IComponent from '../../../../services/forms.interface';
import Button from '@mui/material/Button';

interface IButtonProps extends Omit<IComponent, 'defaultValue'> {
    defaultValue: string | number;
}

const Dropdown: React.FC<IButtonProps> = props => {

    const handleChange = (event) => {
        props.handleChange(event, props.id, 'true');
    };

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }

    return (
        <div className={baseClasses}>
            <Button onClick={handleChange} variant="outlined">{props.label}</Button>
        </div>
    );
}

export default Dropdown;