import React, { useCallback } from 'react';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

interface ConfirmationDialogProps {
    text: string,
    title: string,
    open: boolean,
    onClose: (val: boolean) => void,
    onSave?: (e: any) => Promise<void>,
    deleteMode?: boolean,
    deletebuttontext?: string,
    hideCancelButton?: boolean,
    okbuttontext?: string,
    cancelbuttontext?: string
}



const useStyles = makeStyles((theme) => ({
    deleteButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    },
    okButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.warning.main
    },
    cancelButton: {
        color: theme.custom.secondaryButtonColor, 
        backgroundColor: theme.custom.secondaryButtonColorBackground,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.dark
        }
    },
    saveButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
}));

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
    const classes = useStyles();
    const { onClose, onSave, open, title, text, deleteMode, hideCancelButton = false,  ...other } = props;

    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true);
    };

    const handleSave = async () => {
        await onSave(null);
        onClose(true);
     }

    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!hideCancelButton  &&
                    < Button variant="contained" autoFocus onClick={handleCancel} className={classes.cancelButton}>
                        {props.cancelbuttontext ? props.cancelbuttontext : 'Cancel'}
                    </Button>
                }
                <Button variant="contained" onClick={handleOk} classes={{ root: deleteMode ? classes.deleteButton : classes.okButton }}>
                    {deleteMode ? (props.deletebuttontext ? props.deletebuttontext : 'Delete') : props.okbuttontext ? props.okbuttontext : 'Ok'}
                </Button>
                {onSave &&
                    <Button variant="contained" onClick={handleSave} className={classes.saveButton}>
                        Save Changes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default ConfirmationDialog;