import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LocationDto, LocationsClient, Mode, OidcConfigurationClient, PatientDto } from '../api/web-api-client';
import useAuthenticatedPatientRequest from '../hooks/use-authenticated-patient-request';
import useRwSWR from '../hooks/use-rw-swr';
import clientService from '../services/clientService';
import Keys from '../services/keys';
import { getPatientPortalColor } from '../themes/patients-custom-theme';

interface IPatientAppContext {
    loading: boolean;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
    ShowError?: (message: string, duration?: number) => void,
    ShowWarning?: (message: string, duration?: number) => void,
    ShowSuccess?: (message: string, duration?: number) => void;
    test: boolean;
    location: LocationDto,
    setRwLocationId?: React.Dispatch<React.SetStateAction<number>>,
    color: string,
    selectedPatient: PatientDto,
    setSelectedPatient?: React.Dispatch<React.SetStateAction<PatientDto>>,
    mode: Mode,
    setMode?: React.Dispatch<React.SetStateAction<Mode>>,
    unwrapperedDomain: string;
    allFormsFilledOut: boolean;
    setAllFormsFilledOut?: React.Dispatch<React.SetStateAction<boolean>>
}

export const defaultState = {
    authenticated: true,
    loading: false,
    test: false,
    location: null,
    color: getPatientPortalColor('blue'),
    selectedPatient: null as PatientDto,
    accountLocationName: '',
    mode: Mode.None,
    unwrapperedDomain: null,
    allFormsFilledOut: false
};

const PatientAppContext = React.createContext<IPatientAppContext>(defaultState);

export const usePatientAppContext = () => useContext(PatientAppContext);

export const PatientAppContextProvider: React.FC = ({ children }) => {
    const [selectedPatient, setSelectedPatient] = useState(defaultState.selectedPatient);
    const [loading, setLoading] = useState(defaultState.loading);
    const [color] = useState(defaultState.color);
    const [mode, setMode] = useState(defaultState.mode);
    const [test, setTest] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const [rwLocationId, setRwLocationId] = useState(null as number);
    const [unwrapperedDomain, setUnwrapperedDomain] = useState(null as string);
    const [allFormsFilledOut, setAllFormsFilledOut] = useState(defaultState.allFormsFilledOut);

    const { data: location } = useRwSWR([Keys.ApiLocation, rwLocationId], (url: string, lId: number) => fetcher(lId));
    useAuthenticatedPatientRequest(location?.rwLocationId, mode); // globally configuring axios with this hook here.

    const ShowError = useCallback((message: string, duration?: number): void => {
        console.log(message);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const ShowWarning = useCallback((message: string, duration?: number): void => {
        console.log(message);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const ShowSuccess = useCallback((message: string, duration?: number): void => {
        console.log(message);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetcher = async (rwLocationId: number) => {
        try {
            const client = clientService.getFormsClient(LocationsClient, { apiPath: unwrapperedDomain });
            return await client.get(rwLocationId);
        } catch (e) {
            ShowError('Problem occurred while loading location.');
        }
    }

    const setDomain = async () => {
        try {
            const client = clientService.getFormsClient(OidcConfigurationClient, { apiPath: unwrapperedDomain });
            const domain = await client.getUnwrapperedDomain(null);
            setUnwrapperedDomain(domain);
        } catch (e) {
            ShowError('Problem occurred while loading domain.');
        }
    }

    useEffect(() => {
        if (queryParams.get('test') == 'true') setTest(true);
        setDomain();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PatientAppContext.Provider
            value={{
                loading,
                setLoading,
                ShowError,
                ShowWarning,
                ShowSuccess,
                test,
                location,
                setRwLocationId,
                color,
                selectedPatient,
                setSelectedPatient,
                mode,
                setMode,
                unwrapperedDomain,
                allFormsFilledOut,
                setAllFormsFilledOut
            }}
        >
            {children}
        </PatientAppContext.Provider>
    );
};
