import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Fade } from '@mui/material';
import RwAlert from './RwAlert';

export interface IRwValidationErrors {
    errors: Record<string, string[]>;
    handleclose?: ((keyIndex?: number, valueIndex?: number) => void) | (() => void);
    fadeout?: boolean;
}

const RwValidationErrors: React.FC<IRwValidationErrors> = (props) => {
    const [open, setOpen] = useState(true);

    const handleClose = (keyIndex: number, valueIndex: number) => {
        props.handleclose(keyIndex, valueIndex);
    }

    useEffect(() => {
        setOpen(true);
        const id = setTimeout(() => {
            setOpen(false);
        }, 5000);
        return () => { clearTimeout(id) }
    }, [props.errors]);

    if ((!props.errors || !Object.keys(props.errors).length) && !props.fadeout)
        return <></>;

    return (        
            <Box sx={{ width: '100%' }}>
                {Object.values(props.errors).map((errorArray, index) => {
                    return errorArray.map((err, i) => {
                        return (
                            <Fade in={props.fadeout ? open : true} timeout={{ enter: 0, exit: 1000 }} key={i} unmountOnExit>
                                <Box>
                                    <RwAlert severity="error" color="error" {...(props.handleclose ? { onClose: () => { handleClose(index, i) } } : {})}>
                                        <Typography variant="body1"><strong>Error: </strong> {err}</Typography>
                                    </RwAlert>
                                </Box>
                            </Fade>
                        )
                    })
                })}
            </Box>
        
    );
}

export default RwValidationErrors