import MUIAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react'

const ChipThemed = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,        
        margin: '3px'
    },
    label: {
        color: theme.palette.primary.main
    },
    deleteIcon: {
        color: theme.palette.primary.main
    }
}))(Chip)

const useStyles = makeStyles(theme => ({
    limitTag: {
        display: 'block'
    }
}))

export interface IRwAutocompleteProps extends AutocompleteProps<any, true, false, false> {
    chipwidth?: number | 'none';
    readOnly?: boolean;
}


const RwAutocomplete: React.FC<IRwAutocompleteProps> = (props) => {
    const acClasses = useStyles();
    const defaultChipWidth = props.fullWidth ? 'none' : 165;

    return (
        <MUIAutocomplete
            multiple            
            {...props}
            getLimitTagsText={more => (
                <span className={ acClasses.limitTag }>+{more}</span>
            )}
            renderTags={(value: readonly string[], getTagProps) => {
                    return (
                        value.map((option: string, index: number) => (                            
                            <ChipThemed
                                style={{ maxWidth: props.chipwidth ? props.chipwidth : defaultChipWidth }}
                                key={index}
                                label={props.getOptionLabel(option)}
                                {...(props.readOnly ? {} : { ...getTagProps({ index }) })}
                            />
                        ))
                    )
            }}
        />
    )
}

export default RwAutocomplete;