import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from "@mui/material";


const useStyles = makeStyles(() => ({
    skeleton: {
        marginTop: '100px',
        margin: 'auto',
        width: '85%',
        height: '85%',
    },

}));

export const FormSkeleton: React.FC<any> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.skeleton}>
            <div>
                <div>
                    <div>
                        <div>
                            <div className="header" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Skeleton variant="rectangular" width={250} style={{ marginBottom: 30 }} />
                            </div>
                            <div className="components">
                                {[...Array(5)].map((row, i) => {
                                    return (
                                        <Skeleton key={i} variant="text" width={'100%'} />
                                    );
                                })}
                                <Skeleton variant="text" width={'50%'} style={{ marginBottom: 20 }} />
                                {[...Array(7)].map((row, i) => {
                                    return (
                                        <Skeleton key={i} variant="text" width={'100%'} />
                                    );
                                })}
                                <Skeleton variant="text" width={'20%'} style={{ marginBottom: 20 }} />
                                {[...Array(5)].map((row, i) => {
                                    return (
                                        <Skeleton key={i} variant="text" width={'100%'} />
                                    );
                                })}
                                <Skeleton variant="text" width={'70%'} style={{ marginBottom: 20 }} />
                                {[...Array(5)].map((row, i) => {
                                    return (
                                        <Skeleton key={i} variant="text" width={'100%'} />
                                    );
                                })}
                                <Skeleton variant="text" width={'50%'} style={{ marginBottom: 20 }} />
                                {[...Array(7)].map((row, i) => {
                                    return (
                                        <Skeleton key={i} variant="text" width={'100%'} />
                                    );
                                })}
                                <Skeleton variant="text" width={'20%'} style={{ marginBottom: 20 }} />
                                {[...Array(5)].map((row, i) => {
                                    return (
                                        <Skeleton key={i} variant="text" width={'100%'} />
                                    );
                                })}
                                <Skeleton variant="text" width={'70%'} style={{ marginBottom: 20 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}