import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionProps, AccordionSummary, Grid, LinkProps, Typography, IconButton } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

export interface IRwAccordionButtonProps {
    onClick: () => void;
    icon: JSX.Element;
    id: string;
}

export interface IRwAccordionProps extends AccordionProps {
    title: string;
    name: string;
    error: boolean;
    expanded?: boolean;
    defaultExpanded?: boolean;
    to?: string;
    showSkeleton?: boolean;
    linkprops?: LinkProps;
    buttons?: IRwAccordionButtonProps[];
}

const useStyles = makeStyles((theme) => ({
    accordionSetting: {
        borderRadius: '6px',
        marginTop: '16px',
    },
    errorIcon: {
        marginLeft: '6px',
        top: theme.spacing(1),
        width: theme.typography.h6.fontSize,
        height: theme.typography.h6.fontSize
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline !important',
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
    },
    right: {
        marginLeft: 'auto'
    },
    addButton: {
        color: theme.palette.primary.main,
        borderRadius: '48px',
        margin: '2px',
        width: '28px',
        height: '28px',
        '& svg': {
            fontSize: '28px'
        },
    }
}));

const RwAccordionButton: React.FC<IRwAccordionButtonProps> = props => {
    const classes = useStyles();

    return (
        <IconButton id={props.id} size="large" onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            if (e.currentTarget.type === 'button')
                e.stopPropagation();
            if (props.onClick)
                props.onClick();
        }} className={classes.addButton}>
            {props.icon}
        </IconButton>
    )
}

const RwAccordion: React.FC<IRwAccordionProps> = (props) => {
    const [myExpand, setMyExpand] = useState(props.onChange ? props.expanded : props.defaultExpanded);
    const classes = useStyles();
    const { error, showSkeleton, title, name, ...rest } = props;
    useEffect(() => {
        if (!props.onChange)
            return;

        setMyExpand(props.expanded);
    }, [props.expanded]); // eslint-disable-line react-hooks/exhaustive-deps

    const accordionProps = rest.onChange ? rest :
        { ...rest, onChange: (event: React.ChangeEvent<HTMLInputElement>, isExpanded: boolean) => setMyExpand(isExpanded) }

    return (
        <Accordion className={classes.accordionSetting} {...accordionProps} expanded={myExpand}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ opacity: showSkeleton ? 0 : 1 }} />}
                aria-controls={`${name}-content`}
                id={`${name}-header`}
            >
                <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                        {showSkeleton &&
                            <Skeleton variant="text" width={200} height={25} />
                        }
                        {!showSkeleton &&
                            <Typography variant="h6" >{title}</Typography>
                        }
                    </Grid>
                    <Grid item style={{ display: error ? 'inline-block' : 'none' }} >
                        <ErrorOutline className={classes.errorIcon} color='error' />
                    </Grid>
                    <Grid item className={classes.right} style={{ display: props.linkprops?.title ? 'inline-block' : 'none' }}>   
                        <Link className={classes.link} to="#" onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                            if (e.type !== 'link')
                                e.stopPropagation();
                            if (props.linkprops?.onClick)
                                props.linkprops?.onClick(e);
                        }
                        }>{props.linkprops?.title}</Link>
                    </Grid>
                    {props.buttons &&
                        <Grid item className={classes.right}>
                            {props.buttons.map(button => {
                                return (
                                    <RwAccordionButton key={button.id} {...button} />);
                                })
                            }
                        </Grid>
                    }
                </Grid>
            </AccordionSummary>
            {props.children}
        </Accordion>
    );
}
export default RwAccordion;

