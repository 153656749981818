
import { AnonymousCredential, BlobClient, BlockBlobClient, BlobDownloadResponseParsed, BlockBlobUploadResponse } from "@azure/storage-blob";
const anonymousCredential = new AnonymousCredential();


export interface IAzureService {
    keys;
    getJsonFromAzure(azureBlobSasUri: string | undefined): Promise<string>;
    updatePdfAtAzure(azureBlobSasUri: string, blob: Blob): Promise<string>;
    getPdfFromAzure(azureBlobSasUri: string): Promise<Blob | null>;
}

// identifier for data-sets
export enum Keys {
    AzureForm = "/azure/form"
}

export class AzureService implements IAzureService {

    keys = Keys;

    getJsonFromAzure = (azureBlobSasUri: string): Promise<string> => {

        return new Promise((resolve, reject) => {

            const blobClient = new BlobClient(
                azureBlobSasUri,
                anonymousCredential
            );

            blobClient.download().then((response: BlobDownloadResponseParsed) => {

                if (response.blobBody) {
                    response.blobBody.then((blob: Blob) => {
                        const reader = new FileReader();

                        reader.addEventListener('loadend', (e) => {
                            if (e.target) {
                                resolve((e.target).result as string);
                            } else {
                                reject();
                            }
                        });

                        reader.readAsText(blob);

                    }).catch(() => reject);

                }
            });
        });

    }

    getPdfFromAzure = async (azureBlobSasUri: string): Promise<Blob | null> => {

        const blobClient = new BlobClient( azureBlobSasUri, anonymousCredential);

        try {
            const response = await blobClient.download();
            const blobData = await response.blobBody;
            const pdfBlob = new Blob([blobData], { type: "application/pdf" });
            return pdfBlob;
        } catch (error) {
            console.error("Error downloading blob:", error);
            return null;
        }

        return null;
    }


    updatePdfAtAzure = (azureBlobSasUri: string, blob: Blob): Promise<string> => {

        const blockBlobClient = new BlockBlobClient(azureBlobSasUri, anonymousCredential);

        return new Promise((resolve, reject) => {

            blockBlobClient.uploadData(blob).then((response: BlockBlobUploadResponse) => {

                if (!response.errorCode) {
                    resolve('sucess');
                } else {
                    reject();
                }

            }).catch(() => reject);
        });

    }

}


