import React from 'react';
import IComponent from '../../../../services/forms.interface';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import makeStyles from '@mui/styles/makeStyles';
import myxss from './myxss';
import classNames from 'classnames';

interface ICustomProps {
    name: string;
    type: string;
    value: string;
    defaultValue: string;
    disabled: boolean;
    read_only: string;
    required: boolean;
    maskKey: string;
    error: any;
    onChange: any;
    onBlur: any;
    label: string;
    placeholder: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    textArea: {
        backgroundColor: '#F9F9F9',
        width: '99%',
        borderRadius: '4px',
        '&.showError': {
            border: theme.custom.errorBorder,
        },
    },
    
}));


const TextArea: React.FC<IComponent> = props => {
    const classes = useStyles();

    const handleChange = (event) => {
        props.handleChange(event, props.id, event.target.value);
    };

    const isRequired = (props.hasOwnProperty('required') && props.required === true && !props.read_only);
    const labelText = myxss.process(props.label);

    const localProps: ICustomProps = {} as ICustomProps;
    localProps.type = 'text';
    localProps.name = props.field_name;
    localProps.onChange = handleChange;
    localProps.required = isRequired;
    localProps.defaultValue = props.value;
    localProps.placeholder = localProps.label = labelText;
    localProps.disabled = props.disabled;

    const fieldProps: any = {};
    fieldProps.name = props.field_name;

    if (props.disabled) {
        localProps.disabled = true;
    }

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }

    return (
        <div className={baseClasses}>
            <TextareaAutosize className={classNames(classes.textArea, { showError: props.hasOwnProperty('error')})}
                minRows={5}
                maxRows={5}
                
                    {...localProps}
                />
        </div>
    )
}

export default TextArea;

