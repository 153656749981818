import React from 'react';
import IComponent from '../../../../services/forms.interface';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import makeStyles from '@mui/styles/makeStyles';
import { FormLabel, Typography } from '@mui/material';
import myxss from './myxss';
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    FormGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    Row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    label: {
        marginRight: '10px'
    }

}));

const RadioButtons: React.FC<IComponent> = props => {
    const [value, setValue] = React.useState(props.defaultValue || '');
    const classes = useStyles();

    const handleChange = (event) => {
        setValue(event.target.value);
        props.handleChange(event, props.id, event.target.value);
    };

    const baseClasses = 'custom-control custom-radio';


    return (
        <div className={classNames(baseClasses, classes.Row)}>
            {props.displayLabel &&
                <div className={classes.label}>
                    <Typography variant="subtitle1" fontSize={17}>
                        {myxss.process(props.label)}
                    </Typography>
                </div>
            }
            <RadioGroup row aria-label="position" name={props.name} value={value} onChange={handleChange}>
                {props.options.map((option) => {
                    const this_key = `preview_${option.key}`;
                    return (
                        <div className={baseClasses} key={this_key}>

                            <div className={classes.FormGroup}>
                                <Radio
                                    name={props.name}
                                    size="small"
                                    disabled={props.disabled}
                                    color={props.hasOwnProperty('error') ? 'secondary' : 'primary'}
                                    value={option.value}
                                />
                                        
                                <FormLabel component="legend" error={props.hasOwnProperty('error')}>{option.text}</FormLabel>
                            </div>
                                    
                        </div>
                    );
                })}
            </RadioGroup>
        </div>
    )
}

export default RadioButtons;

