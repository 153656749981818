import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { ContentTitle } from '../membership/content-title';
import { useAppContext } from "../../context/app-context";
import { CircularProgress } from '@mui/material';
import { ITitleButtonProps } from '../membership/content-title';
import classNames from 'classnames';


export interface IRwPageProps {
    title?: string;
    hideDrawer?: boolean;
    buttons?: ITitleButtonProps[];
    backButton?: ITitleButtonProps;
    loading?: boolean;
    showLocationFilter?: boolean;
    actionBar?: React.ReactElement;
    overrideElement?: React.ReactElement;
    hide?: boolean;
}

const useStyles = makeStyles((theme) => ({
    page: {
        flex: '1 0',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        '&.hide': {
            display: 'none'
        }
    },
    innerPage: {
        display: 'flex',
        flex: '1 1 100%',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '15px 0px 0px 0px',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto'

    },
    header: {
        flexShrink: 0
    },
    overlay: {
        background: '#ffffff',
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: 5000,
        top: 0,
        left: 0,
        float: 'left',
        textAlign: 'center',
        paddingTop: '20%',
        opacity: 0.70
    },
}));



const RwPage: React.FC<IRwPageProps> = (props) => {
    const classes = useStyles();

    const { setDrawerVisible, loading, setLocationFilterVisible } = useAppContext();

    useEffect(() => {
        setDrawerVisible(!props.hideDrawer);
    }, [setDrawerVisible, props.hideDrawer]);

    useEffect(() => {
        setLocationFilterVisible(props.showLocationFilter);
    }, [setLocationFilterVisible, props.showLocationFilter])

    return (
        <div className={classNames(classes.page, { hide: props.hide })}>
            <ContentTitle className={classes.header} {...props} />
            {loading &&
                <div className={classes.overlay}>
                    <CircularProgress />
                </div>}
            <div className={classes.innerPage}>
                {props.children}
            </div>

            {props.actionBar && props.actionBar}

        </div>
    );
}

export default RwPage