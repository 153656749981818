import React, { CSSProperties, useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import { horizontalListSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import SortableField from "./sortable-field";
import { HEADER, SUBHEADER } from "../shared/constants";

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexBasis: '98%',
        borderRight: '1px dashed rgba(0, 0, 0, 0.23)',
        alignItems: 'center',
        borderRadius: 5,
        border: "1px solid #ccc",
        '&:hover': {
            border: (props: any) => props.isOverField ? "1px dashed #ccc" : "1px dashed #2E7D31",
        },
        margin: '10px'
    },
   
    actionColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '2%',
        margin: '3px 3px 3px 3px',
        color: 'hsla(214, 11%, 60%, 1)',
        borderRadius: '0px 4px 4px 0px',
    
    },
    delete: {
        padding: 2,
        fontSize: 16,
        marginTop: 'auto',
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.23)',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#fc7303',
            borderRadius: '8px'
        }
    }
}));

const SortableRow = ({ item, container, deleteRow, warning, siblingCount, deleteComponent, googleMapApiKey, displayLabel }) => {
    const [isOverField, setIsOverField] = useState(false);
    const classes = useStyles({ isOverField: isOverField });

    const { setNodeRef, attributes, listeners, transition, transform, isDragging } = useSortable({
        id: item.id,
        data: {
            type: "card"
        }
    });

    const style: CSSProperties = {
        transition,
        transform: CSS.Transform.toString(transform),
        opacity: isDragging ? 0.5 : 1,
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        borderRadius: 5,
        marginBottom: '0px',
        cursor: 'move',

       
    }

    if (warning) {
        style.border = "1px solid orange";
    }


    return (
        <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={style}>
                <div className={classes.row}>
                <SortableContext
                    items={item.blocks?.map((block) => block?.id)}
                    strategy={horizontalListSortingStrategy}
                >
                    {item.blocks?.map((block) => {
                        return (
                            <SortableField
                                block={block}
                                container="B"
                                key={block?.id}
                                siblingCount={item.blocks.length}
                                deleteComponent={deleteComponent}
                                googleMapApiKey={googleMapApiKey}
                                disabled={block.data.type === HEADER || block.data.type === SUBHEADER}
                                displayLabel={displayLabel}
                                setIsOverField={setIsOverField}
                            />
                        );
                    })}
                </SortableContext>
                </div>
            <div className={classes.actionColumn} data-no-dnd="true">
                <Tooltip title="Delete Row" data-no-dnd="true" placement="bottom">
                    <DeleteOutlinedIcon className={clsx(classes.delete, 'show-on-hover')} onClick={() => deleteRow(item.id)} />
                </Tooltip>
                </div>
        </div>
    );
};

export default SortableRow;

