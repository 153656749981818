import React, { useCallback, useContext, useEffect, useState } from 'react';
import useAuthenticatedRequest from '../hooks/use-authenticated-request';
import { useNotification } from '@revenuewell/logic-notification';
import useRwPlatformClient from '../hooks/use-rw-platform-client';
import { LocationData } from '../hooks/use-rw-platform-client.interface';
import { useOidc, UserClass } from '@revenuewell/logic-oidc';
import { httpService } from '../services/httpService';
import clientService from '../services/clientService';
import { OidcConfigurationClient } from '../api/web-api-client';

interface IAppContext {
    drawerVisible: boolean;
    setDrawerVisible?: React.Dispatch<React.SetStateAction<boolean>>
    productMenuVisible: boolean;
    setProductMenuVisible?: React.Dispatch<React.SetStateAction<boolean>>
    loading: boolean;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
    ShowError?: (message: string, duration?: number) => void,
    ShowWarning?: (message: string, duration?: number) => void,
    ShowSuccess?: (message: string, duration?: number) => void;
    allLocations?: LocationData[];
    selectedLocations?: LocationData[];
    allLocationsWithoutForms2FeatureFlag?: LocationData[];
    setSelectedLocations?: React.Dispatch<React.SetStateAction<LocationData[]>>
    locationFilterVisible: boolean;
    setLocationFilterVisible?: React.Dispatch<React.SetStateAction<boolean>>
    unwrapperedDomain: string;
    test: boolean;
    initializeLocations?: () => Promise<void>,
    getMasterAccountId?: () => Promise<number>
}

export const defaultState = {
    drawerVisible: true,
    productMenuVisible: true,
    loading: false,
    locationFilterVisible: false,
    test: false,
    unwrapperedDomain: null
};

const AppContext = React.createContext<IAppContext>(defaultState);

export const useAppContext = () => useContext(AppContext);

const CUSTOMER_ID = 'customerId';
const LOCATION_ID = 'locationId';

export const AppContextProvider: React.FC = ({ children }) => {
    const [drawerVisible, setDrawerVisible] = useState(defaultState.drawerVisible);
    const [productMenuVisible, setProductMenuVisible] = useState(defaultState.drawerVisible);
    const [loading, setLoading] = useState(defaultState.loading);
    const { showSuccess, showWarning, showError } = useNotification();
    const { getLocations, getIfForms2AvailableForLocations, getLocationsByAccountId } = useRwPlatformClient();
    const [allLocations, setAllLocations] = useState([] as LocationData[]);
    const [allLocationsWithoutForms2FeatureFlag, setAllLocationsWithoutForms2FeatureFlag] = useState([] as LocationData[]);
    const [unwrapperedDomain, setUnwrapperedDomain] = useState(null as string);
    const [selectedLocations, setSelectedLocations] = useState([] as LocationData[]);
    const [locationFilterVisible, setLocationFilterVisible] = useState(defaultState.locationFilterVisible);
    const [test, setTest] = useState(false);
    const { getClaims } = useOidc();
    const queryParams = new URLSearchParams(window.location.search);

    const setDomain = async () => {
        try {
            const client = clientService.getFormsClient(OidcConfigurationClient, null);
            const domain = await client.getUnwrapperedDomain(null);
            setUnwrapperedDomain(domain);
        } catch (e) {
            ShowError('Problem occurred while loading domain.');
        }
    }

    useAuthenticatedRequest(); // globally configuring axios with this hook here.

    const ShowError = useCallback((message: string, duration?: number): void => {
        showError(message, duration);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const ShowWarning = useCallback((message: string, duration?: number): void => {
        showWarning(message, duration);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const ShowSuccess = useCallback((message: string, duration?: number): void => {
        showSuccess(message, duration);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMasterAccountId = useCallback(async (): Promise<number> => {
        const claims = await getClaims();
        const accountId = claims.masterAccountId;
        if (accountId)
            return accountId;
        const customerIdStr = localStorage.getItem(CUSTOMER_ID);
        if (customerIdStr) {
            return parseInt(customerIdStr);
        }
        return null;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initializeLocations = async () => {
        const claims = await getClaims();
        const admin: UserClass = 'GlobalUser';
        const pattersonUser = 'PattersonUser';
        const customerIdParam = queryParams.get(CUSTOMER_ID);
        const customerId = customerIdParam ? parseInt(customerIdParam) : null;
        const isGlobalUserOrPattersonUser = claims.userClass === admin || claims.userClass.toString() === pattersonUser;
        try {
            // in case a global user is using 'login to customer', get the appropriate locations for them.
            const locations = customerId && isGlobalUserOrPattersonUser ? await getLocationsByAccountId(customerId) : await getLocations();
            locations.sort((a, b) => a.name > b.name ? 1 : -1);

            // filter out locations without forms2 flag
            const res = await getIfForms2AvailableForLocations(locations?.map(a => a.id));
            const availLocations = res.filter(a => a.isAvailable).map(b => b.id);
            const locationsWithoutForms2 = res.filter(a => !a.isAvailable).map(b => b.id);
            const locationsFiltered = locations.filter(b => availLocations.some(c => c === b.id));
            const locationsWithoutForms2Filtered = locations.filter(b => locationsWithoutForms2.some(c => c === b.id));

            setAllLocations(locationsFiltered);
            setAllLocationsWithoutForms2FeatureFlag(locationsWithoutForms2Filtered);
            // send account data in the headers for global users

            if (customerId && isGlobalUserOrPattersonUser) {
                const instance = httpService.GetInstance();
                localStorage.setItem(CUSTOMER_ID, customerId.toString());
                const locationIdParam = queryParams.get(LOCATION_ID);
                const locationId = locationIdParam ? parseInt(locationIdParam) : null;
                if (locationId) {
                    localStorage.setItem(LOCATION_ID, locationId.toString());
                }
                instance.defaults.headers.common['RwLocationIds'] = `[${locationsFiltered.map(l => l.id).join(',')}]`;
                instance.defaults.headers.common['RwAccountId'] = `${customerId}`;
            }
        }
        catch (e) {
            ShowError("Problem occurred while loading location data.");
        }
    };

    useEffect(() => {
        if (queryParams.get('test') == 'true') setTest(true);
        initializeLocations();
        setDomain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider
            value={{
                drawerVisible,
                setDrawerVisible,
                productMenuVisible,
                setProductMenuVisible,
                loading,
                setLoading,
                ShowError,
                ShowWarning,
                ShowSuccess,
                allLocations,
                selectedLocations,
                allLocationsWithoutForms2FeatureFlag,
                setSelectedLocations,
                locationFilterVisible,
                setLocationFilterVisible,
                test,
                getMasterAccountId,
                unwrapperedDomain,
                initializeLocations
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
