import { useEffect, useState } from "react";
import useSWR, { Key, SWRResponse } from "swr";
import { Fetcher, SWRConfiguration } from "swr/dist/types";

export interface RwSWRResponse<Data, Error> extends SWRResponse<Data, Error> {
    firstValidation: boolean;
}

export default function useRwSWR<Data = any, Error = any>(...args: readonly [Key] | readonly [Key, Fetcher<Data> | null] | readonly [Key, SWRConfiguration<Data, Error> | undefined] | readonly [Key, Fetcher<Data> | null, SWRConfiguration<Data, Error> | undefined]): RwSWRResponse<Data, Error> {
    const [firstValidation, setFirstValidation] = useState(true);
    const vals = useSWR(...args);

    useEffect(() => {
        if (!firstValidation || !vals.isValidating) {
            setFirstValidation(false);
        }
    }, [vals.isValidating, firstValidation]);

    return { ...vals, firstValidation };
}