import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IComponent from '../../../../services/forms.interface';
import myxss from './myxss';

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        margin: theme.spacing(2),
    },
    textField: {
        minWidth: '100%',
    },
    menu: {
        margin: theme.spacing(1),
        minWidth: 300,
    }
}));

interface ICustomProps {
    name: string;
    type: string;
    defaultValue: string | number;
    disabled: boolean;
    read_only: string;
    required: boolean;
    mask: string;
    error: any;
    onChange: any;
    label: string;
}

interface IDropdownProps extends Omit<IComponent, 'defaultValue'> {
    defaultValue: string | number;
}

const Dropdown: React.FC<IDropdownProps> = props => {
    const classes = useStyles();

    const handleChange = (event) => {
        props.handleChange(event, props.id, event.target.value);
    };

    const isRequired = (props.hasOwnProperty('required') && props.required === true && !props.read_only);
    const labelText = myxss.process(props.label);

    const localProps: ICustomProps = {} as ICustomProps;
    localProps.type = 'text';
    localProps.name = props.field_name;
    localProps.onChange = props.handleChange;
    localProps.required = isRequired;
    localProps.mask = props.mask;
    localProps.label = labelText;
    if (props.mutable) {
        localProps.defaultValue = props.defaultValue ? props.defaultValue : "";
    }

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }
    

    if (props.disabled) {
        localProps.disabled = true;
    }
    
    return (
        <div className={baseClasses}>
            <TextField
                {...localProps}
                name={props.field_name}
                select
                label={labelText}
                className={classes.textField}
                onChange={handleChange}
                required={isRequired}
                variant='outlined'
                size="small"
                margin="none"
                sx={{ backgroundColor: '#F9F9F9' }}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu
                    }
                }}
                error={props.hasOwnProperty('error')}
                //helperText={helperText}
            >
                {props.options.map((option) => (
                    <MenuItem key={`preview_${option.key}`} value={option.value}>{option.text}</MenuItem>
                ))}
            </TextField>
        </div>
    );
}

export default Dropdown;