import React, { ChangeEvent, useState } from 'react';
import DynamicOptionList from './dynamic-option-list';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Radio, RadioGroup, Switch } from '@mui/material';
import FieldNames from './pms-field-names';
import ReactQuill,{ Quill }  from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormBuilderContext } from './form-builder-context';
import 'quill-paste-smart';
import { FormFieldDto } from '../../../api/web-api-client';

export const CustomRadio = withStyles(theme => ({
    root: {
        '&$checked': {
            color: theme.palette.success.main,
        },
    },
    checked: {},
}))(Radio);

const itemPadding = '24px';

const useStyles = makeStyles((theme) => ({
    textBox: {
        width: '100%',
    },
    container: {
        alignContent: 'center',
    },
    section: {
        paddingLeft: itemPadding + ' !important',
        paddingRight: itemPadding + ' !important',
        width: '100%'
    },
    sectionHeader: {
        paddingBottom: 9,
    },
    sectionSubHeader: {
        paddingBottom: 20,
    },
    buttonPreview: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        padding: '8px 22px',
        position: 'static',
        marginRight: 'auto',
        width: '100%'
    },
    buttonSettings: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        alignSelf: 'flex-end',
        alignItems: 'center',
        padding: '8px 22px',
        position: 'static',
        width: '100%'
    },
    closePanel: {
        alignItems: 'center',
        padding: itemPadding,
    },
    typePanel: {        
        height: 51,
        backgroundColor: theme.custom.listItemBackground,
        display: 'flex',
        alignItems: 'center',
        borderTop: theme.palette.grey[200],
        borderTopStyle: 'solid',
        padding: '0 !important',
        marginBottom: '20px',
        '& > *': {
            ...theme.typography.body1,
            fontWeight: theme.typography.fontWeightMedium,
            paddingLeft: itemPadding,
            paddingRight: itemPadding,
        }
    },
    switchLabel: {
        marginLeft: 0
    },
    quill: {
        '& .ql-tooltip': {
        }
    }
}));



const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body1,
    marginTop: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
}));

const ItemOptions = styled(Box)(({ theme }) => ({
    ...theme.typography.body1,
    paddingBottom: '30px',
    textAlign: 'left',
    color: theme.palette.text.secondary,  
    marginBottom: '20px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    ...theme.typography.body2,
    justifyContent: 'space-between'
}));

export interface IParagraphEditorProps {
    defaultValue: string;
    onChange: (html: string) => void;
    element: any;
}

const ParagraphEditor = (props: IParagraphEditorProps) => {
    const [value, setValue] = useState(props.defaultValue || '');
    const { state } = useFormBuilderContext();
    const classes = useStyles();

    const handleChange = (html) => {
        setValue(html);
        state.editElement.content = html;
        props.onChange(html);
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ['link'],
            ['clean']
        ],
        clipboard: {
            allowed: {
                tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
                attributes: ['href', 'rel', 'target', 'class']
            },
            matchVisual: false,
            keepSelection: true,
            substituteBlockElements: true,
            magicPasteLinks: true,

        }
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'align',
        'link'
    ];

    const Link = Quill.import('formats/link');

    Link.sanitize = (url: string) => {
        // quill by default creates relative links if scheme is missing.
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `http://${url}`
        }
        return url;
    }

    const blurHandler = () => {
        const el = document.querySelector('.ql-tooltip [data-link]');

        if (!el) {
            return;
        }

        el.setAttribute('placeholder', 'https://www.example.com');
        el.setAttribute('data-link', 'https://www.example.com');
    }
    return (<div className="quill-wrapper"><ReactQuill theme="snow" onBlur={blurHandler} value={value} onChange={handleChange} modules={modules} formats={formats} className={classes.quill} bounds={'.quill-wrapper'} /></div>);
}

const FormElementsEdit: React.FC = props => {
    const classes = useStyles();
    const { state, editModeOff, updateComponent, updateComponentFormField } = useFormBuilderContext();

    const editElementProp = (elemProperty, targProperty, e) => {
        updateComponent(state.editElement.id, elemProperty, e.target[targProperty]);
    }

    const editElementContentProp = (content: string) => {
        state.editElement.content = content;
    }
    
    const editElementFormFieldProp = (formField: FormFieldDto, event) => {

        updateComponentFormField(state.editElement.id, formField, state.pmsSystemVm.formFields);

        if (!formField)
            return;

        const field = state.pmsSystemVm.formFields?.find(obj => obj.name === formField.name);

        addRemovePmsFieldOptions(field);
    }

    const addRemovePmsFieldOptions = (field) => {

        const this_element = { ...state.editElement }; 
        
        if (this_element.type !== 'Dropdown' && this_element.type !== 'Checkbox' && this_element.type !== 'RadioButtons')
            return;

        this_element.options = [];

        field?.formFieldOptions.map((option, index) => {
            this_element.options.push({ text: option.text, value: option.value, key: 'dropdown_option_' + this_element.id + '_' + option.id });
        });

        updateComponent(this_element.id, 'options', this_element.options);
    }

    const editElementMaskProp = (event: ChangeEvent<HTMLInputElement>) => {
        updateComponent(state.editElement.id, 'mask', event.target.value);
    }

    const this_checked = state.editElement.hasOwnProperty('required') ? state.editElement.required : false;
    const this_explaination = state.editElement.hasOwnProperty('explaination') ? state.editElement.explaination : false;
    const this_read_only = state.editElement.hasOwnProperty('readOnly') ? state.editElement.readOnly : false;
    const this_default_today = state.editElement.hasOwnProperty('defaultToday') ? state.editElement.defaultToday : false;
    const this_has_pms_options = state.pmsSystemVm.formFields?.some(obj => obj.name === state.editElement.name && obj.formFieldOptions?.length > 0);

    const { canHaveOptionCorrect } = state.editElement;

    let editorState;

    if (state.editElement.hasOwnProperty('content')) {
        editorState = state.editElement.content;
    }
    if (state.editElement.hasOwnProperty('label')) {
        editorState = state.editElement.label;
    }

    

    return (
        <Box className={classes.container}>
            <Grid container>
                <Grid container item className={classes.closePanel} xs={12}>
                    <IconButton aria-label="close" onClick={editModeOff} size="large">
                        <CloseIcon />
                    </IconButton>                    
                    <Typography variant="h6">Question Properties</Typography>
                </Grid>
                <Grid item className={classes.typePanel} xs={12}>
                    <Typography>
                        {state.editElement.typeDisplay} Settings
                    </Typography>
                </Grid>
                <Grid item className={classes.section}>
                    <ItemOptions>
                        <Typography className={classes.sectionHeader} variant='subtitle2'>Question</Typography>
                        {state.editElement.hasOwnProperty('title') &&
                            <Grid item xs={12}>
                                <FormControl className={classes.textBox}>
                                <TextField variant="outlined" defaultValue={state.editElement.title} onChange={editElementProp.bind(this, 'title', 'value')} />
                                </FormControl>
                            </Grid>
                        }
                        {state.editElement.hasOwnProperty('content') &&
                            <Grid item xs={12}>
                            <FormControl className={classes.textBox}>
                                {state.editElement.type !== 'Paragraph' &&
                                    <TextField variant="outlined" defaultValue={editorState} onChange={editElementProp.bind(this, 'content', 'value')} />
                                }
                                {state.editElement.type === 'Paragraph' &&
                                    <ParagraphEditor defaultValue={editorState} onChange={editElementContentProp.bind(this)} element={state.editElement}/>
                                }
                            </FormControl>
                            </Grid>
                        }
                        {state.editElement.hasOwnProperty('label') &&
                            <Grid item xs={12}>
                                <TextField variant="outlined" className={classes.textBox} defaultValue={editorState} onChange={editElementProp.bind(this, 'label', 'value')} />
                            </Grid>
                        }
                    </ItemOptions>
                    {state.pmsSystemVm && state.pmsSystemVm.name !== 'Unknown' &&
                        <ItemOptions>
                            <Typography className={classes.sectionHeader} variant='subtitle2'>{state.pmsSystemVm.name} Field Type</Typography>
                            <Typography className={classes.sectionSubHeader} variant='body2'>Used to map submission values to the appropriate {state.pmsSystemVm.name} location</Typography>
                            <FieldNames onChange={editElementFormFieldProp.bind(this)} fields={state.pmsSystemVm.formFields} component={state.editElement} components={state.components} elementType={state.editElement.type} />
                        </ItemOptions>
                    }
                    {state.pmsSystemVm && state.pmsSystemVm.name === 'Unknown' &&
                        <ItemOptions>
                            <Typography className={classes.sectionHeader} variant='subtitle2'> Practice Management System (PMS) type could not be determined. This can happen if a form is shared by multiple locations that use different PMS. We recommend only sharing forms between locations that share a PMS.</Typography>
                            
                        </ItemOptions>}

                    {state.editElement.type === 'SwitchInput' &&
                        < ItemOptions >
                        <Typography variant='subtitle2'>Explanation</Typography>
                            <FormGroup>
                                <StyledFormControlLabel
                                    control={
                                        <Switch
                                            checked={this_explaination}
                                            value="true"
                                            onChange={editElementProp.bind(this, 'explaination', 'checked')}
                                            id="explaination"
                                            className="custom-control-input"
                                        />
                                    }
                                    label="Ask for explanation?"
                                    labelPlacement="start"
                                    className={classes.switchLabel}
                                />
                            </FormGroup>
                        </ItemOptions>
                    }

                    <Item>
                        
                        {state.editElement.hasOwnProperty('options') &&
                        <ItemOptions>
                                <DynamicOptionList showCorrectColumn={state.showCorrectColumn}
                                canHaveOptionCorrect={canHaveOptionCorrect}
                                    canHaveOptionValue={false}
                                    hasPmsOptions={this_has_pms_options}
                                    softwareType={state.pmsSystemVm.name}
                                key={`${state.editElement.id}_option_list`} />
                        </ItemOptions>
                    }
                    {state.editElement.hasOwnProperty('label') &&
                            <div>
                            {state.editElement.hasOwnProperty('mask') && state.editElement.type !== 'NumberInput' &&
                                <ItemOptions>
                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend">Format</FormLabel>
                                    <RadioGroup aria-label="mask" name="mask1" row value={state.editElement.mask} onChange={editElementMaskProp.bind(this)}>
                                        <StyledFormControlLabel value="" control={<CustomRadio />} label="None" />
                                        <StyledFormControlLabel value="maskEmail" control={<CustomRadio />} label="Email" />
                                        <StyledFormControlLabel value="maskPhoneUs" control={<CustomRadio />} label="Phone" />
                                        <StyledFormControlLabel value="maskZipUs" control={<CustomRadio />} label="Zip" />
                                        <StyledFormControlLabel value="maskSSN" control={<CustomRadio />} label="SSN" />
                                        <StyledFormControlLabel value="maskNumbers" control={<CustomRadio />} label="Numeric" />
                                        </RadioGroup>
                                    </FormControl>
                                </ItemOptions>
                            }
                            {state.editElement.type !== 'Allergies' && state.editElement.type !== 'Medications' &&
                                <ItemOptions>
                                    <Typography variant='subtitle2'>Required</Typography>
                                    <FormGroup>
                                        <StyledFormControlLabel
                                            control={
                                                <Switch
                                                    checked={this_checked}
                                                    value="true"
                                                    onChange={editElementProp.bind(this, 'required', 'checked')}
                                                    id="is-required"
                                                    className="custom-control-input"
                                                />
                                            }
                                            label="Is this field required?"
                                            labelPlacement="start"
                                            className={classes.switchLabel}
                                        />
                                    </FormGroup>
                                </ItemOptions>
                            }
                            
                            {state.editElement.hasOwnProperty('readOnly') &&
                                <ItemOptions>
                                    <Typography variant='subtitle2'>Read Only?</Typography>
                                    <FormGroup>
                                        <StyledFormControlLabel
                                            control={
                                                <Switch
                                                    checked={this_read_only}
                                                    value="true"
                                                    onChange={editElementProp.bind(this, 'readOnly', 'checked')}
                                                    id="is-read-only"
                                                    className="custom-control-input"
                                                />
                                            }
                                            label="This field will be set to read only and cannot be edited."
                                            labelPlacement="start"
                                            className={classes.switchLabel}
                                        />
                                    </FormGroup>
                                </ItemOptions>
                            }
                            {state.editElement.hasOwnProperty('defaultToday') &&
                                <ItemOptions>
                                <Typography variant='subtitle2'>Default to Today?</Typography>
                                    <FormGroup>
                                        <StyledFormControlLabel
                                            control={
                                                <Switch
                                                    checked={this_default_today}
                                                    value="true"
                                                    onChange={editElementProp.bind(this, 'defaultToday', 'checked')}
                                                    id="is-default-to-today"
                                                    className="custom-control-input"
                                                />
                                            }
                                            label="Today’s date will automatically be entered on this field."
                                            labelPlacement="start"
                                            className={classes.switchLabel}
                                        />
                                    </FormGroup>
                                </ItemOptions>
                            }
                           

                        </div>
                    }




                        {state.editElement === 'Signature' && state.editElement.readOnly
                        ? (
                            <ItemOptions>
                                <FormGroup>
                                    <FormControl className={classes.textBox}>
                                        <TextField variant="outlined" id="variableKey" label="Variable Key"
                                            helperText="This will give the element a key that can be used to replace the content with a runtime value."
                                        />
                                    </FormControl>
                                </FormGroup>
                            </ItemOptions>
                        )
                        : (<div />)
                    }
                   
                    {state.editElement.hasOwnProperty('default_value') &&
                        <div className="form-group">
                            <div className="form-group-range">
                                <label className="control-label" htmlFor="defaultSelected">Default Selected</label>
                                <input id="defaultSelected" type="number" className="form-control" defaultValue={state.editElement.default_value} onChange={editElementProp.bind(this, 'default_value', 'value')} />
                            </div>
                        </div>
                    }
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );

}

FormElementsEdit.defaultProps = { className: 'edit-element-fields' };

export default FormElementsEdit;

