import React, { useEffect, useState } from "react";
import { Redirect, Link, useLocation } from "react-router-dom";
import { ALLERGIES, ALLERGIESPMS, BLOCK, BUTTON, CARD, COLUMN, COMPONENT, HEADER, MEDICATIONS, PROBLEMS, PROBLEMSPMS, ROW, SIDEBAR_ITEMS, SUBHEADER } from "../shared/constants";
import makeStyles from '@mui/styles/makeStyles';
import { Button, Box, Typography, Drawer, Tabs, Tab, FormGroup, Tooltip, FormControlLabel, Switch, Skeleton, Grid, Checkbox } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Drawer as RWDrawer } from "@revenuewell/ui-drawers";
import { DrawerSection } from '../../membership/drawer-section';
import FormElementsEdit from "../shared/form-element-edit";
import ConfirmationDialog from "../../shared/confirmation-dialog";
import { FormVm } from "../../../api/web-api-client";
import RwAccordion from "../../shared/rw-accordion";
import { ActionKind, useFormBuilderContext } from "../shared/form-builder-context";
import { FormJsonData } from "../../../services/forms.interface";
import ID from "../../shared/UUID";
import {
    DndContext,
    KeyboardSensor,
    useSensor,
    useSensors,
    rectIntersection,
    MouseSensor as LibMouseSensor,
    TouchSensor as LibTouchSensor,
    DragOverlay,
    useDroppable
} from "@dnd-kit/core";

import {
    SortableContext,
    sortableKeyboardCoordinates,
    arrayMove,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { handleCreateCompositeComponent, initComponent } from "../shared/helpers";
import DraggableField from "./draggable-field";
import SortableRow from "./sortable-row";
import SortableField from "./sortable-field";
import DraggableRow from "./draggable-row";
import { useAppContext } from "../../../context/app-context";
import { Virtuoso } from "react-virtuoso";
import EmptyRows from "./empty-rows";


interface Block {
    id: string;
    text: string;
    data?: any;
}

interface Item {
    id: string;
    text: string;
    blocks: Block[];
}

const TOOL_TIP_ON = 'Your changes will be saved as a draft to prevent a potential loss of work. Your changes will not be made public until you click the Save button.';
const TOOL_TIP_OFF = 'Enable this feature to prevent a potential loss of work. Your changes will not be made public until you click the Save button.';

const drawerWidth = 690;

const useStyles = makeStyles((theme) => ({
    app: {
        '& div:first-child': {
            '&::-webkit-scrollbar': {
                width: '0.4em',
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '7px'
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.25)',
                borderRadius: '7px'
            }
        },
    },
    builder: {
        height: "100%",
        overflow: "hidden",
        width: "100%",
        background: "#fafafa"
    },
    innerPage: {
        width: "97%",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "100%",
    },
    pageRow: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        height: "100%",
    },
    main: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20px',
        marginTop: '15px',
        height: "90%",
    },
    dnd: {
        marginTop: '-10px',
        paddingLeft: "20px",
        paddingRight: "20px",
        position: "relative",
        width: "97%",
        height: "85%",
        border: "1px solid #E9EAEE",
        borderRadius: '5px'

    },
    preview: {
        width: '35%',
        height: '86%',
        overflow: 'hidden',
        marginTop: '5px',
    },
    previewScaled: {
        transformOrigin: "top left",
        transform: "scale(0.5)",
        height: '200%',
        width: '200%',
        marginTop: '5px',
        backgroundColor: '#FAFAFA !important'
    },
    drawer: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
        border: '1px solid #E9EAEE',
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        zIndex: 1251,
    },
    buttonBack: {
        paddingBottom: '8px',
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        marginLeft: '20px',
        marginRight: '45px',
        paddingBottom: 20,
        marginBottom: '20px',
    },
    tab: {
        minWidth: '50%',
        width: '50%',
    },
    otherSubtitle: {
        marginTop: '20px',
    },
    toolBarItems: {
        width: '100%',
        padding: 0,
        margin: 0,
        overflow: 'hidden'
    },
    autoSave: {
        marginLeft: '20px',
        marginTop: '5px',
        flexDirection: 'row',
        alignItems: 'center',

    },
    sideBar: {
        '& .MuiBox-root': {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
        minWidth: '350px',
    },
    builderError: {
        marginLeft: '20px',
        marginRight: '28px',
        flexDirection: 'column',
    },
    row: {
        width: '99%',
    },
    buttonSuccess: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 22px',
        position: 'static',
        '&:hover': {
            backgroundColor: theme.palette.success.dark
        }
    },
    buttonCancel: {
        backgroundColor: theme.custom.cancel,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '8px 22px',
        position: 'static',
        '&:hover': {
            backgroundColor: theme.custom.cancelHover
        }
    },
    warnings: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.palette.warning.main,
        marginRight: '10px'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '30px',
        alignItems: 'center',
    },
    label: {
        marginRight: '10px',
    },
    displayLabels: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
}));


interface IContainer {
    formData: FormVm,
    data: FormJsonData,
    onSave: (d: any, r: boolean) => void,
    showCorrectColumn?: boolean,
    showDescription?: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    packetId?: string
}

const Container: React.FC<IContainer> = (props) => {
    const classes = useStyles();
    const { state, dispatch, saveExperimentData, editModeOff, createNoToAllRules } = useFormBuilderContext();
    const { ShowWarning, ShowSuccess } = useAppContext();
    const { search } = useLocation();
    const [subShowSectionAlert, setShowSectionAlert] = useState<boolean>(true);
    const [refreshSideBar, setRefreshSideBar] = useState<boolean>(false);
    const [dropped, setDropped] = useState<boolean>(false);
    const [activeContainer, setActiveContainer] = useState<string>('A');
    const [activeItem, setActiveItem] = useState<Item>();
    const [activeBlock, setActiveBlock] = useState<Block>();
    const [items, setItems] = useState({ A: [], B: [] });
    const cardsList = {};
    const [warnings, setWarnings] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const ref = React.useRef(null);
    const { setNodeRef } = useDroppable({ id: "empty-avatar" });


    const handleSwitchChange = (event, newValue) => {
        dispatch({ type: ActionKind.SET_DISPLAY_LABELS, payload: newValue });
    };

    const handleAutoSaveChange = () => {
        dispatch({ type: ActionKind.SET_AUTOSAVE, payload: !state.autoSave });
    }

    const openDeleteDialog = () => {
        dispatch({ type: ActionKind.SET_DELETEDIALOGOPEN, payload: true });
    }

    const handleDeleteDialogClose = (result: boolean) => {
        dispatch({ type: ActionKind.SET_DELETEDIALOGOPEN, payload: false });
        if (result) {
            window.localStorage.removeItem(state.draft.id);
            dispatch({ type: ActionKind.SET_REDIRECT, payload: true });
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch({ type: ActionKind.SET_TAB, payload: newValue });
    };

    const handleSaveClick = async (e: any) => {
        await createNoToAllRules();
        setIsButtonClicked(true);
    }

    const TabPanel = (tabProps) => {
        const { children, value, index, ...other } = tabProps;
        return (
            <div {...other}>
                {value === index && <Box p={3}>{children}</Box>}
            </div>
        );
    }

    const findContainer = (id) => {
        if (id in items) {
            return id;
        }

        const item = Object.keys(items).find((key) => items[key]?.map((item) => item.id).includes(id));

        return item;
    };

    const findCard = (id) => {
        const array = ([] as Item[]).concat(...Object.values(items));
        array?.forEach((card) => (cardsList[card?.id] = card?.blocks));

        if (id in cardsList) {
            return id;
        }
        return Object.keys(cardsList).find((cardid) => cardsList[cardid].map((item) => item.id).includes(id));
    };

    const handleDragStart = (result) => {

        if (result.active.data.current.type === CARD) {
            const container = findContainer(result.active.id);
            setActiveContainer(container);
            const idx = items[container].findIndex((item) => item.id === result.active.id);
            setActiveItem(items[container][idx]);
        }
        if (result.active.data.current.type === BLOCK) {
            const cardId = findCard(result.active.id);
            setActiveContainer(result.active.data.current.container);
            const idx = cardsList[cardId].findIndex((item) => item.id === result.active.id);
            setActiveBlock(cardsList[cardId][idx]);
        }
    };

    const handleDragOver = (result) => {
        const { active, over } = result;

        const overId = over?.id;

        if (overId == null || active.id in items) {
            return;
        }

        if (result.active.data.current.type === CARD) {
            // if the draggable type is card move the card to the new container
            const overContainer = findContainer(overId);
            const activeContainer = findContainer(active.id);

            if (!overContainer || !activeContainer) {
                return;
            }

            if (activeContainer !== overContainer) {
                setItems((items) => {
                    const activeItems = items[activeContainer];
                    const overItems = items[overContainer];

                    const overIndex = overItems.findIndex((item) => item.id === overId);
                    const activeIndex = activeItems.findIndex((item) => item.id === active.id);

                    let newIndex;
                    if (overId in items) {
                        newIndex = overItems.length + 1;
                    } else {

                        const isBelowOverItem =
                            over &&
                            active.rect.current.translated &&
                            active.rect.current.translated.top > over.rect.top + over.rect.height;

                        const modifier = isBelowOverItem ? 1 : 0;
                        newIndex = overIndex > 0 ? overIndex + modifier : overItems.length + 1;
                    }

                    return {
                        ...items,
                        [activeContainer]: (items[activeContainer]).filter((item) => item.id !== active.id),
                        [overContainer]: [
                            ...items[overContainer].slice(0, newIndex),
                            items[activeContainer][activeIndex],
                            ...items[overContainer].slice(newIndex, items[overContainer].length),
                        ],
                    };
                });
            }
        } else if (result.active.data.current.type === BLOCK) {
            // if the draggable type is block move the block to the new card
            const overCard = findCard(overId);
            const activeCard = findCard(active.id);

            if (!overCard || !activeCard) {
                return;
            }

            const activeContainer = result.active.data.current.container;
            const overContainer = result.over.data.current.container;

            if (!overContainer)
                return;

            if (overCard !== activeCard) {

                const activeBlocks = cardsList[activeCard];
                const overBlocks = cardsList[overCard];

                const overIndex = overBlocks.findIndex((item) => item.id === overId);
                const activeIndex = activeBlocks.findIndex((item) => item.id === active.id);

                let newIndex;
                if (overId in cardsList) {
                    newIndex = overBlocks.length;
                } else {
                    const isRightOfOverItem =
                        over &&
                        active.rect.current.translated &&
                        active.rect.current.translated.left > over.rect.right + over.rect.width;

                    const modifier = isRightOfOverItem ? 1 : 0;

                    newIndex = overIndex > 0 ? overIndex + modifier : overBlocks.length + 1;

                }

                const newCardsList = {
                    ...cardsList,
                    [activeCard]: cardsList[activeCard].filter((item) => item.id !== active.id),
                    [overCard]: [
                        ...cardsList[overCard].slice(0, newIndex),
                        cardsList[activeCard][activeIndex],
                        ...cardsList[overCard].slice(newIndex, cardsList[overCard].length),
                    ],
                };

                const newItems = { ...items };

                const newActiveContainer = newItems[activeContainer]?.map((card) => {
                    if (card.id === activeCard) {
                        card.blocks = newCardsList[activeCard];
                    }
                    return card;
                });

                const newOverContainer = newItems[overContainer]?.map((card) => {
                    if (card.id === overCard) {
                        card.blocks = newCardsList[overCard];
                    }
                    return card;
                });

                newItems[activeContainer] = newActiveContainer;
                newItems[overContainer] = newOverContainer;
                setItems(newItems);
            }
        }
    };

    const moveCards = (activeId, overId, type) => {
        // Logic to reorder within the same container

        if (type !== CARD)
            return;

        const activeContainer = findContainer(activeId);

        if (!activeContainer) {
            setActiveItem(null);
            return;
        }

        if (overId == null) {
            setActiveItem(null);
            return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
            const activeIndex = items[activeContainer].findIndex((item) => item.id === activeId);
            const overIndex = items[overContainer].findIndex((item) => item.id === overId);

            if (activeIndex !== overIndex) {
                setItems((items) => ({
                    ...items,
                    [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
                }));
            }
        }

        const item = { ...activeItem.blocks[0].data };
        addComponent(item);

    };


    const addComponent = (component) => {

        if (state.components[component.id])
            return;

        let newComponent = { ...component };
        newComponent = initComponent(newComponent);
        newComponent = handleCreateCompositeComponent(newComponent);
        const newComponents = { ...state.components };
        newComponents[newComponent.id] = newComponent;
        dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });
    }


    // Helper function to find the index of a block within a card
    const findBlockIndex = (cardId, blockId) => cardsList[cardId].findIndex((item) => item.id === blockId);

    // Helper function to move a block within a card
    const moveBlockInCard = (cardId, fromIndex, toIndex) => arrayMove(cardsList[cardId], fromIndex, toIndex);

    const moveBlocks = (activeId, overId, type, overContainer) => {

        if (type !== BLOCK || overId == null || overContainer == null) {
            setActiveBlock(null);
            return;
        }

        const activeCard = findCard(activeId);
        const overCard = findCard(overId);

        if (!activeCard || !overCard) {
            setActiveBlock(null);
            return;
        }

        const activeIndex = findBlockIndex(activeCard, activeId);
        const overIndex = findBlockIndex(overCard, overId);

        // Perform the move operation only if both indices are found
        if (activeIndex !== -1 && overIndex !== -1) {
            const updatedBlocks = moveBlockInCard(overCard, activeIndex, overIndex);

            // Update the state with the new blocks arrangement
            setItems((prevItems) => ({
                ...prevItems,
                [overContainer]: prevItems[overContainer]?.map((card) => {
                    if (card.id === overCard) {
                        return { ...card, blocks: updatedBlocks };
                    }
                    return card;
                }),
            }));

            // Handle additional component logic if necessary

            if (activeBlock.data) {
                addComponent(activeBlock.data);
            }
        }
    }

    const addCard = () => {
        const cardId = ID.uuid();
        const blockId = ID.uuid();
        const item = { ...activeItem.blocks[0].data };
        item.id = blockId;
        addComponent(item);
        const newItems = { ...items };
        const listB = [...newItems.B];
        listB.push({ id: cardId, text: item.type, blocks: [{ id: blockId, text: item.type, data: { ...item, id: blockId } }] });
        newItems["B"] = listB;
        setItems(newItems);
    };

    const handleDragEnd = (result) => {

        const { active, over } = result;

        if (!over)
            return;

        const type = activeItem ? CARD : BLOCK;

        if (!items?.B?.length && activeItem) {
            addCard();
        } else {
            moveCards(active.id, over?.id, type);
            moveBlocks(active.id, over?.id, type, result.over?.data?.current?.container);
        }

        checkWarnings();
        setRefreshSideBar(true);
        setDropped(true);
        setActiveBlock(null);
        setActiveItem(null);
    };

    const deleteRow = (id) => {
        const newItems = { ...items };
        const index = newItems.B.findIndex((item) => item.id === id);
        const item = newItems.B[index];

        const newComponents = { ...state.components };
        item.blocks.forEach((block) => {
            delete newComponents[block.id];
        });
        dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });

        newItems.B.splice(index, 1);
        setItems(newItems);
        setRefreshSideBar(true);
        checkWarnings();
    }

    const deleteComponent = (id) => {
        const itemId = findCard(id);
        let newItems = { ...items };
        const itemIndex = newItems.B.findIndex((item) => item.id === itemId);
        const blockIndex = newItems.B[itemIndex].blocks.findIndex((block) => block.id === id);
        const item = newItems.B[itemIndex].blocks[blockIndex].data;
        newItems.B[itemIndex].blocks.splice(blockIndex, 1);

        const newComponents = { ...state.components };
        delete newComponents[item.id];
        dispatch({ type: ActionKind.SET_COMPONENTS, payload: newComponents });

        if (item.type === MEDICATIONS || item.type === ALLERGIES || item.type === PROBLEMS)
            newItems = createCardsFromSideBarItems(newItems, newComponents);

        setItems(newItems);
        setRefreshSideBar(true);
        checkWarnings();
    }

    const checkWarnings = () => {
        const newWarnings = [];
        items.B.forEach((item) => {
            if (item.blocks.some(x => x.text === HEADER) && item.blocks.length > 1) {
                ShowWarning('Headers should be the only item in a row');
                newWarnings.push(item.id);
            }
            if (item.blocks.some(x => x.text === SUBHEADER)) {
                if (item.blocks.length > 1) {
                    if (item.blocks[1].data.type != BUTTON) {
                        ShowWarning('Section should be the only item in a row');
                        newWarnings.push(item.id);
                    }
                } else if (subShowSectionAlert) {
                    ShowSuccess('Please note that sections create a visual grouping of all rows below them until a new section is found.', 10000);
                    setShowSectionAlert(false);
                }
            }
        });
        setWarnings(newWarnings);
    }

    const handleDragCancel = () => {
        setActiveItem(null);
        setActiveBlock(null);
        let newItems = { ...items };
        newItems = createCardsFromSideBarItems(newItems, state.components);
        setItems(newItems);
    };

    const hasComponent = (components, type: string) => {
        if (!components)
            return false;

        return Object.keys(components)?.some(id => components[id]?.type === type);
    }

    const createCardsFromSideBarItems = (newItems, components) => {

        const copiedSidebarItems = { ...SIDEBAR_ITEMS };

        copiedSidebarItems.OTHER = copiedSidebarItems.OTHER.filter(x => (x.component.type !== MEDICATIONS || !hasComponent(components, MEDICATIONS))
            && (x.component.type !== PROBLEMS || !hasComponent(components, PROBLEMS))
            && (x.component.type !== ALLERGIES || !hasComponent(components, ALLERGIES))
            && (x.component.type !== PROBLEMSPMS || (!hasComponent(components, PROBLEMSPMS) && state.pmsSystemVm?.diseaseFormFields?.length))
            && (x.component.type !== ALLERGIESPMS || (!hasComponent(components, ALLERGIESPMS) && state.pmsSystemVm?.allergyFormFields?.length)));


        const listA = [];

        copiedSidebarItems.QUESTIONS.forEach((item) => {
            const newId = ID.uuid();
            listA.push({ id: ID.uuid(), text: "QUESTIONS", blocks: [] });
            listA[listA.length - 1].blocks.push({ id: newId, text: item.component.type, data: { ...item, id: newId, ...item.component } });
        });

        // todo: group constants better and clean this up
        copiedSidebarItems.OTHER.forEach((item) => {
            const newId = ID.uuid();
            listA.push({ id: ID.uuid(), text: "OTHER-CARDS", blocks: [] });
            listA[listA.length - 1].blocks.push({ id: newId, text: item.component.type, data: { ...item, id: newId, ...item.component } });
        });

        copiedSidebarItems.CONTENT.forEach((item) => {
            const newId = ID.uuid();
            listA.push({ id: ID.uuid(), text: "CONTENT-CARDS", blocks: [] });
            listA[listA.length - 1].blocks.push({ id: newId, text: item.component.type, data: { ...item, id: newId, ...item.component } });
        });

        newItems.A = listA;
        return newItems;
    }

    const createCardsFromLayout = (newItems, layout, components) => {

        if (!components || Object.keys(components).length === 0)
            return newItems;

        const listB = [];

        layout.forEach((row) => {
            listB.push({ id: row.id, text: row.type, blocks: [] });
            row.children.forEach((column) => {
                column.children.forEach((layoutComponent) => {
                    const component = components[layoutComponent.id];
                    if (component)
                        listB[listB.length - 1].blocks.push({ id: component.id, text: component.type, data: { ...component, id: component.id } });
                });
            });
        });

        newItems.B = listB;
        return newItems;
    }


    useEffect(() => {
        if (props.data.layout && props.data.components) {

            dispatch({ type: ActionKind.SET_DATA, payload: props.data });

            for (const k in props.data.components) {
                if (props.data.components[k].type === SUBHEADER) {
                    setShowSectionAlert(false);
                    break;
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.pmsSystemVm) {


            let newItems = { ...items };
            newItems = createCardsFromSideBarItems(newItems, props.data.components);
            if (props.data) {
                newItems = createCardsFromLayout(newItems, props.data.layout, props.data.components);
            }
            setItems(newItems);
        }

    }, [state.pmsSystemVm]);


    useEffect(() => {
        //do not save experiment(draft) data on initial load
        if (state.useEffectCount > 1) {
            saveExperimentData(state.draft.id, { layout: state.layout, components: state.components, rules: state.rules, displayLabels: state.displayLabels });
        } else {
            dispatch({ type: ActionKind.SET_USEEFFECTCOUNT, payload: state.useEffectCount + 1 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.draft.id, state.layout, state.components, state.rules, saveExperimentData]);


    useEffect(() => {
        if (!refreshSideBar)
            return;

        let newItems = { ...items };
        newItems = createCardsFromSideBarItems(newItems, state.components);
        setItems(newItems);
        setRefreshSideBar(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshSideBar]);

    useEffect(() => {
        if (isButtonClicked) {
            props.onSave({ layout: state.layout, components: state.components, rules: state.rules, displayLabels: state.displayLabels, pdf: state.pdf }, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isButtonClicked]);

    useEffect(() => {

        if (items.B.length === 0)
            return;

        const newLayout = [];

        items.B.forEach((item) => {
            newLayout.push({ id: item.id, type: ROW, children: [{ id: ID.uuid(), type: COLUMN, children: [] }] });
            item.blocks.forEach((block) => {
                newLayout[newLayout.length - 1].children[0].children.push({ id: block.id, type: COMPONENT });
            });
        });

        dispatch({ type: ActionKind.SET_LAYOUT, payload: newLayout });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {

        if (items.B.length === 0 || !dropped)
            return;

        //look for any cards that do not have blocks and remove them
        if (items.B.some(x => x.blocks.length === 0)) {
            const newItems = { ...items };
            newItems.B = newItems.B.filter(x => x.blocks.length > 0);
            setItems(newItems);
        }

        setDropped(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropped]);


    const handler = ({ nativeEvent }: React.MouseEvent | React.TouchEvent) => {
        let cur = nativeEvent.target as HTMLElement;

        while (cur) {
            if (cur.dataset && cur.dataset.noDnd) {
                return false;
            }
            cur = cur.parentElement as HTMLElement;
        }

        return true;
    };

    class MouseSensor extends LibMouseSensor {
        static activators = [{ eventName: 'onMouseDown', handler }] as typeof LibMouseSensor['activators'];
    }

    class TouchSensor extends LibTouchSensor {
        static activators = [{ eventName: 'onTouchStart', handler }] as typeof LibTouchSensor['activators'];
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    if (state.redirect) return <Redirect to={`/forms/settings/${props.formData.id}${search}`} />;

    return (
        <>
            <DndContext
                sensors={sensors}
                collisionDetection={rectIntersection}
                onDragOver={handleDragOver}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}>

                <RWDrawer className={classes.sideBar}>
                    <DrawerSection>
                        <Tabs value={state.tab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Questions" classes={{ root: classes.tab }} />
                            <Tab label="Groups" classes={{ root: classes.tab }} />
                        </Tabs>

                        <div className={classes.toolBarItems}>
                            <TabPanel value={state.tab} index={0}>
                                <Typography variant="subtitle2">
                                    QUESTIONS
                                </Typography>
                                {items["A"].filter(x => x.text === "QUESTIONS").map((item) => (
                                    <DraggableRow block={item.blocks[0]} container={item.id} key={item.id}></DraggableRow>
                                ))}
                                <Typography variant="subtitle2" className={classes.otherSubtitle}>
                                    LAYOUT
                                </Typography>
                                {items["A"].filter(x => x.text === "CONTENT-CARDS").map((item) => (
                                    <DraggableRow block={item.blocks[0]} container={item.id} key={item.id}></DraggableRow>
                                ))}
                            </TabPanel>
                            <TabPanel value={state.tab} index={1}>
                                {items["A"].filter(x => x.text === "OTHER-CARDS").map((item) => (
                                    <DraggableRow block={item.blocks[0]} container={item.id} key={item.id}></DraggableRow>
                                ))}
                            </TabPanel>
                        </div>
                    </DrawerSection>
                </RWDrawer>

                <div className={classes.builder}>
                    {props.formData &&

                        <div className={classes.innerPage}>
                            <Box
                                component="div"
                                className={classes.titleBar}>
                                <Box sx={{ margin: '7px 0px 0px 0px' }}>
                                    {state.dirty && <Button color="primary" onClick={openDeleteDialog} ><ArrowBackIcon /> Back</Button>}
                                    {!state.dirty && <Button color="primary" component={Link} to={props.packetId ? `/forms/settings/${props.formData.id}/${props.packetId}${search}` : `/forms/settings/${props.formData.id}${search}`} ><ArrowBackIcon /> Back</Button>}
                                </Box>
                                <Box sx={{ margin: '10px 0px 0px 20px' }}>
                                    <Typography style={{ flex: 1, margin: 0, padding: 0 }} variant="h6">{state.draft.hasDraft ? props.formData.name + ' (Draft)' : props.formData.name}</Typography>
                                </Box>
                                <FormGroup className={classes.autoSave}>
                                    <Tooltip title={state.autoSave ? TOOL_TIP_ON : TOOL_TIP_OFF}>
                                        <FormControlLabel control={<Switch />} label="Auto Save Drafts"
                                            checked={state.autoSave}
                                            onChange={handleAutoSaveChange}
                                        />
                                    </Tooltip>
                                    {state.timeStamp && <Typography>
                                        Last Saved Time: {state.timeStamp}
                                    </Typography>}
                                </FormGroup>
                                <Box sx={{ display: 'flex', gap: '10px', flexGrow: 1, margin: '0px 5px 0px 20px', padding: 0, justifyContent: 'flex-end' }}>
                                    <Button className={classes.buttonCancel} onClick={openDeleteDialog}>Cancel</Button>
                                    <Button className={classes.buttonSuccess} onClick={() => { handleSaveClick(this) }}>Save</Button>
                                </Box>
                            </Box>

                            <div>
                                <Drawer
                                    variant="temporary"
                                    anchor="right"
                                    transitionDuration={{ enter: 500, exit: 500 }}
                                    classes={{ paper: classes.drawerPaper }}
                                    open={state.editMode}
                                    PaperProps={{ elevation: 9 }}
                                    className={classes.drawer}
                                    onClose={editModeOff}
                                >
                                    {state.editMode && state.components &&
                                        <FormElementsEdit />
                                    }
                                </Drawer>
                            </div>

                            <div key="B" className={classes.dnd}>
                                {!items?.B?.length &&
                                    <EmptyRows />
                                }

                                {!!items?.B?.length && state.components &&
                                    <>
                                        <div className={classes.actions}>

                                            <div className={classes.displayLabels}>
                                                <div className={classes.label}>
                                                    <Typography variant="subtitle1" fontSize={17}>
                                                        Display Question Labels
                                                    </Typography>
                                                </div>
                                                <Checkbox
                                                    checked={state.displayLabels}
                                                    onChange={handleSwitchChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />

                                            </div>
                                        </div>
                                        <SortableContext
                                            items={items["B"].map((item) => item.id)}
                                            strategy={verticalListSortingStrategy}>

                                            <Virtuoso
                                                ref={ref}
                                                style={{ height: '95%' }}
                                                followOutput="smooth"
                                                initialTopMostItemIndex={0}
                                                data={items.B}
                                                itemContent={(index, user) => {
                                                    const item = items.B[index];
                                                    return (<SortableRow item={item}
                                                        key={item.id}
                                                        container="B"
                                                        deleteRow={deleteRow}
                                                        warning={warnings?.filter((warning) => warning === item.id).length === 1}
                                                        siblingCount={item.blocks.length}
                                                        deleteComponent={deleteComponent}
                                                        googleMapApiKey={props.formData.googleMapApiKey}
                                                        displayLabel={state.displayLabels} />);
                                                }}
                                            />
                                            <style>{`html, body, #root { height: 100% }`}</style>
                                        </SortableContext>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>

                <DragOverlay>
                    {activeContainer == 'A' && activeItem &&
                        <DraggableRow block={activeItem.blocks[0]} container="A" key={activeItem.id}></DraggableRow>
                    }
                    {activeContainer == 'A' && activeBlock &&
                        <DraggableField block={activeBlock} container="A" key={activeBlock.id}></DraggableField>
                    }
                    {activeContainer == 'B' && activeItem &&
                        <SortableRow
                            item={activeItem}
                            key={activeItem.id}
                            container="B"
                            displayLabel={state.displayLabels}
                            googleMapApiKey={props.formData.googleMapApiKey}
                            deleteComponent={deleteComponent}
                            siblingCount={activeItem.blocks.length}
                            deleteRow={deleteRow}
                            warning={warnings?.filter((warning) => warning === activeItem.id).length === 1} />
                    }
                    {activeContainer == 'B' && activeBlock &&
                        <SortableField
                            block={activeBlock}
                            container="B"
                            key={activeBlock?.id}
                            siblingCount={1}
                            deleteComponent={deleteComponent}
                            googleMapApiKey={props.formData.googleMapApiKey}
                            disabled={false}
                            displayLabel={state.displayLabels}
                            setIsOverField={() => { return; }}
                        />
                    }
                </DragOverlay>

                <ConfirmationDialog
                    open={state.deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    okbuttontext="Leave"
                    title="Leave without Saving"
                    text={"Are you sure you want to leave without saving your changes? Any changes, including auto saved changes, will be lost."}
                />
            </DndContext>
        </>
    );



};


export default Container;



export const ContainerSkeleton: React.FC<any> = (props) => {
    const classes = useStyles();

    return (
        <>
            <RWDrawer className={classes.sideBar}>
                <Grid container direction="column" justifyContent="space-between" alignItems="center" style={{ flexWrap: 'nowrap' }}>
                    <Skeleton variant="rectangular" width={290} height={20} style={{ marginBottom: 50, marginTop: 45, flexShrink: 0 }} />
                    {[...Array(20)].map((row, i) => {
                        return (
                            <Skeleton key={i} variant="rectangular" width={260} height={40} style={{ marginBottom: 10, flexShrink: 0 }} />
                        );
                    })
                    }
                </Grid>
            </RWDrawer>
            <div className={classes.builder}>
                <div className={classes.innerPage}>
                    <Box
                        component="div"
                        m={1}
                        className={classes.titleBar}
                        style={{ height: 35 }}
                    >
                        <Skeleton variant="rectangular" width={60} style={{ marginRight: 170 }} />
                        <Skeleton variant="rectangular" width={250} />
                    </Box>
                </div>
                <Skeleton variant="text" width={200} style={{ marginBottom: 40, marginTop: 30, marginLeft: 50 }} />
                {[...Array(15)].map((row, i) => {
                    return (
                        <RwAccordion key={i} title='' name='' error={false} showSkeleton={true}
                            defaultExpanded={false} style={{ marginLeft: 40, marginRight: 40, marginBottom: 30 }}>
                            <br />
                        </RwAccordion>
                    );
                })
                }
            </div>
        </>
    );
}
