import React, { useContext, useEffect, useState } from 'react';
import { FormSubmissionsClient } from '../api/web-api-client';
import clientService from '../services/clientService';
import useRwSWR from '../hooks/use-rw-swr';
import Keys from '../services/keys';
import { useAppContext } from './app-context';

interface ISubmissionStatsContext {
    unreadSubmissionCount: number;
    setUnreadSubmissionCount?: React.Dispatch<React.SetStateAction<number>>,
    mutateSubmissionCount?: () => void,
}

export const defaultState = {
    unreadSubmissionCount: 0,
};

const SubmissionStatsContext = React.createContext<ISubmissionStatsContext>(defaultState);

export const useSubmissionStatsContext = () => useContext(SubmissionStatsContext);

export const SubmissionStatsContextProvider: React.FC = ({ children }) => {
    const { unwrapperedDomain } = useAppContext();
    const client = clientService.getFormsClient(FormSubmissionsClient, { apiPath: unwrapperedDomain });
    const [unreadSubmissionCount, setUnreadSubmissionCount] = useState(defaultState.unreadSubmissionCount);


    const submissionsCountFetcher = async () => {
        try {
            return await client.getUnreadSubmissionsCount(null);
        } catch (e) {
            return 0;
        }
    }
    const { data, mutate: mutateSubmissionCount } = useRwSWR([Keys.ApiFormSubmission + '/count'], (url) => submissionsCountFetcher(), { refreshInterval: 300000 });


    useEffect(() => {
        if (data) {
            setUnreadSubmissionCount(data);
        }
    }, [data]);


    return (
        <SubmissionStatsContext.Provider
            value={{
                unreadSubmissionCount,
                setUnreadSubmissionCount,
                mutateSubmissionCount,
            }}
        >
            {children}
        </SubmissionStatsContext.Provider>
    );
};
