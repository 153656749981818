// eslint-disable-next-line max-classes-per-file
import DatePicker from './date-picker';
import Dropdown from './dropdown';
import TextInput from './textinput';
import TextArea from './textarea';
import RadioButtons from './radio-buttons';
import SwitchInput from './switchinput';
import Header from './header';
import SubHeader from './sub-header';
import Paragraph from './paragraph';
import Label from './label';
import Checkboxes from './checkbox';
import Signature from './signature';
import Medications from './medications';
import Allergies from './allergies';
import PmsList from './pms-list';
import Address from './address';
import Button from './button';
import Attachment from './attachment';
import Problems from './problems';

const FormElements = {};

FormElements.Header = Header;
FormElements.SubHeader = SubHeader;
FormElements.Paragraph = Paragraph;
FormElements.Label = Label;
FormElements.TextInput = TextInput;
FormElements.TextArea = TextArea;
FormElements.Dropdown = Dropdown;
FormElements.Signature = Signature;
FormElements.Checkbox = Checkboxes;
FormElements.DatePicker = DatePicker;
FormElements.RadioButtons = RadioButtons;
FormElements.SwitchInput = SwitchInput;
FormElements.Button = Button;

FormElements.Medications = Medications;
FormElements.Allergies = Allergies;
FormElements.AllergiesPms = PmsList;
FormElements.Address = Address;
FormElements.Attachment = Attachment;
FormElements.Problems = Problems;
FormElements.ProblemsPms = PmsList;

export default FormElements;