import React, { lazy, Suspense } from 'react';
import { FormSkeleton } from '../../forms/shared/skeleton';


export const PatientsSubmissionCreateSkeleton: React.FC = () => {
    return (<FormSkeleton />);
};

const PatientsSubmissionCreate = lazy(() => import(/* webpackChunkName: "patients-forms-create" */'./create'));

const LazyPatientFormsCreate: React.FC = () => {
    return (
        <Suspense fallback={<PatientsSubmissionCreateSkeleton />}>
            <PatientsSubmissionCreate />
        </Suspense>
    );
};

export const SubmissionCreateModule =
{
    routeProps: {
        path: '/patients/forms/create/:id/:assignmentId?',
        component: LazyPatientFormsCreate,
        private: true
    },
    name: 'Create Submission',
};