import React, { useState } from 'react';
import { Box, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';
import { ArrowBack } from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLayout } from './layout';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { MenuFocus } from './types';

export interface ITitleButtonProps {
    text: string;
    alternateText?: string;
    action: string;
    onClick?: () => void;
    disableButton?: boolean;
    toolTip?: string;
}

export interface IContentTitleProps {
   title?: string;
   className?: string;
    buttons?: ITitleButtonProps[];
    backButton?: ITitleButtonProps;
    loading?: boolean;
    overrideElement?: React.ReactElement;
}

const useStyles = makeStyles((theme) => ({
    contentTitle: {
        width: "auto",
        '& > :last-child': {
            marginRight: "90px"
        }
    },
    contentButton: {
        marginLeft: "5px"
    },
    backButtonText: {
        paddingTop: "2px"
    }

}));

export function ContentTitle(props: IContentTitleProps) {
    const classes = useStyles();
    const { search } = useLocation();
    const { menuFocus, menuItems, setMenuFocus } = useLayout();

    const onBackClick = () => {
        const currentIndex = menuItems.indexOf(menuFocus);
        const newIndex = currentIndex === 0 ? 0 : currentIndex - 1;
        setMenuFocus(menuItems[newIndex] as MenuFocus);
    }

    const buttonId = (text: string) => {
        return text.replace(/\s/g, '-').toLowerCase();
    }
    const makeButton = (button: ITitleButtonProps) => {
        if (props.loading)
            return <Skeleton variant="rectangular" width={100} height={40} key={button.text} />;

        let buttonEl: JSX.Element;

        if (button.onClick != null) {
            buttonEl = (
                <Button id={buttonId(button.text)} className={classes.contentButton} key={button.text} onClick={button.onClick} disabled={button.disableButton != null && button.disableButton ? true : false}
                    variant="contained" color="primary">{button.text}
                </Button>
            );
        } else {
            buttonEl = (
                <Button id={buttonId(button.text)} className={classes.contentButton} component={Link} to={button.action + search} key={button.text}
                    variant="contained" color="primary" disabled={button.disableButton != null && button.disableButton ? true : false}>{button.text}
                </Button>
            );
        }
        if (button.toolTip) {
            buttonEl = (
                <Tooltip key={button.text} title={button.toolTip} placement="bottom" arrow>
                    <span>
                        {buttonEl}
                    </span>
                </Tooltip>
            );
        }

        return buttonEl;
    }
        
    const makeMenuItem = (item:ITitleButtonProps) => {
        if (item.onClick != null) {
            return (
                (<MenuItem key={item.alternateText ?? item.text} onClick={item.onClick} >{item.alternateText ?? item.text}</MenuItem>)
                )
        }
        return (<MenuItem component={Link} to={item.action} key={item.alternateText ?? item.text}>{item.alternateText ?? item.text}</MenuItem>)
    }
    
    const ContentActionsMenu = () => {
        const [titleAnchorEl, setTitleAnchorEl] = useState(null);

        const handleClick = (event) => {
            setTitleAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setTitleAnchorEl(null);
        };
        return (
            <Grid item>
                <Box display={{ xs: 'none', sm: 'none', lg: 'flex' }}>
                    {props.buttons.map(makeButton, this)}
                </Box>
                <Box display={{ xs: 'block', sm: 'block', lg: 'none' }}>                
                    <IconButton
                        aria-controls="content-title-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        size="large">
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="content-title-menu"
                        anchorEl={titleAnchorEl}
                        keepMounted
                        open={Boolean(titleAnchorEl)}
                        onClose={handleClose}
                    >
                        <Box display={{ xs: 'block', sm: 'block', lg: 'none' }}>
                            {props.buttons.map(makeMenuItem, this)}
                        </Box>
                    </Menu>
                </Box>
            </Grid>
        );
    }

    return (
        <Grid container justifyContent={props.overrideElement ? "flex-start" : "space-between"} wrap='nowrap' className={props.overrideElement ? "content-title" : `${classes.contentTitle} content-title ${props.className ? props.className : ""}`}>
            <IconButton className="button-back" onClick={onBackClick} size="large">
                <ArrowBack />
            </IconButton>
            {props.backButton && !props.loading &&
                <Button className="button-back-details" color="primary" onClick={props.backButton.onClick} >
                <ArrowBack /> <div className={classes.backButtonText}>{props.backButton.text}</div>
                </Button>
            }
            {props.title && !props.loading &&
                <Typography style={{flex: 1}} variant="h6">
                    {props.title}
                </Typography>}
            {props.overrideElement &&
                <>
                    {props.overrideElement}
                </>
            }
            {props.title && props.loading &&
                <div style={{ flex: 1 }}>
                <Skeleton variant="text" width={180} height={45} />
                </div>}
            {props.buttons && <ContentActionsMenu />}
            
        </Grid>
    );
}
