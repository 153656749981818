import React from "react";
import { Icons } from "../shared/icons";
import { Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    toolBarItemText: {
        color: theme.custom.listItem,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '24px',
        margin: 0
    },
    toolBarItemIcon: {
        color: theme.custom.listItem,
    }
}));

const SideBarItem: React.FC<any> = props => {
    const classes = useStyles();
    const { field } = props;

    if (!field || !field.component)
        return <></>;

    return (
        <>
            <ListItemIcon className={classes.toolBarItemIcon}>
                <Icons type={field.component.type} />
            </ListItemIcon>
            <ListItemText className={classes.toolBarItemText} inset={false} disableTypography={true}
                primary={<Typography variant="subtitle1" className={classes.toolBarItemText}> {field.component.typeDisplay}</Typography>} />
        </>
    );
};

const SideBarLayoutItem = ({ data }) => {
    return <SideBarItem data={data} />;
};

export { SideBarItem, SideBarLayoutItem };

