import React from 'react';
import IComponent from '../../../../services/forms.interface';
import myxss from './myxss';
import Typography from '@mui/material/Typography'

const Label: React.FC<IComponent> = props => {

    let baseClasses = 'item SortableItem rfb-item';
    if (props.selected) { baseClasses += ' selected'; }
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    

    return (
        <div className={baseClasses}>
            <Typography variant="subtitle1" fontSize={17}>
                    {myxss.process(props.content)}
            </Typography>
        </div>
    );
}

export default Label;