import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, FormControl, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import IComponent from '../../../../services/forms.interface';
import classNames from 'classnames';
import { ALLERGIESPMS, SWITCHINPUT } from '../constants';

interface ICustomProps {
    name: string;
    type: string;
    value: string;
    defaultValue: string;
    disabled: boolean;
    read_only: string;
    required: boolean;
    maskKey: string;
    error: any;
    onChange: any;
    onBlur: any;
    label: string;
    key: string;
    buttonDisabled: string;
}

interface IPmsListItemProps {
    id: string;
    parentId: string;
    label: string;
    isMobile?: boolean;
    disabled: boolean;
    handleChange: (event: any, id: string, value: string, parentId: string) => void;
    index: number;
    value?: string;
    error?: string;
    required?: boolean;
    childComponents?: Map<string, IComponent>;
}

const useStyles = makeStyles((theme) => ({
    pmsListContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        border: '1px solid #ccc',
        borderRadius: '7px',
        paddingBottom: '20px',
        backgroundColor: '#fff',
        marginBottom: '10px',
    },
    subHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #ccc',
        padding: '10px',
        color: '#1C3D64',
        marginBottom: '15px',
        paddingLeft: '20px',
    },
    pmsListItem: {
        paddingLeft: '35px',
    },
    addIcon: {
        width: '30px',
    },
    addDiv: {
        flexBasis: '30px',
        minWidth: '30px',
        jusifyContent: 'flex-end'
    },
    textFieldName: {
        flexBasis: '100%',
        marginRight: '5px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        flexBasis: '100%',
    },
    column1: {
        display: 'flex',
        flexBasis: '50%',
        flexDirection: 'column',
        marginTop: '5px',

    },
    button: {
        backgroundColor: '#F9F9F9',
        outlineColor: 'rgba(0, 0, 0, 0.23)',
        outlineWidth: '1px',
        outlineStyle: 'solid',
        margin: '1px',
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        '&.error': {
            color: theme.palette.error.main,
            outlineColor: theme.palette.error.main,
        }
    },
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        flexBasis: '100%',
    },
    textField: {
        minWidth: '300px',
        marginLeft: '5px'
    },
    mobileTextField: {
        minWidth: '300px',
        marginTop: '5px'
    },
    mobile: {
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    label: {
        marginRight: '20px'
    }
}));


const PmsListItem: React.FC<IPmsListItemProps> = props => {
    const classes = useStyles({ isMobile: props.isMobile });
    const switchValue = props.value ? props.value : '';
    const childError = props.childComponents && props.childComponents[props.id].hasOwnProperty('error') ? true : false;

    const handleChange = (event, newValue) => {
        props.handleChange(event, props.id, newValue, props.parentId);
    };

    return (
        <FormControl component="fieldset" className={`${classes.formControl} ${props.isMobile ? classes.mobile : ''}`} required={props.required}>
            <div className={classes.column1}>
                <Typography variant="subtitle1" fontSize={17}>
                    {props.label.split(':')[1]}
                </Typography>
            </div>
            <div className={classes.column1}>
                <ToggleButtonGroup value={switchValue}
                    exclusive
                    id={props.id}
                    size="small"
                    disabled={props.disabled}
                    onChange={handleChange}
                >
                    <ToggleButton value="true" className={classNames(classes.button, { error: switchValue === '' && childError })}>Yes</ToggleButton>,
                    <ToggleButton value="false" className={classNames(classes.button, { error: switchValue === '' && childError })}>No</ToggleButton>,
                </ToggleButtonGroup>
            </div>
        </FormControl>
    )
}

const PmsList: React.FC<IComponent> = props => {
    const classes = useStyles({ isMobile: props.isMobile });
    const [list, setList] = useState(null);
    const [ids, setIds] = useState([]);

    const localProps: ICustomProps = {} as ICustomProps;
    localProps.type = 'text';

    localProps.required = (props.hasOwnProperty('required') && props.required === true && !props.read_only);

    if (props.error) { localProps.error = true; }

    if (props.disabled) {
        localProps.disabled = true;
        localProps.buttonDisabled = 'disabled';
    }
        
    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }

    const handleNoToAllClick = (event) => {
        ids.forEach((id) => {
            props.handleChange(event, id, 'false', props.id);
        }); 
    };

    const createRows = (fields) => {
       
        if (fields?.length === 0) return;

        setIds(fields.map(x => x.id));

        const length = fields?.length;
        const half = Math.floor(length / 2);

        // create two lists of equal length from fields 
        const firstHalfFields = fields?.slice(0, half);
        const secondHalfFields = fields?.slice(half);
     
        const list = 
            <div className={classes.row}>
                <div className={classes.column1}>
                    {firstHalfFields.map((field, index) => 
                        <PmsListItem key={field.id} id={field.id} childComponents={props.childComponents} {...localProps} index={index} label={field.display ? field.display : field.label} value={field.value} parentId={props.id} handleChange={props.handleChange} />
                    )}
                </div>
                <div className={classes.column1}>
                    {secondHalfFields.map((field, index) => 
                        <PmsListItem key={field.id} id={field.id} childComponents={props.childComponents} {...localProps} index={index} label={field.display ? field.display : field.label} value={field.value} parentId={props.id} handleChange={props.handleChange} />
                    )}
                </div>
            </div>;

        setList(list);
    }

    useEffect(() => {

        if (list?.length || !props.pmsSystemVm)
            return;

        const fields = props.type === ALLERGIESPMS ? props.pmsSystemVm.allergyFormFields : props.pmsSystemVm.diseaseFormFields;

        if(fields !== undefined)
            createRows(fields.filter(x => x.htmlType === SWITCHINPUT));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pmsSystemVm]);

    useEffect(() => {

        if (!props.childComponents)
            return;

        createRows(Object.values(props.childComponents));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    if (!list)
        return <></>;

    return (
        <div className={baseClasses}>
            <div className={classes.pmsListContainer}>
                <div className={classes.subHeader}>
                    <Typography variant={'h6'}>
                        {props.label}
                    </Typography>
                    <Button onClick={handleNoToAllClick} variant="outlined" disabled={localProps.disabled}>No to All</Button>
                </div>
                <div className={classes.pmsListItem}>
                    {list}
                </div>
            </div>
        </div>
    );
}

export default PmsList;



