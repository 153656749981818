import React, { lazy, Suspense, useEffect } from 'react';
import { useAppContext } from '../../../context/app-context';
import { ContainerSkeleton } from '../edit/container';


export const FormImportSkeleton: React.FC = () => {
    const { setDrawerVisible } = useAppContext();

    useEffect(() => {
        setDrawerVisible(false);
    }, [setDrawerVisible]);

    return <ContainerSkeleton />;
};

const EditForm = lazy(() => import(/* webpackChunkName: "forms-edit" */ '../edit'));

const LazyEditFormImport: React.FC = () => {
    return (
        <Suspense fallback={<FormImportSkeleton />}>
            <EditForm />
        </Suspense>
    );
};

export const EditFormModule =
{
    routeProps: {
        path: '/forms/edit/:id/:packetId?',
        component: LazyEditFormImport,
        private: true
    },
    name: 'Edit Form',
};