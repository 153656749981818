import React from 'react';

/* eslint-disable-next-line */
export interface IDrawerSectionProps {
    title?: string;
}

export function DrawerSection(props: React.PropsWithChildren<IDrawerSectionProps>) {
    return (
        <div className="drawer-section">
            <div className="section-title">{props.title}</div>
            {props.children}
        </div>
    );
}

