import React, { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import IComponent from '../../../../services/forms.interface';
import SignatureCanvas from 'react-signature-canvas'
import { FormControl, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import GestureIcon from '@mui/icons-material/Gesture';
import RwAlert from '../../../shared/RwAlert';

interface IPadProps {
    defaultValue: string;
    type: string;
    ref: any;
    value: ISignature;
}

interface ISignature {
    signature: string;
    date: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        flexDirection: 'column',
        maxWidth: '570px',
        width: '80vw',
        alignSelf: 'center',
        justifyContent: 'center',
        alignContent: 'center',
    },
    bottombar: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        marginBottom: '20px',
        width: 'calc(100% - 40px)',
        marginLeft: '20px',
        marginRight: '20px',
    },
    draw: {
        width: 'calc(100% - 40px)',
        marginLeft: '20px',
        marginRight: '20px',
        maxHeight: '150px',
    },
    type: {
        width: 'calc(100% - 40px)',
        marginLeft: '20px',
        marginRight: '20px',
    },
    typeDraw: {
        textAlign: 'right',
        marginBottom: '10px',
        color: theme.palette.text.secondary,
    },
    label: {
        backgroundColor: theme.palette.background.paper,
    },
    input: {
    },
    signatureCanvasControl: {
    },
    signatureCanvas: {
        border: '1px dashed gray',
        borderRadius: '6px',
        width: '100%',
        maxWidth: '530px',
        height: '100%',
        maxHeight: '150px',
    },
    signatureCanvasAction: {
        position: 'absolute',
    },
    signatureCanvasFont: {
        fontFamily: 'Cedarville cursive'
    },
    formControl: {
        width: '100%',
        backgroundColor: '#F9F9F9',
        borderRadius: '4px',
    },
    button: {
        minWidth: '225px',
        minHeight: '40px',
        borderColor: 'currentColor',
        color: 'rgba(0, 0, 0, 0.54)',
        '&.showError': {
            color: theme.palette.error.main,
            border: theme.custom.errorBorder,
        },
    },
    signatureContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        flexBasis: '100%',

    },
    signatureImage: {
        width: '200px',
        height: 'auto',
    },
    signatureDate: {
        marginBottom: '10px',
        marginLeft: '15px',
    },
    signatureContent: {
        backgroundColor: '#FFF',
    },
}));

function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, value, open } = props;
    const [signature, setSignature] = React.useState<ISignature>(value);
    const [typeDraw, setTypeDraw] = React.useState(false);
    const [text, setText] = React.useState('');
    const [showError, setShowError] = React.useState(false);
    const canvasRef = useRef(null);

    let canClear = !!value;
    canClear = !props.read_only;

    const handleClose = () => {
        onClose(signature);
    };

    const handleSignClick = () => {
        if ((typeDraw && !text) || (!typeDraw && canvasRef.current.isEmpty())) {
            setShowError(true);
            return;
        }
        setShowError(false);
        const canvas = canvasRef.current.getCanvas();
        const ctx = canvas.getContext("2d");
        onClose({ signature: ctx.canvas.toDataURL(), date: new Date() });
    };

    const handleTypeDrawClick = () => {
        setTypeDraw(!typeDraw);
    };

    const handleType = e => {
        setText(e);
        const canvas = canvasRef.current.getCanvas();
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = '40px Cedarville cursive';
        ctx.fillText(e, 10, 75);
    };

    const clear = () => {
        setText('');
        if (value) {
            setSignature({ signature: '', date: '' });
            if (canvasRef.current) {
                canvasRef.current.clear();
            }
        }
    }

    const onSigDrawEnd = () => {
        setShowError(false);
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Sign or Type your Signature</DialogTitle>

            <div className={classes.root}>
                <div className={classes.typeDraw}>
                    <Button color="primary" onClick={handleTypeDrawClick}>
                        {!typeDraw ? <Tooltip title="Type Signature"><KeyboardIcon /></Tooltip> : <Tooltip title="Draw Signature"><GestureIcon /></Tooltip>}
                    </Button>
                </div>

                <div className={classes.draw} style={{ height: typeDraw ? '15vh' : '30vh' }} >
                    <SignatureCanvas {...props} penColor="#0d47a1" ref={canvasRef}
                        canvasProps={{ className: classNames(classes.signatureCanvas) }}
                        onEnd={onSigDrawEnd}
                    />
                </div>
                {typeDraw &&
                    <div className={classes.type}>
                        <TextField
                            value={text}
                            fullWidth
                            variant="outlined"
                            onChange={e => handleType(e.target.value)}
                            className={classes.input}
                        />
                    </div>
                }
                {showError && <RwAlert severity="error">
                    <b>Error:</b> Please fill in a signature.
                    </RwAlert>}
                <div className={classes.bottombar}>
                    {canClear && (
                        <Button variant="outlined" onClick={clear}>
                            Clear
                        </Button>
                    )}
                    <Button variant="outlined" onClick={handleSignClick}>
                        Sign
                    </Button>   
                </div>
            </div>
        </Dialog>
    );
}


SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const Signature: React.FC<IComponent> = props => {

    const classes = useStyles();
    const [value, setValue] = React.useState<ISignature>({signature: '', date: '' });
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setValue(value);
        props.handleChange(event, props.id, value);
    };

    const pad_props = { } as IPadProps;
    if (props.mutable) {
        pad_props.value = value;
    }

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) {baseClasses += ' is-editing'; }
    if (!props.editMode) {baseClasses += ' not-is-editing'; }
    if (props.selected) {baseClasses += ' selected'; }

    return (
        <div className={baseClasses}>
            {props.read_only === 'true' || value.signature &&
                <div className={classes.signatureContainer}>
                    <img src={value.signature} className={classes.signatureImage} />
                    <Typography variant="body2" className={classes.signatureDate} >{value.date.toLocaleString()}</Typography>
                </div>
            }
            {props.read_only === 'false' || !value.signature &&
                <FormControl className={classes.formControl}>
                    <Button variant="outlined" fullWidth className={classNames(classes.button, { showError: props.hasOwnProperty('error') })} onClick={handleClickOpen} disabled={props.disabled}>
                        Signature
                    </Button>
                    <SimpleDialog value={value} open={open} onClose={handleClose} {...pad_props} />
                </FormControl>
            }
        </div>
    );
}

export default Signature;