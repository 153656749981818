import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import MaskedTextInput from './masked-textinput';
import useRwUtils from '../../../../hooks/use-rw-utils';
import { usePlacesWidget } from "react-google-autocomplete";
import IComponent from '../../../../services/forms.interface';

interface ICustomProps {
    name: string;
    type: string;
    value: string;
    defaultValue: string;
    disabled: boolean;
    read_only: string;
    required: boolean;
    maskKey: string;
    error: any;
    onChange: any;
    onBlur: any;
    label: string;

}

interface IAddress {
    address_components: IAddressComponents[];
    formatted_address: string;
}

interface IAddressComponents {
    long_name: string;
    short_name: string;
    types: string[];
}

interface IAddressState {
    address1: IComponent,
    address2: IComponent,
    city: IComponent,
    state: IComponent,
    zip: IComponent
}


const useStyles = makeStyles((theme) => ({
    addressContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '100%',
        flexWrap: 'wrap',
    },
    address1: {
        flexBasis: '100%',
        marginBottom: '.5em'
    },
    address2: {
        flexBasis: '100%',
        marginBottom: '.5em'
    },
    city: {
        flexBasis: (props: any) => props.isMobile ? '100%' : '48%',
        marginBottom: '.5em'
    },
    state: {
        flexBasis: (props: any) => props.isMobile ? '100%' : '20%',
        marginBottom: '.25em'
    },
    zip: {
        flexBasis: (props: any) => props.isMobile ? '100%' : '30%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '100%',
        flexWrap: 'nowrap',
        justifyContent: 'space-between'
    },
    stateInputLabel: {
        '&:not(.MuiInputLabel-shrink)': {
            margin: '-7px 0',
        }
    }
}));

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F9F9F9',
    },
});

const Row = (props: any) => {
    return (
        <React.Fragment>
            {props.isMobile &&
                <div className={props.classes}>
                    {props.children}
                </div >
            }
            {!props.isMobile &&
                props.children
            }
        </React.Fragment>
    );
}

const Address: React.FC<IComponent> = props => {
    const classes = useStyles(props);
    const { usStates } = useRwUtils();
    const [address, setAddress] = useState<IAddressState>();

    const localProps: ICustomProps = {} as ICustomProps;
    localProps.type = 'text';
    localProps.required = (props.hasOwnProperty('required') && props.required === true && !props.read_only);

    if (props.error) { localProps.error = true; }

    if (props.disabled)
        localProps.disabled = true;

    const localAddress2Props = { ...localProps };
    localAddress2Props.required = false;

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }


    const handleChange = (event) => {
        props.handleChange(event, event.target.id, event.target.value, props.id);
    };

    const handleStateChange = (event: SelectChangeEvent) => {
        const state = getComponent('state');
        props.handleChange(event, state.id, event.target.value, props.id);
        
    };

    const getComponent = (type) => {
        let found: any = {};

        if (!props.childComponents)
            return found;

        Object.values(props?.childComponents).forEach((comp: any, i) => {
            let fieldName = comp?.field_name || comp?.field_Name;
            fieldName = fieldName?.toLowerCase();
            if (fieldName.indexOf(type) !== -1)
                found = comp
        });

        if (found.error) { found.error = true; }
        if (!found.value) { found.value = ''; }
        if (props.required) { found.required = true; }

        return found;
    }

    const { ref: materialRef } = usePlacesWidget({
        apiKey: props.googleMapApiKey,
        onPlaceSelected: (place: IAddress) => {

            const address1 = getComponent('street');
            const address2 = getComponent('apt');
            address2.required = false;
            const city = getComponent('city');
            const state = getComponent('state');
            const zip = getComponent('zip');

            const street_number = place.address_components.find(x => x.types.find(t => t === 'street_number'));
            const route = place.address_components.find(x => x.types.find(t => t === 'route'));

            if (address1 && street_number && route)
                props.handleChange(event, address1.id, street_number.short_name + ' ' + route.short_name, props.id);

            const subpremise = place.address_components.find(x => x.types.find(t => t === 'subpremise'));


            if (address2 && subpremise)
                props.handleChange(event, address2.id, subpremise.short_name, props.id);

            const locality = place.address_components.find(x => x.types.find(t => t === 'locality'));

            if (city) {
                if (locality) {
                    props.handleChange(event, city.id, locality.long_name, props.id);
                } else {
                    const subLocality = place.address_components.find(x => x.types.find(t => t === 'sublocality'));
                    props.handleChange(event, city.id, subLocality.long_name, props.id);
                }
            }

            const administrative_area_level_1 = place.address_components.find(x => x.types.find(t => t === 'administrative_area_level_1'));

            if (state && administrative_area_level_1)
                props.handleChange(event, state.id, administrative_area_level_1.short_name, props.id);

            const postal_code = place.address_components.find(x => x.types.find(t => t === 'postal_code'));

            if (zip && postal_code)
                props.handleChange(event, zip.id, postal_code.short_name, props.id);

        },
        inputAutocompleteValue: "address",
        options: {
            types: ['address'],
            componentRestrictions: { country: 'us' }
        },
    });

    useEffect(() => {
        const address1 = getComponent('street');
        const address2 = getComponent('apt');
        address2.required = false;
        const city = getComponent('city');
        const state = getComponent('state');
        const zip = getComponent('zip');

        setAddress({ address1: address1, address2: address2, city: city, state: state, zip: zip });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.childComponents]);


    
    return (
        <div className={baseClasses}>
            {usStates &&
                <div className={classes.addressContainer}>

                    <div className={classes.row}>
                    <StyledTextField variant="outlined" inputRef={materialRef} required={address?.address1.required} error={address?.address1.error && Object.keys(address.address1.error).length > 0}
                        onChange={handleChange}  {...localProps} id={address?.address1?.id} label={props.label} name={address?.address1?.name} className={classes.address1}
                        value={address?.address1?.value || ''} size="small" />
                    </div>

                    {address &&
                        <>
                            <div className={classes.row}>
                            <StyledTextField variant="outlined" {...localAddress2Props}
                            onChange={handleChange} id={address.address2.id} label={address.address2.label} name={address.address2.name} className={classes.address2}
                            value={address.address2.value} size="small" />
                            </div>
                            <Row isMobile={!props.isMobile} classes={classes.row}>
                                <Row isMobile={props.isMobile} classes={classes.row}>
                            <StyledTextField variant="outlined" {...localProps} error={address.city.error && Object.keys(address.city.error).length > 0} required={address.city.required}
                                onChange={handleChange} id={address.city.id} label={address.city.label} name={address.city.name} value={address.city.value} className={classes.city} size="small" />
                                </Row>
                                <Row isMobile={props.isMobile} classes={classes.row}>
                                <FormControl className={classes.state} required={address.state.required}>
                                <InputLabel id="demo-simple-select-label" error={address.state.error && Object.keys(address.state.error).length > 0} className={classes.stateInputLabel}>{address.state.label}</InputLabel>
                                        <Select
                                            {...localProps}
                                            id={props.id}
                                            name={props.field_name}
                                            label={address.state.label}
                                            className={classes.state}
                                            onChange={handleStateChange}
                                            variant='outlined'
                                            size="small"
                                            margin="none"
                                            value={address.state.value}
                                            error={address.state.error && Object.keys(address.state.error).length > 0}
                                            sx={{ backgroundColor: '#F9F9F9' }}
                                            inputProps={{
                                                id: props.id,
                                                autoComplete: 'address-level1'
                                            }}
                                        >
                                            {usStates.map((option) => (
                                                <MenuItem key={`preview_${option.id}`} value={option.id}>{option.id}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Row>
                                <Row isMobile={props.isMobile} classes={classes.row}>
                            <MaskedTextInput id={address.zip.id} name={address.zip.name} value={address.zip.value} error={address.zip.error && Object.keys(address.zip.error).length > 0}
                                required={address.zip.required} onChange={handleChange} className={classes.zip} size="small" maskKey={address.zip.mask} label={address.zip.label} />
                                </Row>
                            </Row>
                        </>
                    }
                </div>
            }
        </div>

    );
}

export default Address;



