import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useMediaQuery } from '@mui/material';


interface IPatientLoginContainer {
    fullWidth?: boolean
}

const PatientLoginContainer: React.FC<IPatientLoginContainer> = (props) => {

    const isMobile = useMediaQuery('(max-width:650px)');
    const isTablet = useMediaQuery('(max-width:960px)');
    const useStyles = makeStyles((theme) => ({
        patientLoginContainer: {
            width: "400px",
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        },
        mobilePatientLoginContainer: {
            width: '90%',
            margin: "5%",

        },
        tabletPatientLoginContainer: {
            width: "80%",
            margin: "5% 10% 5% 10% ",
        },
    }));
    const classes = useStyles();

    return (
        <div className={isMobile || props.fullWidth ? classes.mobilePatientLoginContainer : isTablet ? classes.tabletPatientLoginContainer : classes.patientLoginContainer}>
            {props.children}
        </div>
        );
}
export default PatientLoginContainer
