import React from "react";
import SvgIcon from '@mui/material/SvgIcon';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SelectAllOutlinedIcon from '@mui/icons-material/SelectAllOutlined';
import GrassIcon from '@mui/icons-material/Grass';
import { ROW, COLUMN, ADDRESS, ATTACHMENT, ALLERGIES, PROBLEMS, BUTTON, MEDICATIONS, PARAGRAPH, LABEL, SUBHEADER, HEADER, SIGNATURE, DATEPICKER, TEXTAREA, TEXTINPUT, SWITCHINPUT, RADIOBUTTONS, CHECKBOX, DROPDOWN, LINEBREAK, PROBLEMSPMS, ALLERGIESPMS } from "./constants";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';

export const Icons = (props) => {
    switch (props.type) {
        case DROPDOWN:
            return <SvgIcon viewBox="0 0 24 24">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -113C-47 -121.837 -39.8366 -129 -31 -129H277V772H-31C-39.8366 772 -47 764.837 -47 756V-113Z" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                </g>
                <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                <path d="M17.25 6.55882V17.4706H6.33821V6.55882H17.25ZM17.25 5H6.33821C5.48086 5 4.77939 5.70147 4.77939 6.55882V17.4706C4.77939 18.3279 5.48086 19.0294 6.33821 19.0294H17.25C18.1073 19.0294 18.8088 18.3279 18.8088 17.4706V6.55882C18.8088 5.70147 18.1073 5 17.25 5Z" fill="black" />
                <path d="M15.3716 9.67627L11.7941 13.246L8.21659 9.67627L7.11761 10.7752L11.7941 15.4517L16.4706 10.7752L15.3716 9.67627Z" fill="black" />
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-129" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case CHECKBOX:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -172C-47 -180.837 -39.8366 -188 -31 -188H277V713H-31C-39.8366 713 -47 705.837 -47 697V-172Z" fill="white" />
                    <rect x="-15" y="-11" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-11" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                </g>
                <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                <path d="M17.4444 5H6.55556C5.7 5 5 5.7 5 6.55556V17.4444C5 18.3 5.7 19 6.55556 19H17.4444C18.3 19 19 18.3 19 17.4444V6.55556C19 5.7 18.3 5 17.4444 5ZM17.4444 17.4444H6.55556V6.55556H17.4444V17.4444ZM16.6589 9.66667L15.5622 8.56222L10.4367 13.6878L8.43 11.6889L7.32556 12.7856L10.4367 15.8889L16.6589 9.66667Z" fill="black" />
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-188" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case RADIOBUTTONS:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -232C-47 -240.837 -39.8366 -248 -31 -248H277V653H-31C-39.8366 653 -47 645.837 -47 637V-232Z" fill="white" />
                    <rect x="-15" y="-11" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-11" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                </g>
                <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                <path d="M5.21622 10.8C4.54324 10.8 4 11.336 4 12C4 12.664 4.54324 13.2 5.21622 13.2C5.88919 13.2 6.43243 12.664 6.43243 12C6.43243 11.336 5.88919 10.8 5.21622 10.8ZM5.21622 6C4.54324 6 4 6.536 4 7.2C4 7.864 4.54324 8.4 5.21622 8.4C5.88919 8.4 6.43243 7.864 6.43243 7.2C6.43243 6.536 5.88919 6 5.21622 6ZM5.21622 15.6C4.54324 15.6 4 16.144 4 16.8C4 17.456 4.55135 18 5.21622 18C5.88108 18 6.43243 17.456 6.43243 16.8C6.43243 16.144 5.88919 15.6 5.21622 15.6ZM7.64865 17.6H19V16H7.64865V17.6ZM7.64865 12.8H19V11.2H7.64865V12.8ZM7.64865 6.4V8H19V6.4H7.64865Z" fill="black" />
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-248" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case SWITCHINPUT:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -291C-47 -299.837 -39.8366 -307 -31 -307H277V594H-31C-39.8366 594 -47 586.837 -47 578V-291Z" fill="white" />
                    <rect x="-15" y="-10" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-10" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                </g>
                <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                <path d="M16.0909 7H7.90909C5.20091 7 3 9.24167 3 12C3 14.7583 5.20091 17 7.90909 17H16.0909C18.7991 17 21 14.7583 21 12C21 9.24167 18.7991 7 16.0909 7ZM16.0909 15.3333H7.90909C6.10091 15.3333 4.63636 13.8417 4.63636 12C4.63636 10.1583 6.10091 8.66667 7.90909 8.66667H16.0909C17.8991 8.66667 19.3636 10.1583 19.3636 12C19.3636 13.8417 17.8991 15.3333 16.0909 15.3333ZM7.90909 9.5C6.55091 9.5 5.45455 10.6167 5.45455 12C5.45455 13.3833 6.55091 14.5 7.90909 14.5C9.26727 14.5 10.3636 13.3833 10.3636 12C10.3636 10.6167 9.26727 9.5 7.90909 9.5Z" fill="black" />
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-307" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case TEXTINPUT:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -351C-47 -359.837 -39.8366 -367 -31 -367H277V534H-31C-39.8366 534 -47 526.837 -47 518V-351Z" fill="white" />
                    <rect x="-15" y="-10" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-10" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                </g>
                <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                <path d="M5 16H19V14.4H5V16ZM8.11111 11.2V12.8H15.8889V11.2H8.11111ZM5 8V9.6H19V8H5Z" fill="black" />
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-367" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case TEXTAREA:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -413C-47 -421.837 -39.8366 -429 -31 -429H277V472H-31C-39.8366 472 -47 464.837 -47 456V-413Z" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                    <path d="M8.88889 14.6667V16.1111H16.1111V14.6667H8.88889ZM6 19H19V17.5556H6V19ZM6 13.2222H19V11.7778H6V13.2222ZM8.88889 8.88889V10.3333H16.1111V8.88889H8.88889ZM6 6V7.44444H19V6H6Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-429" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case DATEPICKER:
            return <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -533C-47 -541.837 -39.8366 -549 -31 -549H277V352H-31C-39.8366 352 -47 344.837 -47 336V-533Z" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                    <path d="M17.6727 5.41818H16.9636V4H15.5455V5.41818H8.45454V4H7.03636V5.41818H6.32727C5.54727 5.41818 4.90909 6.05636 4.90909 6.83636V18.1818C4.90909 18.9618 5.54727 19.6 6.32727 19.6H17.6727C18.4527 19.6 19.0909 18.9618 19.0909 18.1818V6.83636C19.0909 6.05636 18.4527 5.41818 17.6727 5.41818ZM17.6727 18.1818H6.32727V10.3818H17.6727V18.1818ZM17.6727 8.96364H6.32727V6.83636H17.6727V8.96364Z" fill="black" />
                    <path d="M9.80889 12.5445C10.1634 12.5445 10.3833 12.7714 10.3833 13.055C10.3833 13.3174 10.284 13.5089 10.0003 13.8067C9.74507 14.0761 9.24871 14.5725 8.48998 15.3312V16.0545H11.2483V15.3525H9.5678L9.54653 15.317C10.0287 14.8349 10.362 14.5087 10.5393 14.3314C10.9718 13.9059 11.1916 13.4663 11.1916 13.0125C11.1916 12.8423 11.1562 12.275 10.5463 11.963C10.2131 11.7999 9.65289 11.7078 9.16362 11.9418C8.58217 12.2183 8.46162 12.743 8.45453 12.7572L9.17071 13.055C9.24162 12.821 9.44017 12.5445 9.80889 12.5445Z" fill="black" />
                    <path d="M13.7231 15.303C13.1346 15.303 13.0211 14.764 12.9998 14.6931L12.2695 14.9839C12.5886 16.1113 13.6947 16.0546 13.7231 16.0546C14.574 16.0546 14.9144 15.544 14.9711 15.4519C15.198 15.1044 15.2264 14.5726 14.964 14.2039C14.8435 14.0337 14.6804 13.9131 14.4818 13.8351V13.7855C14.6237 13.7146 14.7442 13.6011 14.8506 13.4451C15.0349 13.1544 15.0704 12.6864 14.8364 12.3319C14.7797 12.2539 14.4606 11.8 13.6877 11.8C12.7942 11.8 12.4538 12.4382 12.3758 12.6793L13.0778 12.97C13.1558 12.7431 13.326 12.5162 13.6806 12.5162C13.9926 12.5162 14.2124 12.7006 14.2124 12.9771C14.2124 13.3884 13.8224 13.4877 13.5884 13.4877H13.2622V14.1968H13.6167C14.0138 14.1968 14.3542 14.367 14.3542 14.757C14.3542 15.1044 14.0138 15.303 13.7231 15.303Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-549" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case SIGNATURE:
            return <SvgIcon viewBox="0 0 24 24">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -637C-47 -645.837 -39.8366 -653 -31 -653H277V248H-31C-39.8366 248 -47 240.837 -47 232V-637Z" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                    <path d="M17.6836 10.7478L18.5319 9.92333C19.156 9.31667 19.156 8.32889 18.5319 7.72222L17.4035 6.62556C16.7794 6.01889 15.7631 6.01889 15.1389 6.62556L14.2907 7.45L17.6836 10.7478ZM14.2907 11.8522L8.52901 17.4444H7.40069V16.3478L13.1543 10.7556L14.2907 11.8522ZM13.1543 8.54667L5.80023 15.7022V19H9.1932L16.5473 11.8522L13.1543 8.54667ZM17.8037 16.2778C17.8037 17.9811 15.7711 19 13.8025 19C13.3624 19 13.0023 18.65 13.0023 18.2222C13.0023 17.7944 13.3624 17.4444 13.8025 17.4444C15.0349 17.4444 16.2032 16.8767 16.2032 16.2778C16.2032 15.9122 15.8191 15.6011 15.2189 15.3444L16.4033 14.1933C17.2595 14.6833 17.8037 15.3367 17.8037 16.2778ZM6.26436 13.05C5.48814 12.6144 5 12.0467 5 11.2222C5 9.82222 6.51243 9.17667 7.84881 8.60889C8.67305 8.25111 9.80137 7.76889 9.80137 7.33333C9.80137 7.01444 9.17719 6.55556 8.20091 6.55556C7.19263 6.55556 6.7605 7.03 6.7365 7.05333C6.45642 7.37222 5.95227 7.41111 5.61618 7.14667C5.28808 6.88222 5.22406 6.40778 5.49614 6.07333C5.58417 5.96444 6.4084 5 8.20091 5C9.99343 5 11.4018 6.02667 11.4018 7.33333C11.4018 8.78778 9.85739 9.44889 8.489 10.0322C7.73678 10.3511 6.60046 10.8333 6.60046 11.2222C6.60046 11.4633 6.94456 11.6889 7.4567 11.8911L6.26436 13.05Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-653" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case "Download":
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -697C-47 -705.837 -39.8366 -713 -31 -713H277V188H-31C-39.8366 188 -47 180.837 -47 172V-697Z" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                    <path d="M15.7727 6.86364V15.75C15.7727 17.4577 14.3082 18.8409 12.5 18.8409C10.6918 18.8409 9.22727 17.4577 9.22727 15.75V6.09091C9.22727 5.02455 10.1436 4.15909 11.2727 4.15909C12.4018 4.15909 13.3182 5.02455 13.3182 6.09091V14.2045C13.3182 14.6295 12.95 14.9773 12.5 14.9773C12.05 14.9773 11.6818 14.6295 11.6818 14.2045V6.86364H10.4545V14.2045C10.4545 15.2709 11.3709 16.1364 12.5 16.1364C13.6291 16.1364 14.5455 15.2709 14.5455 14.2045V6.09091C14.5455 4.38318 13.0809 3 11.2727 3C9.46455 3 8 4.38318 8 6.09091V15.75C8 18.0991 10.0127 20 12.5 20C14.9873 20 17 18.0991 17 15.75V6.86364H15.7727Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-713" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case ATTACHMENT:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-47 -757C-47 -765.837 -39.8366 -773 -31 -773H277V128H-31C-39.8366 128 -47 120.837 -47 112V-757Z" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="white" />
                    <rect x="-15" y="-12" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#9ED5CD" fillOpacity="0.47" />
                    <path d="M13.696 6.55556L15.16 8.11111H18.4V17.4444H5.6V8.11111H8.84L10.304 6.55556H13.696V6.55556ZM14.4 5H9.6L8.136 6.55556H5.6C4.72 6.55556 4 7.25556 4 8.11111V17.4444C4 18.3 4.72 19 5.6 19H18.4C19.28 19 20 18.3 20 17.4444V8.11111C20 7.25556 19.28 6.55556 18.4 6.55556H15.864L14.4 5ZM12 10.4444C13.32 10.4444 14.4 11.4944 14.4 12.7778C14.4 14.0611 13.32 15.1111 12 15.1111C10.68 15.1111 9.6 14.0611 9.6 12.7778C9.6 11.4944 10.68 10.4444 12 10.4444ZM12 8.88889C9.792 8.88889 8 10.6311 8 12.7778C8 14.9244 9.792 16.6667 12 16.6667C14.208 16.6667 16 14.9244 16 12.7778C16 10.6311 14.208 8.88889 12 8.88889Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-47" y="-773" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case HEADER:
        case SUBHEADER:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-46 -73C-46 -81.8365 -38.8366 -89 -30 -89H278V812H-30C-38.8366 812 -46 804.837 -46 796V-73Z" fill="white" />
                    <rect x="-14" y="-12" width="261" height="48" rx="4" fill="white" />
                    <rect x="-14" y="-12" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#DCECC9" />
                    <path d="M17.4444 10.1429V8.57143H6.55556V10.1429H17.4444ZM19 18H5V16.4286H19V18ZM19 14.8571H5V13.2857H19V14.8571ZM19 11.7143H5V7H19V11.7143Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-46" y="-89" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case LABEL:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-46 -192C-46 -200.837 -38.8366 -208 -30 -208H278V693H-30C-38.8366 693 -46 685.837 -46 677V-192Z" fill="white" />
                    <rect x="-14" y="-13" width="261" height="48" rx="4" fill="white" />
                    <rect x="-14" y="-13" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#DCECC9" />
                    <path d="M6 15.4V17H18V15.4H6ZM9.85714 12.04H14.1429L14.9143 13.8H16.7143L12.6429 5H11.3571L7.28571 13.8H9.08571L9.85714 12.04ZM12 6.584L13.6029 10.6H10.3971L12 6.584Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-46" y="-208" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case PARAGRAPH:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-46 -252C-46 -260.837 -38.8366 -268 -30 -268H278V633H-30C-38.8366 633 -46 625.837 -46 617V-252Z" fill="white" />
                    <rect x="-14" y="-11" width="261" height="48" rx="4" fill="white" />
                    <rect x="-14" y="-11" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#DCECC9" />
                    <path d="M14.6667 14H6V15.3333H14.6667V14ZM14.6667 8.66667H6V10H14.6667V8.66667ZM6 12.6667H19V11.3333H6V12.6667ZM6 18H19V16.6667H6V18ZM6 6V7.33333H19V6H6Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-46" y="-268" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case LINEBREAK:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-46 -315C-46 -323.837 -38.8366 -331 -30 -331H278V570H-30C-38.8366 570 -46 562.837 -46 554V-315Z" fill="white" />
                    <rect x="-14" y="-14" width="261" height="48" rx="4" fill="white" />
                    <rect x="-14" y="-14" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#DCECC9" />
                    <path d="M5 17.375H9.94118V15.75H5V17.375ZM18.1765 6H5V7.625H18.1765V6ZM15.7059 10.875H5V12.5H15.9118C16.8176 12.5 17.5588 13.2312 17.5588 14.125C17.5588 15.0188 16.8176 15.75 15.9118 15.75H14.0588V14.125L11.5882 16.5625L14.0588 19V17.375H15.7059C17.5259 17.375 19 15.9206 19 14.125C19 12.3294 17.5259 10.875 15.7059 10.875Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-46" y="-331" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case ROW:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-46 -131C-46 -139.837 -38.8366 -147 -30 -147H278V754H-30C-38.8366 754 -46 746.837 -46 738V-131Z" fill="white" />
                    <rect x="-14" y="-11" width="261" height="48" rx="4" fill="white" />
                    <rect x="-14" y="-11" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#DCECC9" />
                    <path d="M18.3333 11.6V9.8H6.66667V11.6H18.3333ZM20 17H5V15.2H20V17ZM20 13.4H5V8H20V13.4Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-46" y="-147" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>
        case COLUMN:
            return <SvgIcon viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" fill="#E5E5E5" />
                <g filter="url(#filter0_d_823_36019)">
                    <path d="M-46 -373C-46 -381.837 -38.8366 -389 -30 -389H278V512H-30C-38.8366 512 -46 504.837 -46 496V-373Z" fill="white" />
                    <rect x="-14" y="-12" width="261" height="48" rx="4" fill="white" />
                    <rect x="-14" y="-12" width="261" height="48" rx="4" fill="#007DFF" fillOpacity="0.04" />
                    <rect width="24" height="24" rx="4" fill="#DCECC9" />
                    <path d="M13.1329 6.6265V18.0126C13.1329 18.9072 13.8649 19.6392 14.7595 19.6392H18.0127C18.9073 19.6392 19.6393 18.9072 19.6393 18.0126V6.6265C19.6393 5.73188 18.9073 4.99992 18.0127 4.99992H14.7595C13.8649 4.99992 13.1329 5.73188 13.1329 6.6265ZM18.0127 6.6265V18.0126H14.7595V6.6265H18.0127Z" fill="black" />
                    <path d="M5 6.6265L5 18.0126C5 18.9072 5.73196 19.6392 6.62658 19.6392H9.87975C10.7744 19.6392 11.5063 18.9072 11.5063 18.0126V6.6265C11.5063 5.73188 10.7744 4.99992 9.87975 4.99992H6.62658C5.73196 4.99992 5 5.73188 5 6.6265ZM9.87975 6.6265V18.0126H6.62658L6.62658 6.6265H9.87975Z" fill="black" />
                </g>
                <defs>
                    <filter id="filter0_d_823_36019" x="-46" y="-389" width="327" height="901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.917647 0 0 0 0 0.933333 0 0 0 1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_823_36019" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_823_36019" result="shape" />
                    </filter>
                </defs>
            </SvgIcon>

        case MEDICATIONS:
            return <MedicationOutlinedIcon style={{ backgroundColor: 'rgba(205, 232, 232, 1)', color: '#000', borderRadius: '4px' }} />
        case PROBLEMSPMS:
        case ALLERGIESPMS:
            return <SyncOutlinedIcon style={{ backgroundColor: 'rgba(205, 232, 232, 1)', color: '#000', borderRadius: '4px' }} />
        case ALLERGIES:
            return <GrassIcon style={{ backgroundColor: 'rgba(205, 232, 232, 1)', color: '#000', borderRadius: '4px' }} />
        case ADDRESS:
            return <HomeOutlinedIcon style={{ backgroundColor: 'rgba(205, 232, 232, 1)', color: '#000', borderRadius: '4px' }} />
        case ATTACHMENT:
            return <AttachFileOutlinedIcon style={{ backgroundColor: 'rgba(205, 232, 232, 1)', color: '#000', borderRadius: '4px' }} />
        case PROBLEMS:
            return <SickOutlinedIcon style={{ backgroundColor: 'rgba(205, 232, 232, 1)', color: '#000', borderRadius: '4px' }} />
        case BUTTON:
                return <SelectAllOutlinedIcon style={{ backgroundColor: 'rgba(205, 232, 232, 1)', color: '#000', borderRadius: '4px' }} />
        default:
            return <DragIndicatorIcon />
    }
}