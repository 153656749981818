export class UnifiedWrapperService {
    private static PROXY_PATH = "forms-frame";
    private static API_PROXY_PATH = "api/enterprise-forms";
  
    public static IsProxyMode = () => { 
        const pathSegments = window.location.pathname.split('/');
        return pathSegments.length > 1 && pathSegments[1].includes(this.PROXY_PATH);
    };

    public static ProxyPath = this.IsProxyMode() ? '/' + this.PROXY_PATH : undefined;

    public static ApiProxyPath = this.IsProxyMode() ? '/' + this.API_PROXY_PATH : undefined;
}
