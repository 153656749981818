import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Alert, AlertProps, ThemeProvider } from '@mui/material';
import { actionBarTheme } from './RwActionBar';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiAlert-standard .MuiAlert-standardWarning': {
            backgroundColor: '#FFF0CA',
            color: '#6B4904',
        },
        '& .MuiAlert-standardWarning .MuiAlert-icon': {
            color: '#D59900',
        },
        '& .MuiAlert-standard .MuiAlert-standardError': {
            color: 'rgb(95, 33, 32)',
            backgroundColor: 'rgb(253, 237, 237)',
            marginBottom: '5px',
        },
        '& .MuiAlert-standardError .MuiAlert-icon': {
            color: 'rgb(95, 33, 32)',
        },
        '& .MuiAlert-standardError .MuiAlert-action': {
            pointerEvents: 'auto',
        },
        '& .MuiAlert-icon': {
            alignItems: 'center',

        }
    },
}));

const RwAlert: React.FC<AlertProps> = (props) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={actionBarTheme}>
            <div className={classes.root} id='hello'>
            <Alert {...props}>
                {props.children}
            </Alert>
            </div>
        </ThemeProvider>
    );
}

export default RwAlert;