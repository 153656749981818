import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { SWRConfig } from 'swr';
import { PatientAppContextProvider, usePatientAppContext } from '../../context/patient-app-context';
import FormsCustomThemeProvider from '../../themes/forms-custom-theme';
import { patientStyles } from '../../themes/patients-custom-theme';


export default function PatientAppProviders(props: React.PropsWithChildren<any>) {

    const context = usePatientAppContext();
    const color = context.color;

    const theme = createTheme({
        palette: {
            primary: {
                main: color
            },
            secondary: {
                main: color,
                dark: patientStyles.secondaryButtonBackgroundColorHover
            },

        },
        components: {
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        padding: '0 48px 0 48px',
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: '40px 48px 0 48px',
                        color: '#1C3D64',
                    },
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: '0 48px 40px 48px',
                    }
                }
            },
            
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });

    theme.typography.h4 = {
        fontSize: '1.875rem',
        fontWeight: '700',
        lineHeight: '37.05px'
    };

    theme.typography.subtitle1 = {
        fontSize: '1.0625rem',
        fontWeight: '500',
        lineHeight: '25.5px'
    };

    theme.typography.subtitle2 = {
        fontSize: '1.0625rem',
        fontWeight: '400',
        lineHeight: '25.5px'
    };

    return (
        <SWRConfig value={{ revalidateOnFocus: false }}>
            <PatientAppContextProvider>
                <ThemeProvider theme={theme}>
                    <FormsCustomThemeProvider>
                        {props.children}
                    </FormsCustomThemeProvider>
                </ThemeProvider>
            </PatientAppContextProvider>
        </SWRConfig>
    );
}