import { CircularProgress, Grid, Skeleton } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import PatientLoginContainer from './shared/PatientLoginContainer';
import RwPatientPage from './shared/RwPatientPage';



const MigrateForms = lazy(() => import(/* webpackChunkName: "forms-migrate" */ './forms/migrate'));

export const MigrateFormsModule =
{
    routeProps: {
        path: '/forms/migrate',
        component: MigrateForms,
        private: true
    },
    name: 'Migrate Forms',
};

const LoginPatient = lazy(() => import(/* webpackChunkName: "patients-login" */ './patients/login'));

export const LoginPatientModule =
{
    routeProps: {
        path: '/patients/login/:locationId/:expired/:token?/:id?',
        component: LoginPatient,
        private: false
    },
    name: 'Login Patient',
};

const RegisterPatient = lazy(() => import(/* webpackChunkName: "patients-register" */'./patients/register'));

export const RegisterPatientModule =
{
    routeProps: {
        path: '/patients/register/:id/:type/:locationId/:expired?',
        component: RegisterPatient,
        private: false
    },
    name: 'Register Patient',
};

const ScanPatient = lazy(() => import(/* webpackChunkName: "patients-scan" */'./patients/scan'));

export const ScanPatientModule =
{
    routeProps: {
        path: '/patients/scan',
        component: ScanPatient,
        private: false
    },
    name: 'Scan Patient',
};

const NotFound = lazy(() => import(/* webpackChunkName: "patients-notfound" */'./patients/notfound'));

export const NotFoundModule =
{
    routeProps: {
        path: '/patients/notfound/:locationId',
        component: NotFound,
        private: false
    },
    name: 'Not Found',
};

const PatientSearch = lazy(() => import(/* webpackChunkName: "patients-search" */'./patients/search/index'));

export const SearchSkeleton: React.FC = () => {
 
    return (
        <RwPatientPage title="Patient Search" loading={false}>
            <PatientLoginContainer fullWidth={true}>
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <Skeleton variant="rectangular" width={300} />
                    </Grid>
                    <Grid container item xs={12} justifyContent="center" style={{ marginTop: 10 }} >
                        <Skeleton variant="text" width={460} />
                    </Grid>
                    <Grid container item xs={12} justifyContent="center">
                        <Skeleton variant="rectangular" width={250} height={400} style={{marginTop: 100 }} />
                    </Grid>
                </Grid>
            </PatientLoginContainer>
        </RwPatientPage>
    );
};

const LazySearch: React.FC = () => {
    return (
        <Suspense fallback={<SearchSkeleton />}>
            <PatientSearch />
        </Suspense>
    );
};

export const SearchPatientModule =
{
    routeProps: {
        path: '/patients/search',
        component: LazySearch,
        private: false
    },
    name: 'Patient Search',
};

const VerifyPatient = lazy(() => import(/* webpackChunkName: "patients-verify" */'./patients/verify'));

const LazyVerifyPatientFallback = () => {
    return <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', marginTop: 200 }  }><CircularProgress /></div>
}

const LazyVerifyPatient: React.FC = () => {
    return (
        <Suspense fallback={<LazyVerifyPatientFallback />}>
            <VerifyPatient />
        </Suspense>
    );
};

export const VerifyPatientModule =
{
    routeProps: {
        path: '/patients/verify/:locationId/:token/:userId/:emlOrPhone?/:birthdate?/:mode?/:linkId?/:linkType?',
        component: LazyVerifyPatient,
        private: false
    },
    name: 'Verify Patient',
};

