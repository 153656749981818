import React, { CSSProperties } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Component from "../view/component";
import { useFormBuilderContext } from "../shared/form-builder-context";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    component: {
        border: "1px dashed #ccc",
        '&:hover': {
            border: "1px dashed #2E7D31",
        }
    },
    actionRow: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '2%',
        color: 'hsla(214, 11%, 60%, 1)',
        marginLeft: '10px',
        flexGrow: 1,
    },
    actionColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'hsla(214, 11%, 60%, 1)',
        minHeight: '40px',

    },
    sync: {
       
        fontSize: 16,
        marginTop: 'auto',
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.23)',
        marginBottom: 'auto',
        padding: '1px',
        '&:hover': {
            color: '#fff',
            backgroundColor: 'lightblue',
            borderRadius: '8px'
        }
    },
    edit: {
        fontSize: 16,
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.23)',
        padding: '1px',
        '&:hover': {
            color: '#fff',
            backgroundColor: 'green',
            borderRadius: '8px'
        }
    },
    delete: {
        fontSize: 16,
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.23)',
        padding: '1px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#fc7303',
            borderRadius: '8px'
        }
    }
}));

const SortableField = ({ block, container, siblingCount, deleteComponent, googleMapApiKey, disabled, displayLabel, setIsOverField }) => {
    const { state, editModeOn } = useFormBuilderContext();
    const classes = useStyles();

    const { setNodeRef, attributes, listeners, transition, transform, isDragging } = useSortable({
        id: block.id,
        data: {
            type: "block",
            container: container
        },
        disabled: disabled
    });

    const style: CSSProperties = {
        display: "flex",
        transition,
        flexGrow: 1,
        transform: CSS.Transform.toString(transform),
        opacity: isDragging ? 0.5 : 1,
        margin: 20,
        borderRadius: 5,
        cursor: 'move',
        padding: 10,
        backgroundColor: "#fff",
    };

    const canDisplayComponent = block.data && state.components.hasOwnProperty(block.data?.id);

    if (!canDisplayComponent)
        return (
            <div ref={setNodeRef} {...attributes} {...listeners} style={style} >{block.text}</div>);

    const component = state.components[block.data.id];

    if (canDisplayComponent)
        return (
            <div ref={setNodeRef} {...attributes} {...listeners} style={style} className={classes.component} onMouseOver={() => setIsOverField(true)} onMouseLeave={() => setIsOverField(false)}>
                <Component
                    key={block.data.id}
                    component={component}
                    components={state.components}
                    path={''}
                    handleChange={null}
                    readOnly={true}
                    parentType={block.data.parentType}
                    peerCount={1}
                    index={0}
                    firstComponentId={''}
                    isMobile={false}
                    googleMapApiKey={googleMapApiKey}
                    displayLabel={displayLabel}
                    editMode={true}
                    pmsSystemVm={state.pmsSystemVm}
                />
                <div className={classes.actionRow} data-no-dnd="true">
                    <div className={classes.actionColumn} data-no-dnd="true">
                        <Tooltip title="Edit Field" data-no-dnd="true" placement="top">
                            <EditOutlinedIcon className={clsx(classes.edit, 'show-on-hover')} onClick={() => editModeOn(block.id)} />
                        </Tooltip>
                        {siblingCount > 1 && 
                            <Tooltip title="Delete Field" data-no-dnd="true" placement="bottom">
                                <DeleteOutlinedIcon className={clsx(classes.delete, 'show-on-hover')} onClick={() => deleteComponent(block.id)} />
                            </Tooltip>}
                    </div>
                </div>
                {((component.fieldId && component.fieldId !== '') || (component.externalId && component.externalId !== '')) &&
                    <div className={classes.actionColumn}>
                        <Tooltip title="Writes to PMS" data-no-dnd="true" placement="right-end">

                            <SyncOutlinedIcon className={classes.sync} />
                      
                </Tooltip>
            </div>
                }
            </div>
        );
};

export default SortableField;