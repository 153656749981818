import { Grid, Paper, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { lazy, Suspense } from 'react';
import RwPage from '../shared/RwPage';
import classNames from 'classnames';




export const useSharedAssignmentStyles = makeStyles((theme) => ({
    contactsSection: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        '&.hide': {
            display: 'none'
        },
        textAlign: "center",
    },
    patientSearch: {
        textAlign: "center",
        padding: '32px 0px 5px 0px',
        height: '85px',
    },
    patientSearchBack: {
        marginLeft: '25px'
    },
    skeletonPatient: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        paddingTop: 10
    },
    assignmentsListSection: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        '&.desktop': {
            maxWidth: '350px',
        }
    }
}));

export const AssignmentsSkeleton: React.FC = () => {
    const classes = useSharedAssignmentStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <>
            <Paper className={classNames(classes.assignmentsListSection, { desktop: isDesktop })} elevation={3}>
                {[...Array(30)].map((row, i) => {
                    return (
                        <Grid container key={i} direction="row" justifyContent="space-between" alignItems="center" className={classes.skeletonPatient}>
                            <div>
                                <Skeleton variant="text" width={80} />
                                <Skeleton variant="text" width={160} />
                            </div>
                            <Skeleton variant="text" width={70} />
                        </Grid>
                    );
                })}
            </Paper>
            {isDesktop &&
                <RwPage title="Submissions" showLocationFilter={true} loading={true}>
                    <Skeleton variant="rectangular" style={{ marginLeft: '45px', width: '80%', height: '25%' }} />
                </RwPage>
            }
        </>
    );
};


const Assignments = lazy(() => import(/* webpackChunkName: "assignments-index" */ './index'));

const LazyAssignments: React.FC = () => {
    return (
        <Suspense fallback={<AssignmentsSkeleton />}>
            <Assignments />
        </Suspense>
    );
};

export const AssignmentsModule =
{
    routeProps: {
        path: '/assignments/index',
        component: LazyAssignments,
        private: true
    },
    name: 'List Assignments',
};