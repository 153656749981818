import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { SideBarItem } from './side-bar-item';
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';
import { HEADER, SUBHEADER } from '../shared/constants';

const useStyles = makeStyles((theme) => ({
    toolBarItemDragIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.custom.listItem,
        "&:hover": {
            cursor: 'move'
        }
    },
    toolBarItem: {
        display: 'flex',
        width: '98%',
    },
}));
const DraggableField = ({ block, container }) => {
    const classes = useStyles();

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: block.id,
        data: {
            type: "block",
            container: container,
        },
    });
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;


    return (
        <div ref={setNodeRef} style={style} className={classes.toolBarItem}>
            <SideBarItem key={block?.id} field={block?.data} />
            {block?.data?.key !== SUBHEADER && block?.data?.key !== HEADER &&
                <Tooltip title="Drag to add this item as column in an existing row">
                    <ViewColumnRoundedIcon className={classes.toolBarItemDragIcon} {...listeners} {...attributes} />
                </Tooltip>
            }
        </div>
    );
}

export default DraggableField;