import {
    LoginPatientModule, VerifyPatientModule, RegisterPatientModule,
    ScanPatientModule, SearchPatientModule, NotFoundModule
} from './modules';

import { SubmissionsModule } from './submissions/index.module';
import { AssignmentsModule } from './assignments/index.module';
import { FormsModule } from './forms/modules/index.module';
import { PacketsModule } from './packets/index.module';
import { SettingsFormModule } from './forms/modules/settings.module';
import { ViewFormModule } from './forms/modules/view.module';
import { PatientModule } from './patients/index.module';
import { SingleSourceFormsModule } from './forms/modules/single.module';
import { Forms1FormsModule } from './forms/modules/forms1.module';
import { SubmissionCreateModule } from './patients/forms/create.module';
import { EditPacketModule } from './packets/settings.module';
import { EditFormModule } from './forms/modules/edit.module';
import { BuiltInFormsModule } from './forms/modules/builtin.module';

export  const PracticeModules = {
    name: 'practice modules',
    list: [
        FormsModule,
        EditFormModule,
        ViewFormModule,
        SingleSourceFormsModule,
        Forms1FormsModule,
        BuiltInFormsModule,
        SettingsFormModule,
        SubmissionsModule,
        PacketsModule,
        EditPacketModule,
        AssignmentsModule
    ]
}

export  const PatientModules = {
    name: 'patient modules',
    list: [
        LoginPatientModule,
        VerifyPatientModule,
        ScanPatientModule,
        NotFoundModule,
        SearchPatientModule,
        PatientModule,
        SubmissionCreateModule,
        RegisterPatientModule
    ]
}
