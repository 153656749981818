


enum Keys {
    ApiForm = "/api/form",
    ApiFormSubmission = "/api/formsubmissions",
    ApiFormSubmissionPdf = "/api/formsubmissionpdf",
    ApiFormPdf = "/api/formpdf",
    AzureForm = "/azure/form",
    AzureSubmission = "/azure/submission",
    AzureSubmissionWithFormUriAndJson = "/azure/submissionwithformuriandjson",
    ApiFormPackets = "/api/formpackets",
    ApiFormFields = "/api/formfields",
    ApiNetworkFormFields = "/api/networkformfields",
    ApiFormAssignments = "/api/FormAssignments",
    ApiPatient = "/api/patient",
    ApiLocation = "/api/location",
    ApiMedications = "/api/medications",
    ApiPmsInfo = "/api/pmsinfo",
    ApiContacts = "/api/contacts",
    ApiFormSingleSource = "/api/getsinglesourceforms",
    ApiPatientTokenIsValid = "/api/patienttokenisvalid",
    ApiUser = "/api/user",
    ApiPatientSearch = "/api/patientsearch",
    ApiPatientAppointments = "/api/patientappointments",
    ApiPatientAppointmentFormAssignments = "/api/patientappointments/formassignments",
    ApiPatientAppointmentsVm = "/api/patientappointments/vm"
}

export default Keys;





