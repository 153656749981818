import React from 'react';
import IComponent from '../../../../services/forms.interface';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { TextField, Typography } from '@mui/material';
import myxss from './myxss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            backgroundColor: '#F9F9F9',
            outlineColor: 'rgba(0, 0, 0, 0.23)',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            margin: '1px',
            '&.MuiToggleButton-root.Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
            '&.error': {
                color: theme.palette.error.main,
                outlineColor: theme.palette.error.main,
            }
        },
        formControl: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            width: '100%',
            alignItems: 'center',
        },
        textField: {
            minWidth: '300px',
            marginLeft: '5px'
        },
        mobileTextField: {
            minWidth: '300px',
            marginTop: '5px'
        },
        mobile: {
            flexWrap: 'wrap',
            flexDirection: 'column'
        },
        label: {
            marginRight: '20px'
        }
    }),
);

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F9F9F9',
    },
});

const SwitchInput: React.FC<IComponent> = props => {
    const classes = useStyles();

    const switchValue = props.value?props.value: '';
    const textValue = props.childComponents ? Object.values(props.childComponents)[0]?.value : '';
    const childKey = props.childComponents ? Object.keys(props.childComponents)[0] : '';
    const childError = props.childComponents && props.childComponents[childKey].hasOwnProperty('error') ? true : false;

    const handleSwitchChange = (event, newValue) => {
        props.handleChange(event, props.id, newValue); 
    };

    const handleTextChange = (event) => {
        props.handleChange(event, Object.values(props.childComponents)[0]?.id, event.target.value, props.id);
    };

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }

    return (
        <div className={baseClasses}>
            <FormControl component="fieldset" className={`${classes.formControl} ${props.isMobile ? classes.mobile : ''}`}>
                {props.displayLabel &&
                    <div className={classes.label}>
                        <Typography variant="subtitle1" fontSize={17}>
                            {myxss.process(props.label)}
                        </Typography>
                    </div>
                }
                <ToggleButtonGroup value={switchValue}
                    exclusive
                    onChange={handleSwitchChange}
                    size="small"
                    disabled={props.disabled}>
                    <ToggleButton value="true" className={classNames(classes.button, { error: props.hasOwnProperty('error') })}>Yes</ToggleButton>,
                    <ToggleButton value="false" className={classNames(classes.button, { error: props.hasOwnProperty('error') })}>No</ToggleButton>,
                </ToggleButtonGroup>
                {props.explaination && switchValue === 'true' && props.childComponents &&
                    <StyledTextField variant="outlined" fullWidth InputProps={{ className: props.isMobile ? classes.mobileTextField : classes.textField }} error={childError} margin="none" size="small" placeholder="If yes, please explain" defaultValue={textValue} onBlur={handleTextChange} required={props.required} />
                }
            </FormControl>
        </div>
    )
}

export default SwitchInput;

