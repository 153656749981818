import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import IComponent from '../../../../services/forms.interface';
import TextField from "@mui/material/TextField";
import myxss from './myxss';
import MaskedTextInput from './masked-textinput';
import styled from '@emotion/styled';


interface ICustomProps {
    name: string;
    type: string;
    value: string;
    defaultValue: string;
    disabled: boolean;
    read_only: string;
    required: boolean;
    maskKey: string;
    error: any;
    onChange: any;
    onBlur: any;
    label: string;
    autoComplete: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    textField: {
        width: '100%',
    },

}));

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F9F9F9',
    },
});

const TextInput: React.FC<IComponent> = props => {
    const classes = useStyles();

    const handleChange = (event) => {
        props.handleChange(event, props.id, event.target.value);
    };

    const isRequired = (props.hasOwnProperty('required') && props.required === true && !props.read_only);
    const labelText = myxss.process(props.label);
    const localProps: ICustomProps = {} as ICustomProps;

    localProps.type = 'text';
    localProps.name = props.name;
    localProps.onChange = handleChange;
    localProps.required = isRequired;
    localProps.defaultValue = props.value? props.value: '';
    localProps.label = labelText;
    localProps.disabled = props.disabled;
    localProps.autoComplete = props.autocomplete;

    if (props.error) { localProps.error = true; }

    if (props.disabled)
        localProps.disabled = true;

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }
    
    return (
        <div className={baseClasses}>
            {(!props.mask || props.mask?.length === 0 || props.mask === 'maskEmail') &&
                <StyledTextField variant="outlined" {...localProps} fullWidth InputProps={{ className: classes.textField }} margin="none" size="small" />
            }
            {props.mask?.length > 0 && props.mask !== 'maskEmail' &&
                <MaskedTextInput id={props.id} name={props.name} error={props.error}  {...localProps} onChange={handleChange} className={classes.textField} size="small" maskKey={props.mask} label={props.label} />
            }
        </div>
    );
}


export default TextInput;



