import React from "react";
import FormElements from '../shared/form-elements';
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import IComponent from '../../../services/forms.interface';
import { IPmsSystemVm } from "../../../api/web-api-client";

const useStyles = makeStyles(() => ({
    component: {
        display: "flex",
        marginBottom: (props: any) => props.isMobile && props.peerCount > 1 ? '5px' : '0px',
        '& .item': {
            width: '100%',
            alignSelf: 'center'
        },
        '&.SubHeader': {
        },
        '&.RadioButtons': {
        },
        '&.Dropdown': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.Signature': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.NumberInput': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.Paragraph': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.TextInput': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.TextArea': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.DatePicker': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.Medications': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.Allergies': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.Problems': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.Address': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.Label': {
            flexBasis: (props: any) => props.peerCount > 1 ? 'calc(' + props.basis + '%)' : props.basis + '%',
        },
        '&.Attachment': {
            flexBasis: '100%',
        },
        '&.editMode': {
            width: '100%',
        },
    }
}));

interface ComponentProps {
    component: IComponent;
    components: Map<string, IComponent>;
    handleChange: (event, id, value) => void;
    readOnly?: boolean;
    parentType: any;
    path: string;
    peerCount: number;
    index: number;
    firstComponentId: string;
    isMobile: boolean;
    medications?: string[];
    googleMapApiKey: string;
    displayLabel?: boolean;
    editMode?: boolean;
    pmsSystemVm: IPmsSystemVm;
    key: string;
}

const Component: React.FC<ComponentProps> = (props) => {

    const component = props?.components ? props.components[props.component.id] : null;

    const getFlexBasis = (index) => {

        if (props.isMobile)
            return 100;

        if (props.peerCount === 1)
            return 100;

        return (100 / (props.peerCount)) - 1;
    }


    const classes = useStyles({ isMobile: props.isMobile, basis: getFlexBasis(props?.index), peerCount: props?.peerCount });

    const getElement = (item) => {
       
        if (!item)
            return;

        const FormElement = FormElements[item.type];

        if (FormElement === null || FormElement === undefined) {
            return null;
        }

        return <FormElement {...item} mutable={true} id={props.component.id} handleChange={props.handleChange} isMobile={props.isMobile} disabled={props.readOnly} googleMapApiKey={props.googleMapApiKey} displayLabel={props.displayLabel} editMode={props.editMode} pmsSystemVm={props.pmsSystemVm} />
    }
   
    return (
        <>
            {component &&
                <div className={classNames(classes.component, component.type, {editMode: props.editMode})}>
                    {getElement(component)}
                </div>
            }
        </>
    );
};

export default Component;