import { AxiosInstance } from 'axios';
import { httpService } from './httpService';
import { UnifiedWrapperService } from './unified-wrapper';


export interface IClientService {
    apiPath: string;
}

const clientService = {
    getFormsClient: function <T>(c: { new(d: any, e: AxiosInstance): T }, props: IClientService): T {
        return new c(props?.apiPath ? props?.apiPath : UnifiedWrapperService.ApiProxyPath, httpService.GetInstance());
    }
}

export default clientService;