import React from 'react';
import IComponent from '../../../../services/forms.interface';
import myxss from './myxss';
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    header: {
        color: '#1C3D64'
    }
}));
const Header: React.FC<IComponent> = props => {
    const classes = useStyles();

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }

    return (
        <div className={baseClasses}>
            <Typography variant="h4" className={classes.header}>
                    {myxss.process(props.content)}
            </Typography> 
        </div>
    );
}

export default Header;

