import { Box, Typography } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */
export interface IDrawerButtonProps {
    title: string;
    id?: string;
    className?: string;
    onClick?(): void;
    children: JSX.Element;
    badgeCount?: number;
}

export function DrawerButton(props: IDrawerButtonProps) {
    return (
        <div id={props.id} className={`drawer-button ${props.className ? props.className : ""}`} onClick={props.onClick}>
            {props.children}
            <span className="button-title">{props.title}</span>
            {props?.badgeCount > 0 &&
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                <Typography sx={{
                    background: '#0675DD', borderRadius: '64px', position: 'static', width: '30px', color: 'white', fontSize: '12px',
                    height: '20px', top: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    {props.badgeCount}
                    </Typography>
                    </Box>}
        </div>
    );
}

