import React, { useEffect } from 'react';
import IComponent from '../../../../services/forms.interface';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import myxss from './myxss';
import makeStyles from '@mui/styles/makeStyles';
import { FormLabel, Typography } from '@mui/material';

interface ICustomProps {
    name: string;
    type: string;
    value: string;
    defaultValue: string;
    disabled: boolean;
    read_only: string;
    required: boolean;
    maskKey: string;
    error: any;
    onChange: any;
    label: string;
}

interface ICheckboxProps extends Omit<IComponent, 'defaultValue'> {
    defaultValue: string[]
}


const useStyles = makeStyles(theme => ({
    FormGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    Box: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    label: {
        marginRight: '10px'
    }
}));

const Checkboxes: React.FC<ICheckboxProps> = props => {
    const classes = useStyles({ options: props.options });

    if (props.defaultValue && !Array.isArray(props.defaultValue)) {
        throw new Error('defaultValue for Checkboxes input must be an array of strings.');
    }
    
    const [state, setState] = React.useState(props.options.map(o => ({
        ...o,
        checked: props.defaultValue ? props.defaultValue.includes(o.value) : false
    })));
    

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const newState = state.map(o => {
            const newO = { ...o };
            if (event.target.value === o.value) {
                newO.checked = event.target.checked;
            }
            return newO;
        });

        setState(newState);
        props.handleChange(event, props.id, newState.filter(o => o.checked).map(o => o.value));
    };

    useEffect(() => {
        setState(props.options.map(o => {
            const op: any = { ...o };
            const stateForThisOp = state?.find(s => s.key === op.key);
            op.checked = stateForThisOp?.checked ?? false;
            return op;
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);



    const isRequired = (props.hasOwnProperty('required') && props.required === true && !props.read_only);
    const labelText = myxss.process(props.label);

    const localProps: ICustomProps = {} as ICustomProps;
    localProps.name = props.field_name;
    localProps.onChange = handleChange;
    localProps.required = isRequired;
    localProps.defaultValue = props.value;
    localProps.label = labelText;
    localProps.disabled = props.disabled;

    let baseClasses = 'item SortableItem rfb-item';
    if (props.editMode) { baseClasses += ' is-editing'; }
    if (!props.editMode) { baseClasses += ' not-is-editing'; }
    if (props.selected) { baseClasses += ' selected'; }
    
    return (
        <div className={baseClasses}>
            <FormGroup className={classes.FormGroup} row>
                {props.displayLabel &&
                    <div className={classes.label}>
                        <Typography variant="subtitle1" fontSize={17}>
                            {myxss.process(props.label)}
                        </Typography>
                    </div>
                }
                    {state.map((option) => {
                        const this_key = `preview_${option.key}`;
                        return (
                            <div key={this_key} className={classes.Box}>
                                <Checkbox key={this_key + 'checkbox'} color={props.hasOwnProperty('error') ? 'secondary' : 'primary'} checked={option.checked} value={option.value} {...localProps} />
                                <FormLabel key={this_key + 'label'} component="legend" error={props.hasOwnProperty('error')}>{option.text}</FormLabel>
                            </div>
                        );
                    })}
                </FormGroup>
        </div>
    )
}

export default Checkboxes;

