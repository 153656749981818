import { FormFieldDto } from '../../../api/web-api-client';
import IComponent from '../../../services/forms.interface';
import ID from '../../shared/UUID';
import { ROW, COLUMN, COMPONENT, COLUMN_LAYOUT, DEFAULT_ITEM_OPTIONS, SIDEBAR_ITEMS, SIDEBAR_LAYOUT_ITEMS_CONTENT, SUBHEADER, SIDEBAR_ITEMS_QUESTIONS, BUTTON, SWITCHINPUT, LABEL, MEDICATIONS, ALLERGIES, PROBLEMS, ADDRESS, ALLERGIESPMS, PROBLEMSPMS } from "./constants";

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed); // inserting task in new index

    return result;
};

export const remove = (arr, index) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // part of the array after the specified index
    ...arr.slice(index + 1)
];

export const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
];

export const reorderChildren = (children, splitDropZonePath, splitItemPath) => {

    if (splitDropZonePath.length === 1) {
        let dropZoneIndex = Number(splitDropZonePath[0]);

        const itemIndex = Number(splitItemPath[0]);
        if (dropZoneIndex > itemIndex)
            dropZoneIndex--;

        return reorder(children, itemIndex, dropZoneIndex);
    }

    const updatedChildren = [...children];

    const curIndex = Number(splitDropZonePath.slice(0, 1));

    // Update the specific node's children
    const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
    const splitItemChildrenPath = splitItemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
        ...nodeChildren,
        children: reorderChildren(
            nodeChildren.children,
            splitDropZoneChildrenPath,
            splitItemChildrenPath
        )
    };

    return updatedChildren;
};


export const removeChildFromChildren = (children, splitItemPath) => {
    if (splitItemPath.length === 1) {
        const itemIndex = Number(splitItemPath[0]);
        return remove(children, itemIndex);
    }

    const updatedChildren = [...children];

    const curIndex = Number(splitItemPath.slice(0, 1));

    // Update the specific node's children
    const splitItemChildrenPath = splitItemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
        ...nodeChildren,
        children: removeChildFromChildren(
            nodeChildren.children,
            splitItemChildrenPath
        )
    };

    return updatedChildren;
};

export const addChildToChildren = (children, splitDropZonePath, item) => {
    if (splitDropZonePath.length === 1) {
        const dropZoneIndex = Number(splitDropZonePath[0]);
        return insert(children, dropZoneIndex, item);
    }

    const updatedChildren = [...children];

    const curIndex = Number(splitDropZonePath.slice(0, 1));

    // Update the specific node's children
    const splitItemChildrenPath = splitDropZonePath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
        ...nodeChildren,
        children: addChildToChildren(
            nodeChildren.children,
            splitItemChildrenPath,
            item
        )
    };

    return updatedChildren;
};

export const handleMoveWithinParent = (
    layout,
    splitDropZonePath,
    splitItemPath
) => {
    return reorderChildren(layout, splitDropZonePath, splitItemPath);
};

export const handleAddColumDataToRow = layout => {
    const layoutCopy = [...layout];
    const COLUMN_STRUCTURE = {
        type: COLUMN,
        id: ID.uuid(),
        children: []
    };

    return layoutCopy.map(row => {
        if (!row.children.length) {
            row.children = [COLUMN_STRUCTURE];
        }
        return row;
    });
};

export const handleMoveToDifferentParent = (
    layout,
    splitDropZonePath,
    splitItemPath,
    item
) => {


    let newLayoutStructure;
    const COLUMN_STRUCTURE = {
        type: COLUMN,
        id: ID.uuid(),
        children: [item]
    };

    const ROW_STRUCTURE = {
        type: ROW,
        id: ID.uuid()
    };

    switch (splitDropZonePath.length) {
        case 1: {
            // moving column outside into new row made on the fly
            if (item.type === COLUMN) {
                newLayoutStructure = {
                    ...ROW_STRUCTURE,
                    children: [item]
                };
            } else {
                // moving component outside into new row made on the fly
                newLayoutStructure = {
                    ...ROW_STRUCTURE,
                    children: [COLUMN_STRUCTURE]
                };
            }
            break;
        }
        case 2: {
            // moving component outside into a row which creates column
            if (item.type === COMPONENT) {
                newLayoutStructure = COLUMN_STRUCTURE;
            } else {
                // moving column into existing row
                newLayoutStructure = item;
            }

            break;
        }
        default: {
            newLayoutStructure = item;
        }
    }

    let updatedLayout = layout;
    updatedLayout = removeChildFromChildren(updatedLayout, splitItemPath);
    updatedLayout = handleAddColumDataToRow(updatedLayout);
    updatedLayout = addChildToChildren(
        updatedLayout,
        splitDropZonePath,
        newLayoutStructure
    );

    return updatedLayout;
};

export const handleMoveSidebarComponentIntoParent = (
    layout,
    splitDropZonePath,
    item,
) => {
    let newLayoutStructure;

    switch (splitDropZonePath.length) {
        case 1: {
            newLayoutStructure = {
                type: ROW,
                id: ID.uuid(),
                columnLayout: COLUMN_LAYOUT.ROW,
                children: [{ type: COLUMN, id: ID.uuid(), children: [item] }]
            };
            break;
        }
        case 2: {

            newLayoutStructure = {
                type: COLUMN,
                id: ID.uuid(),
                children: [item]
            };
            break;
        }
        default: {
            newLayoutStructure = item;
        }
    }

    return addChildToChildren(layout, splitDropZonePath, newLayoutStructure);
};

export const handleMoveSidebarComponentIntoLayoutParent = (
    layout,
    splitDropZonePath,
    item,
) => {
    let newLayoutStructure;

    switch (splitDropZonePath.length) {
        case 1:
            {

                newLayoutStructure = {
                    type: ROW,
                    typeDisplay: 'Section',
                    id: ID.uuid(),
                    title: "Section Title",
                    columnLayout: COLUMN_LAYOUT.ROW,
                    children: [{ type: COLUMN, id: ID.uuid(), children: [], fillRow: false }]
                };


                break;
            }
        case 2: {

            newLayoutStructure = {
                type: COLUMN,
                typeDisplay: 'Sub-Section',
                id: ID.uuid(),
                title: "Sub-Section Title",
                children: [],
                fillRow: false
            };
            break;
        }
        default: {
            newLayoutStructure = item;
        }
    }

    return addChildToChildren(layout, splitDropZonePath, newLayoutStructure);
};


const createMedicationsComponent = (newComponent) => {
    const childComponents = {};

    for (let i = 1; i < 21; i++) {
        const textItem = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];

        let newChildComponent = {
            id: ID.uuid(),
            ...textItem.component
        };

        newChildComponent.label = 'Name';
        newChildComponent.name = 'health_history_medication' + i + '_name';
        newChildComponent = initComponent(newChildComponent);
        childComponents[newChildComponent.id] = newChildComponent;

        newChildComponent = {
            id: ID.uuid(),
            ...textItem.component
        };

        newChildComponent.label = 'Dosage',
            newChildComponent.name = 'health_history_dosage' + i + '_name';
        newChildComponent = initComponent(newChildComponent);
        childComponents[newChildComponent.id] = newChildComponent;

        newChildComponent = {
            id: ID.uuid(),
            ...textItem.component
        };

        newChildComponent.label = 'Frequency',
         newChildComponent.name = 'health_history_frequency' + i + '_name';
        newChildComponent = initComponent(newChildComponent);
        childComponents[newChildComponent.id] = newChildComponent;
    }

    newComponent.childComponents = childComponents;

    return newComponent;
}

const createAllergiesOrProblemsComponent = (newComponent, prefix) => {
    const childComponents = {};

    for (let i = 1; i < 11; i++) {
        const textItem = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];

        let newChildComponent = {
            id: ID.uuid(),
            ...textItem.component
        };

        newChildComponent.label = 'Name';
        newChildComponent.name = prefix + i + '_name';
        newChildComponent = initComponent(newChildComponent);
        childComponents[newChildComponent.id] = newChildComponent;
    }

    newComponent.childComponents = childComponents;

    return newComponent;
}

const createAddressComponent = (newComponent) => {
    const childComponents = {};

    const address1 = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];

    let address1Component = {
        id: ID.uuid(),
        ...address1.component
    };

    address1Component.label = 'Address';
    address1Component.name = 'street';
    address1Component = initComponent(address1Component);
    childComponents[address1Component.id] = address1Component;

    const address2 = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];

    let address2Component = {
        id: ID.uuid(),
        ...address2.component
    };

    address2Component.label = 'Address line 2 (Optional)';
    address2Component.name = 'apt';
    address2Component = initComponent(address2Component);
    childComponents[address2Component.id] = address2Component;


    const city = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];

    let cityComponent = {
        id: ID.uuid(),
        ...city.component
    };

    cityComponent.label = 'City';
    cityComponent.name = 'city';
    cityComponent = initComponent(cityComponent);
    childComponents[cityComponent.id] = cityComponent;

    const state = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];

    let stateComponent = {
        id: ID.uuid(),
        ...state.component
    };

    stateComponent.label = 'State';
    stateComponent.name = 'state';
    stateComponent = initComponent(stateComponent);
    childComponents[stateComponent.id] = stateComponent;

    const zip = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];
    

    let zipComponent = {
        id: ID.uuid(),
        ...zip.component
    };

    zipComponent.label = 'Zip';
    zipComponent.name = 'zip';
    zipComponent = initComponent(zipComponent);
    zipComponent.mask = 'maskZipUs';
    
    childComponents[zipComponent.id] = zipComponent;

    newComponent.childComponents = childComponents;

    return newComponent;
}

const createSwitchComponent = (newComponent: IComponent) => {

    const textInput = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];

    let textInputComponent = {
        id: ID.uuid(),
        ...textInput.component
    };

    textInputComponent = initComponent(textInputComponent);
    
    newComponent.childComponents = new Map<string, IComponent>();
    newComponent.childComponents[textInputComponent.id] = textInputComponent;

    return newComponent;
}

export const createPmsListComponent = (newComponent, list: FormFieldDto[]) => {
    newComponent.childComponents = new Map<string, IComponent>();
    list.forEach((field) => {
        const switchInput = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === SWITCHINPUT)[0];
        let switchInputComponent = {
            id: ID.uuid(),
            ...switchInput.component
        };
        switchInputComponent = initComponent(switchInputComponent);
        switchInputComponent.label = field.display;
        switchInputComponent.externalId = field.externalId;
        switchInputComponent.fieldId = field.id;
        newComponent.childComponents[switchInputComponent.id] = switchInputComponent;
    });
    return newComponent;
}

export const handleCreateCompositeComponent = (newComponent) => {
    switch (newComponent.type) {
        case MEDICATIONS: {
            return createMedicationsComponent(newComponent);
        }
        case ALLERGIES: {
            return createAllergiesOrProblemsComponent(newComponent, 'health_history_allergies_');
        }
        case PROBLEMS: {
            return createAllergiesOrProblemsComponent(newComponent, 'health_history_problem_');
        }
        case ADDRESS: {
            return createAddressComponent(newComponent);
        }
        case SWITCHINPUT: {
            return createSwitchComponent(newComponent);
        }
        default: {
            return newComponent;
        }
    }
};

export const initComponent = (item) => {

    item.element = item.element || item.key || item.type;
    item.required = false;
    item.value = '';

    if (item.field_name) { item.field_name = item.type + '_' + (item.name.length > 0) ? item.name.replace(' ', '_') + '_' : '' + ID.uuid(); }

    if (item.options) {
        if (item.options.length === 0) {
            item.options = DEFAULT_ITEM_OPTIONS(item.element);
            
        }
    }

    return item;
}


export const handleRemoveItemFromLayout = (layout, splitItemPath) => {
    return removeChildFromChildren(layout, splitItemPath);
};


export const deleteChildren = (item, items) => {
    const children = item.children || item.children;
    if (!Array.isArray(children)) {
        delete items[item.id];
    } else {
        children.forEach(child => {
            deleteChildren(child, items);
        });
    }
    return items;
}

export const updateItem = (component: IComponent, property: string, value:any) => {

    //Date/Time
    if (property === 'showTimeSelect' && value === false)
        component['showTimeSelectOnly'] = false;

    //Switch
    if (component.type === SWITCHINPUT) {
        if (property === 'explaination' && value === true && !component?.childComponents) {
            const textInput = SIDEBAR_ITEMS.QUESTIONS.filter(i => i.component.type === 'TextInput')[0];
            let textInputComponent = { id: ID.uuid(), ...textInput.component };
            textInputComponent = initComponent(textInputComponent);
            component.childComponents = new Map<string, IComponent>();
            component.childComponents[textInputComponent.id] = textInputComponent;
        }
        if (component?.childComponents) {
            const child = Object.values(component.childComponents)[0];
            if (child)
                child['name'] = component.name + '_description';
        }
    }

    //Address
    if (component.type === ADDRESS && component?.childComponents) {
        Object.values(component.childComponents).forEach((comp: any, index) => {
            if (!component.name) {
                comp.name = null;
                comp.required = component.required;
            } else {
                const fieldName = comp.field_name ?? comp.field_Name;
                if (fieldName.indexOf('street') !== -1) {
                    comp.name = component.name;
                    comp.required = component.required;
                }
                if (fieldName.indexOf('apt') !== -1) {
                    comp.name = component.name.replace('street', 'apt');
                }
                if (fieldName.indexOf('city') !== -1) {
                    comp.name = component.name.replace('street', 'city');
                    comp.required = component.required;
                }
                if (fieldName.indexOf('state') !== -1) {
                    comp.name = component.name.replace('street', 'state');
                    comp.required = component.required;
                }
                if (fieldName.indexOf('zip') !== -1) {
                    comp.name = component.name.replace('street', 'zip');
                    comp.required = component.required;
                }
            }
        });

    }

    return component;
} 


