import React, { useState, useEffect, Suspense } from 'react';
import { PracticeModules, PatientModules } from './modules/index';
import { AppRouter } from '@revenuewell/logic-routing';
import { AppTemplate, AppTemplateRoot } from '@revenuewell/logic-app-template';
import { Drawer } from './modules/membership/drawer';
import { useAppState } from '@revenuewell/logic-app-state';
import { useIntercom } from 'react-use-intercom';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import AppProviders from './modules/membership/app-provider-list';
import '@revenuewell/logic-app-template/logic-app-template.esm.css';
import '@revenuewell/logic-notification/logic-notification.esm.css';
import '@revenuewell/page-critical-error/pages-critical-error.esm.css';
import '@revenuewell/ui-dialogs/ui-dialogs.esm.css';
import '@revenuewell/ui-notifications/ui-notifications.esm.css';
import '@revenuewell/ui-progress/ui-progress.esm.css';
import '@revenuewell/ui-drawers/ui-drawers.esm.css';
import '@revenuewell/ui-icons/ui-icons.esm.css';
import './modules/membership/membership.css';
import { OidcConfigurationClient } from './api/web-api-client';
import { httpService } from './services/httpService';
import { useAppContext } from "./context/app-context";
import { SWRConfig } from 'swr';
import PatientAppProviders from './modules/membership/patient-app-provider-list';
import { useOidc } from '@revenuewell/logic-oidc';
import { SubmissionsSkeleton } from './modules/submissions/index.module';
import { UnifiedWrapperService } from './services/unified-wrapper';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <SubApp />
        </BrowserRouter>
    );
}

const SubApp: React.FC = (props) => {
    const location = useLocation();

    if (location.pathname.toLowerCase().indexOf('/patients/') !== -1)
        return <PatientApp />

    return <SWRConfig value={{ revalidateOnFocus: false }}><PracticeApp /></SWRConfig>
}

const PatientApp: React.FC = () => {


    return (
        <SWRConfig value={{ revalidateOnFocus: false }}>
            <PatientAppProviders>
                <Suspense fallback={null}>
                    {PatientModules.list.map((module) => (
                        <Route {...module.routeProps} key={module.name} />
                    ))}
                </Suspense>
            </PatientAppProviders>
        </SWRConfig>
    );
}

const PracticeApp: React.FC = () => {
    const defaultRoute = '/submissions/index';
    const [config, setConfig] = useState<unknown>(null);
    const { ShowError } = useAppContext();
    const location = useLocation();
    const defaultRouteWithParams = location.search ? `${defaultRoute}${location.search}` : defaultRoute;

    useEffect(() => {
        loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadConfig = async () => {
        const client = new OidcConfigurationClient(UnifiedWrapperService.ApiProxyPath, httpService.GetInstance());

        try {
            const result = await client.getConfig();
            const mappedConfig = {
                oidc: {
                    authority: result.authority,
                    client_id: result.clientId,
                    redirect_uri: result.redirectUri,
                    post_logout_redirect_uri: result.postLogoutRedirectUri,
                    acr_values: result.acrValues,
                    response_type: result.responseType,
                    loadUserInfo: result.loadUserInfo,
                    scope: result.scope
                },
                productMenu: {
                    apiUrl: result.productMenuApiUrl,
                    selectedItem: "Forms2Button"
                },
                wrapper: {
                    url: result.unifiedWrapperDomain,
                    frame: 'forms'
                },
                featureFlags: {
                    apiUrl: result.featureFlagsDomain
                },
                forms: {
                    accountApiUrl: result.accountApiUrl,
                    contactsApiUrl: result.contactsApiUrl,
                    messengerApiUrl: result.messengerApiUrl,
                    pmsConnectorApiUrl: result.pmsConnectorApiUrl,
                    productApiUrl: result.productApiUrl,
                    contactDomain: result.contactDomain,
                    phoneDomain: result.phoneDomain,
                    intercomAppId: result.intercomAppId
                }
            };
            setConfig(mappedConfig);
        } catch (e) {
            ShowError('Problem occurred while loading the app.')
        }
    }

    if (!config)
        return <></>;


    return (
        <Switch>
            <AppTemplate devConfig={config} isProduction={false} routes={PracticeModules.list.map(m => m.routeProps)} defaultRedirect={defaultRouteWithParams}>
                <AppProviders>
                    <TheRest />
                </AppProviders>
            </AppTemplate>
        </Switch >);
}

const ShowDrawer = () => {
    const { drawerVisible } = useAppContext();

    if (!drawerVisible)
        return <></>;

    return <Drawer />;
};

const TheRest = () => {
    const { appReady, setAppReady } = useAppState();
    const { productMenuVisible, allLocations } = useAppContext();
    const { boot } = useIntercom();
    const { getClaims } = useOidc();

    useEffect(() => {
        setTimeout(async () => {
            setAppReady(true);
            const claims = await getClaims();

            boot({
                hideDefaultLauncher: true,
                name: `${claims.firstName} ${claims.lastName}`,
                userId: claims.id.toString(),
                company: claims.locationId ? { companyId: claims.locationId.toString() } : undefined
            });
        }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAppReady]);

    return (
        <AppTemplateRoot className="app" ready={appReady} productMenu={{ render: productMenuVisible }}>
            <ShowDrawer />
            <Switch>
                {allLocations?.length &&
                    <Suspense fallback={null}>
                        <AppRouter routes={PracticeModules.list.map(m => m.routeProps)} />
                    </Suspense>
                }
                {!allLocations?.length &&
                    <SubmissionsSkeleton />
                }
            </Switch>
        </AppTemplateRoot>
    );
}

export default App;