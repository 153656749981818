import React, { lazy, Suspense } from 'react';
import RwPage from '../shared/RwPage';
import { AccordionDetails, Box, Divider, Skeleton } from '@mui/material';
import RwAccordion from '../shared/rw-accordion';
import RwAccordionRow from '../shared/rw-accordion-row';
import RwLocationsPanel, { FormModes } from '../shared/rw-locations-panel';


export const EditPacketSkeleton: React.FC = () => {

    return (
        <RwPage title='Edit Packet Settings' buttons={[{text: 'Edit Packet Settings', action: ''}]}
            backButton={{ text: 'Back', action: '' }} loading={true}>
            <Box m={2} pr={12} pl={12}>
                <form>
                    <RwAccordion title="Details" name="details" showSkeleton={true} defaultExpanded={true} error={null}>
                        <RwAccordionRow variant="input" label="Packet Name" showSkeleton={true}></RwAccordionRow>
                        <Divider />
                        <RwAccordionRow variant="input" label="Last Modified" showSkeleton={true}></RwAccordionRow>
                    </RwAccordion>
                    <RwAccordion title="Forms Included" name="Forms Included" defaultExpanded={true} error={false} showSkeleton={true}> {/*FormsIncludedPanel*/}
                        <AccordionDetails style={{ flexDirection: 'column' }}>
                            <Skeleton variant="text" width={300} style={{ marginTop: 20 }} />
                            <Skeleton variant="text" width={300} style={{ marginTop: 30 }} />
                            <Skeleton variant="text" width={300} style={{ marginTop: 30 }} />
                        </AccordionDetails>
                    </RwAccordion>
                    <RwLocationsPanel tooltip='' showSkeleton={true} allLocations={null} formData={null}
                        formMode={FormModes.ReadOnly} defaultExpanded={true} showAllLocationsOnNoSelection={true} />
                </form>
            </Box>
        </RwPage>
    );
};


const EditPacket = lazy(() => import(/* webpackChunkName: "packets-settings" */'./settings'));

const LazyPackets: React.FC = () => {
    return (
        <Suspense fallback={<EditPacketSkeleton />}>
            <EditPacket />
        </Suspense>
    );
};



export const EditPacketModule =
{
    routeProps: {
        path: '/packets/settings/:id?',
        component: LazyPackets,
        private: true
    },
    name: 'Edit Packet',
};