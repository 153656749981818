
import ID from '../../shared/UUID';
import { ISideBarItem } from './interfaces';
export const SIDEBAR_ITEM = 'sidebarItem';
export const SIDEBAR_LAYOUT_ITEM = 'sidebarLayoutItem';
export const ROW = 'row';
export const COLUMN = 'column';

export const COMPONENT = 'component';
export const COLUMN_LAYOUT = {COLUMN, ROW}
export const SECTION_DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim...';
export const SUB_SECTION_DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim...';

export const LABEL = 'Label';
export const MEDICATIONS = 'Medications';
export const PROBLEMS = 'Problems';
export const ALLERGIES = 'Allergies';
export const PROBLEMSPMS = 'ProblemsPms';
export const ALLERGIESPMS = 'AllergiesPms';
export const HEADER = 'Header';
export const SUBHEADER = 'SubHeader';
export const PARAGRAPH = 'Paragraph';
export const SIGNATURE = 'Signature';
export const ADDRESS = 'Address';
export const ATTACHMENT = 'Attachment';
export const DATEPICKER = 'DatePicker';
export const SWITCHINPUT = 'SwitchInput';
export const TEXTAREA = 'TextArea';
export const TEXTINPUT = 'TextInput';
export const CHECKBOX = 'Checkbox';
export const RADIOBUTTONS = 'RadioButtons'; 
export const DROPDOWN = 'Dropdown';
export const BUTTON = 'Button';
export const CARD = 'card';
export const BLOCK = 'block';
export const LINEBREAK = 'LineBreak';

export const SIDEBAR_ITEMS_QUESTIONS: ISideBarItem[] = [
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: DROPDOWN,               // corresponds to element names in modules/forms/edit/shared/form-elements/index
            typeDisplay: DROPDOWN,        // used to display element type without being restricted by above
            canHaveAnswer: true,            
            name: '',               // use this for setting form name from tf, e.g. patient_self_name_first
            label: 'Dropdown Label',        // label displayed for rendered field
            field_name: 'dropdown_',        // name property of rendered field. the value is a composite of type_name_ and a generate guid
            options: [],                    // dropdown/radio options
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: CHECKBOX,
            typeDisplay: CHECKBOX,
            canHaveAnswer: true,
            name: '',
            label: 'Checkbox Label',
            field_name: 'checkbox_',
            options: [],
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: RADIOBUTTONS,
            typeDisplay: 'Multiple Choice',
            canHaveAnswer: true,
            name: '',
            label: 'Multiple Choice Label',
            field_name: 'radiobuttons_',
            options: [],
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: BUTTON,
            typeDisplay: 'No To All',
            canHaveAnswer: true,
            name: '',
            label: 'No To All',
            explaination: false,
            field_name: 'button_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: 'SwitchInput',
            typeDisplay: 'Yes/No',
            key: SWITCHINPUT,
            canHaveAnswer: true,
            name: '',
            label: 'Yes/No',
            explaination: false,
            field_name: 'toggle_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: TEXTINPUT,
            typeDisplay: 'Short Answer',
            canHaveAnswer: true,
            name: '',
            label: 'Short Answer Label',
            field_name: 'text_input_',
            mask: ''
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: TEXTAREA,
            typeDisplay: 'Long Answer',
            key: TEXTAREA,
            canHaveAnswer: true,
            name: '',
            label: 'Long Answer Label',
            field_name: 'text_area_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: DATEPICKER,
            typeDisplay: 'Date',
            key: DATEPICKER,
            canDefaultToday: true,
            canReadOnly: true,
            showTimeSelect: false,
            showTimeSelectOnly: false,
            name: '',
            label: 'Date Selection Label',
            field_name: 'date_picker_',
        }
    },
   
    
];
export const SIDEBAR_ITEMS_OTHER: ISideBarItem[] = [
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: SIGNATURE,
            typeDisplay: SIGNATURE,
            key: SIGNATURE,
            canReadOnly: true,
            name: '',
            label: 'Signature Label',
            field_name: 'signature_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: MEDICATIONS,
            typeDisplay: MEDICATIONS,
            key: MEDICATIONS,
            canReadOnly: true,
            name: 'health_history_medication1_name',
            label: MEDICATIONS,
            field_name: 'medications_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: ALLERGIES,
            typeDisplay: ALLERGIES,
            key: ALLERGIES,
            canReadOnly: true,
            name: 'health_history_allergies_1_name',
            label: ALLERGIES,
            field_name: 'allergies_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: ALLERGIESPMS,
            typeDisplay: 'Allergies (PMS)',
            key: ALLERGIESPMS,
            canReadOnly: true,
            name: 'health_history_allergies',
            label: 'Are you allergic to any of the following?',
            field_name: 'allergiespms_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: PROBLEMSPMS,
            typeDisplay: 'Problems (PMS)',
            key: PROBLEMSPMS,
            canReadOnly: true,
            name: 'health_history_problems',
            label: 'Do you have any of the following conditions or concerns?',
            field_name: 'problemspms_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: PROBLEMS,
            typeDisplay: PROBLEMS,
            key: PROBLEMS,
            canReadOnly: true,
            name: 'health_history_problem_1_name',
            label: PROBLEMS,
            field_name: 'problems_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: ADDRESS,
            typeDisplay: ADDRESS,
            key: ADDRESS,
            canReadOnly: true,
            name: '',
            label: ADDRESS,
            field_name: 'address_',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: ATTACHMENT,
            typeDisplay: ATTACHMENT,
            key: ATTACHMENT,
            canReadOnly: true,
            name: '',
            label: ATTACHMENT,
            field_name: 'attachement_',
        }
    },
];
export const SIDEBAR_LAYOUT_ITEMS: ISideBarItem[] = [
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: ROW,
        component: {
            type: ROW,
            typeDisplay: 'Row',
            key: 'Row',
            name: '',
            static: true,
            title: 'Row Title',
            description: ''
        }
    },
    //{
    //    id: ID.uuid(),
    //    type: SIDEBAR_LAYOUT_ITEM,
    //    parentType: COLUMN,
    //    component: {
    //        type: COLUMN,
    //        typeDisplay: 'Column',
    //        key: 'Column',
    //        name: '',
    //        static: true,
    //        title: 'Column Title',
    //        description: '',
    //        fillRow: true,
    //    }
    //},
];
export const SIDEBAR_LAYOUT_ITEMS_CONTENT: ISideBarItem[] = [
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: HEADER,
            typeDisplay: HEADER,
            key: HEADER,
            name: '',
            static: true,
            content: 'Header Text',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: SUBHEADER,
            typeDisplay: SUBHEADER,
            key: SUBHEADER,
            name: '',
            static: true,
            content: 'Sub-Header Text',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: LABEL,
            typeDisplay: LABEL,
            key: LABEL,
            name: '',
            static: true,
            content: 'Label Text',
        }
    },
    {
        id: ID.uuid(),
        type: SIDEBAR_ITEM,
        parentType: COMPONENT,
        component: {
            type: 'Paragraph',
            typeDisplay: 'Paragraph',
            key: 'Paragraph',
            name: '',
            static: true,
            content: 'Paragraph Text',
        }
    },

]
export const DEFAULT_ITEM_OPTIONS = (element) => {
    switch (element) {
        case 'Dropdown':
            return [
                { value: 'Choice #1', text: 'Choice #1', key: `dropdown_option_${ID.uuid()}` },
                { value: 'Choice #2', text: 'Choice #2', key: `dropdown_option_${ID.uuid()}` },
                { value: 'Choice #3', text: 'Choice #3', key: `dropdown_option_${ID.uuid()}` },
            ];
        case 'Checkbox':
            return [
                { value: 'Choice #1', text: 'Choice #1', key: `checkboxes_option_${ID.uuid()}` },
                { value: 'Choice #2', text: 'Choice #2', key: `checkboxes_option_${ID.uuid()}` },
                { value: 'Choice #3', text: 'Choice #3', key: `checkboxes_option_${ID.uuid()}` },
            ];
        case 'RadioButtons':
            return [
                { value: 'Choice #1', text: 'Choice #1', key: `radiobuttons_option_${ID.uuid()}` },
                { value: 'Choice #2', text: 'Choice #2', key: `radiobuttons_option_${ID.uuid()}` },
                { value: 'Choice #3', text: 'Choice #3', key: `radiobuttons_option_${ID.uuid()}` },
            ];
        default:
            return [];
    }
}
export const SIDEBAR_ITEMS = { QUESTIONS: SIDEBAR_ITEMS_QUESTIONS, OTHER: SIDEBAR_ITEMS_OTHER, SECTIONS: SIDEBAR_LAYOUT_ITEMS, CONTENT: SIDEBAR_LAYOUT_ITEMS_CONTENT };


