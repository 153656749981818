import axios from 'axios';
import  '../MiniProfiler';
declare let MiniProfiler;

const instance = axios.create();

// add miniprofiler interceptor so it updates when our spa changes pages/makes requests
instance.interceptors.response.use((response) => {
    let miniProfilerHeaders = null;
    if (response.headers['x-miniprofiler-ids']) {
        miniProfilerHeaders = JSON.parse(response.headers['x-miniprofiler-ids']);
    }
    if (typeof MiniProfiler !== 'undefined' && miniProfilerHeaders) {
        MiniProfiler.fetchResults(miniProfilerHeaders);
    }
    return response;
}, undefined);

export const httpService = {
    GetInstance: () => {
        return instance;
    },
};
