import React from 'react';
import InputMask from 'react-input-mask';
import TextField from "@mui/material/TextField";
import styled from '@emotion/styled';

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F9F9F9',
    },
});

export const MaskedTextInput = React.forwardRef(function TextMaskCustom(props: any, ref) {

    const mask = (key) => {
        switch (key) {
            case 'maskZipUs': {
                return '99999';
            }
            case 'maskSSN': {
                return '999-99-9999';
            }
            case 'maskPhoneUs': {
                return '(999) 999-9999';
            }
            case 'maskState': {
                return '[A-Z][A-Z]';
            }
            case 'maskNumbers': {
                return '999999999999999999999999';
            }
            
        }
    }

    return (
        <InputMask
            mask={mask(props.maskKey)}
            maskChar={null}
            onChange={props.onChange}
            disabled={props.disabled}
            value={props.value}
            >
            {(inputProps) => <StyledTextField {...inputProps}
                id={props.id}
                name={props.name}
                error={props?.error}
                className={props.className}
                label={props.label}
                autoComplete={props.autoComplete}
                size={props.size}
                type={props.mask !== 'maskState' ? 'tel' : 'text'}
                disabled={props.disabled}
                required={props.required}
            />}
        </InputMask>
    );
});

MaskedTextInput.displayName = "TextMask";

export default MaskedTextInput;



