import React from "react";
import Row from "./row";
import RwActionBar from "../../shared/RwActionBar";
import classNames from "classnames";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { getPatientPortalColorRGB } from "../../../themes/patients-custom-theme";
import { IFormViewProps } from "./interfaces";
import { useFormViewContext } from "../shared/form-view-context";
import { FormSkeleton } from "../shared/skeleton";
import { CircularProgress } from '@mui/material';

const patientPortalColor = getPatientPortalColorRGB('blue', 1);

const theme = createTheme({
    palette: {
        primary: {
            main: patientPortalColor
        },
        secondary: {
            main: patientPortalColor
        },
        error: {
            main: '#e25650',
        },
        text: {
            primary: '#000000'
        },
    },
    components: {
        MuiCheckbox: {
            styleOverrides: {
                colorSecondary: {
                    color: '#e25650',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                colorSecondary: {
                    color: '#e25650',
                },
            },
        },
    },
});

const useStyles = makeStyles<IFormViewProps>((props) => ({
    page: {
        flex: '1 0 100%',
        flexDirection: 'column',
        padding: '0px',
        margin: '0px',
        width: '100%',
        height: "calc(100% - 15px)",
        '&.preview': {
            backgroundColor: 'inherit',
        },
        '&:not(.preview)': {
            backgroundColor: '#F1F2F5',
        }
    },
    app: {
        paddingRight: '25px',
        overflowY: 'visible',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '7px'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.25)',
            borderRadius: '7px'
        }
    },
    form: {
        display: 'flex',
        flex: '1 1 85%',
        flexDirection: 'column',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: (props: any) => props.isMobile && props.peerCount > 1 ? 'calc(100% - 180px)' : '100%',
        width: '85%',
        '&.preview': {
            width: '100%',
            backgroundColor: 'inherit',
            color: '#000000',
        },
        '&:not(.preview)': {
            backgroundColor: '#F1F2F5',
        },
        '&.mobile': {
            height: "88%",
        }
    },
    formName: {
        fontFamily: 'Helvetica',
        fontSize: "36px",
        fontStyle: "normal",
        fontWeight: "700",
        letterSpacing: "0.25px",
        textAlign: 'center',
        width: '100%',
        color: patientPortalColor,
        marginRight: '25px',
        marginTop: '40px',
        marginBottom: '20px',
        '&.preview': {
            color: '#FFF',
        },
        '&.landscape': {
            fontSize: "18px",
            marginTop: '10px',
            marginBottom: '10px',
        }
    },
    virtualized: {
        '& .ReactVirtualized__Grid__innerScrollContainer': {
            marginRight: '10px',
        }
    },
    '&:last-child': {
        borderBottom: '2px solid #E4E5E8',

    },
    img: {
        width: 'calc(100% - 5px)',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: "6px",
        marginTop: '30px',
    },
    overlay: {
        background: '#ffffff',
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: 5000,
        top: 0,
        left: 0,
        float: 'left',
        textAlign: 'center',
        paddingTop: '20%',
        opacity: 0.70
    }
   
}));


const Container: React.FC = () => {
    const { state, handleSubmit, handleChange, handleCancel } = useFormViewContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isLandscape = useMediaQuery('(orientation: landscape)');
    const classes = useStyles({ isMobile: isMobile });
    const isUploadedForm = state.images?.length > 0;

    if (state.submitting)
        return(
        <div className={classes.overlay}>
            <CircularProgress />
        </div>);


    if (state.loading || !state.pmsSystemVm || !state.data || (state.data?.json?.pdf && state.images?.length === 0))
        return <FormSkeleton />;

    return (
        <ThemeProvider theme={theme}>
            <div className={classNames(classes.page, { preview: state.preview })}>
                {!isMobile || !isLandscape &&
                    <div className={classNames(classes.bar, { preview: state.preview })}><br /></div>
                }

                <div className={classNames(classes.form, { preview: state.preview, mobile: isMobile && !state.preview })}>
                    {!state.preview &&
                        <div className={classNames(classes.formName, { preview: state.preview, landscape: isMobile && isLandscape && !state.preview })}>
                            {state.data.form.name}
                        </div>}

                    <div className={classes.app}>
                        {state.images &&
                            state.images.map((img, index) => (

                                <img
                                    key={`child-${index}`}
                                    src={img}
                                    className={classes.img}
                                >
                                </img>
                            ))}

                        {state.tempLayout && state.tempLayout.map((row, index) => {
                            const currentPath = `${index}`;
                            return (<Row
                                key={row.id}
                                row={row}
                                components={state.data.json.components}
                                path={currentPath}
                                handleChange={handleChange}
                                readOnly={state.readOnly}
                                nextRow={index + 1 <= state.tempLayout.length ? state.tempLayout[index + 1] : null}
                                prevRow={index - 1 >= 0 ? state.tempLayout[index - 1] : null}
                                index={index}
                                last={index === state.tempLayout.length-1}
                                isMobile={isMobile}
                                googleMapApiKey={state.data.form.googleMapApiKey}
                                isUploadedForm={isUploadedForm}
                                displayLabel={state.data.json.displayLabels}
                            />);

                        })}
                    </div>

                    {!state.hideActionBar &&
                        <RwActionBar patientName={!isMobile ? state.formViewActionBarString : ''}
                        width='100%'
                        marginLeft='auto'
                        marginRight='auto'
                                leftButtons={[
                                    { onClick: handleCancel, text: "Cancel", buttonStyle: 'buttonCancel' },
                                ]}
                                rightButtons={[{
                                    text: 'Submit', onClick: handleSubmit, addArrowToText: !isMobile, buttonStyle: state.loading ? 'buttonDisable' : 'buttonSuccess', disabled: state.submitting
                                }]}
                                validationMessages={state.validationMessages}
                                validationFadeout={true}
                            />
                    }
                </div> 
            </div>

        </ThemeProvider>);
};

export default Container;


